/<template>
  <div>

    <v-form ref="form" v-model="valid" lazy-validation>  
    <Displays v-if="paramPage.displays" :page="page" :params="paramPage.displays" @returnDisplay="changeParam"/>
  
    <v-row>
        <v-col class="text-center">
            <v-btn class="primary" @click="validate" :disabled="!valid">Enregistrer</v-btn>

        </v-col>
    </v-row> 
</v-form>
</div>

</template>

<script>
export default {
    name : 'editVariantPage',
    props: ['page','modal'],

    components: {

        Displays: () => import('./displaysPage.vue'),

    },

    data: function () {
        return {
            paramPage : [],
            args : [],
            valid : false
        }
    },
    methods: {

        validate() {
            if (this.$refs.form.validate()) {

                this.$store.dispatch('getoverlay', true)
                this.API({type:'put', url:'savevariant', params: {id:this.args.id, fields:this.paramPage.displays, variant:this.args.variant}}).then((resp) => {
                    this.paramPage = resp.data
                    this.$store.dispatch('getoverlay', false)
                    })
            }
        },

        changeParam(data) {
            this.paramPage.displays = data
        }
    },

    mounted() {
        this.args = (!this.modal) ? this.$route.params : this.$store.getters.modalShowParams({type:'to',val:this.page}).params
        this.API({type:'post', url:'page', params:{id : this.args.id,variant: this.args.variant,edit:true }}).then((resp) => { 
                
                this.paramPage = resp.data
                
            })
    }
}
</script>
