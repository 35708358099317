import moment from "moment";
export default {

    data: function() {
        return {
            // stocke les key des conditions qui sont passés, et permet de retrouver une valeur originale si non gardé par l'action 
            listKeyNotGuardCond: [],
            directives: this.item.directives,

            //valeur originales provenant de l'API 
            originalItems: this.item.liste,
            originalValField: this.valField,
            originalProps: this.item.defaultprops,
            // permet de faire la différence entre une selection sur la liste, ou l'ecriture de l'utilisateur (uniquement pour les textarea)
            // permet de ne pas lancer l'action si des valeurs des clés de la liste on été intétgré sur d'autres champs
            onSelect: false,

            // liste les conditions à appliquer uniquement si c'est un form
            specificCondition: (this.type == 'form') ? this.$store.getters['getConditionForm'](this.page) : false,
        }
    },

    watch: {

        specificCondition: {

            handler(val) {

                this.watchSpecificConditions(val)
            },
            deep: true

        },


        valField(val) {

            if (this.type != 'form' || !this.directives) return;

            this.launchDirectives(val, false)

        }
    },


    methods: {

        //permet de lancer les conditions si elles sont dans le getter, pour le champ en question
        watchSpecificConditions(val) {

            if (val && val.conditions.find((item) => { return item[this.item.cle] })) {
                var storeCurrentKey = [];
                let cond = val.conditions.find((item) => { return item[this.item.cle] })

                // on stocke les actions qui ne doivent pas etre gardées si la condition change
                cond[this.item.cle].forEach((condition) => {
                    if (!condition.guard) this.listKeyNotGuardCond.push(condition)
                })

                if (this.listKeyNotGuardCond.filter(element => !storeCurrentKey.includes(element.key))) {
                    let removeAction = this.listKeyNotGuardCond.filter(element => !storeCurrentKey.includes(element.key))
                    removeAction.forEach((action) => {
                        switch (action.type) {
                            case 'props':
                                this.ApplyProps(this.originalProps)
                                break;
                            case 'valuefromfield':
                            case 'simplevalue':
                                this.items = this.originalItems;
                                this.change(this.item, this.originalValField, this.defaultValue)
                                break;
                        }
                        this.listKeyNotGuardCond = this.listKeyNotGuardCond.filter(element => element.key != action.key)
                    })
                }

                cond[this.item.cle].forEach((condition) => {
                    if (!condition.guard) this.listKeyNotGuardCond.push(condition)
                    storeCurrentKey.push(condition.key);

                    this.applySpecificCondition(condition)
                })


            }

        },

        //lance les directives du champ
        launchDirectives(val, firstLoad) {

            var fields = {}
                //execution des conditions si il y a :
            let resultFields = this.$store.getters.listForm(this.page)
            this.directives.forEach((dir) => {
                    dir.then.forEach((then) => {
                        fields[then.field] = { remove: [], insert: [] }
                        var find = false;
                        var value = this.replaceBy({ text: dir.if.value, page: this.page })
                            //on stringify car si dans this.getReelValue() on fait un delete de l'action, il sera délété pour de bon.. et ne reviendra plus
                        let actions = JSON.parse(JSON.stringify(then.actions))
                            //permet de récupérer les vrai valeur en fonction des clés (pour les listes déroulantes)
                        let getActions = this.getReelValue(actions, firstLoad);
                        //stoppe l'action si le champ est un textarea ou textfield et que l'user ecrit ua lieu de sélectionner une valeur via une liste :
                        if (this.onSelect === false && (this.item.format == 'textarea' || this.item.format == 'textfield')) return;
                        switch (dir.if.typechange) {

                            case '*':
                                if (val != '' && val != null) {
                                    fields[then.field].insert = getActions;
                                    find = true
                                }
                                break;

                            case 'value':
                                //si on cible une valeur d'une liste déroulante, on reprend toutes les clé pour checker la condition
                                if (this.item.format == 'select' || this.item.format == 'autocomplete' && dir.if.key) {

                                    let array = this.items.find(el => el[this.defaultValue] == val);
                                    //lors du 1er chargement du form, le val peut etre undefined et ca créer une erreur sur array[dir.if.key].. donc if(val..)
                                    if (val && array[dir.if.key] == value) {
                                        fields[then.field].insert = getActions;

                                        find = true
                                    }
                                } else {
                                    if (Date.parse(val) == Date.parse(value) ||
                                        val == value) {
                                        fields[then.field].insert = getActions;
                                        find = true
                                    }
                                }

                                break;
                            case '!=':
                                //si on cible une valeur d'une liste déroulante, on reprend toutes les clé pour checker la condition
                                if (this.item.format == 'select' || this.item.format == 'autocomplete' && dir.if.key) {
                                    let array = this.items.find(el => el[this.defaultValue] == val);

                                    if (val && array[dir.if.key] != value) {
                                        fields[then.field].insert = getActions;
                                        find = true
                                    }
                                } else {
                                    if (Date.parse(val) == Date.parse(value) ||
                                        val != value) {
                                        fields[then.field].insert = getActions;
                                        find = true
                                    }
                                }

                                break;
                            case 'like':
                                if (val.indexOf(value) != -1) {
                                    fields[then.field].insert = getActions;
                                    find = true
                                }
                                break;
                            case 'null':
                                if (val == '') {
                                    fields[then.field].insert = getActions;
                                    find = true
                                }
                                break;
                            case 'istrue':
                                if (val === true) {
                                    fields[then.field].insert = getActions;
                                    find = true
                                }
                                break;
                            case 'isfalse':
                                if (val === false) {
                                    fields[then.field].insert = getActions;
                                    find = true
                                }
                                break;
                            case 'issup':

                                if (Date.parse(val) > Date.parse(resultFields[dir.if.value]) ||
                                    val > resultFields[dir.if.value]) {
                                    fields[then.field].insert = getActions;
                                    find = true
                                }
                                break;
                            case 'isinf':
                                if (Date.parse(val) < Date.parse(resultFields[dir.if.value]) ||
                                    val < resultFields[dir.if.value]) {
                                    fields[then.field].insert = getActions;
                                    find = true
                                }
                                break;
                            case 'isequal':
                                if (Date.parse(val) == Date.parse(resultFields[dir.if.value]) ||
                                    val == resultFields[dir.if.value]) {
                                    fields[then.field].insert = getActions;
                                    find = true
                                }
                                break;
                            case 'iszerohour':
                                if (moment(val).format('HH:mm') == '00:00') {
                                    fields[then.field].insert = getActions;
                                    find = true
                                }
                                break;
                            default:
                                console.log(dir.if)
                                break;
                        }

                        if (!find) fields[then.field].remove = getActions;

                    })

                    this.$store.dispatch('changeConditionForm', { page: this.page, datas: fields })
                })
                // 
            this.onSelect = false
        },


        getReelValue(actions, firstLoad) {


            let getValue = this.items.find(item => item[this.defaultValue] == this.model)
                // on prend le nombre d'acton à appliquer. Quand le nombre est appliqué et que les actions sont finis,
                // On supprime les actions (dans le cas unique ou la sélection se fait sur un textarea)

            actions.forEach((action, key) => {

                actions[key].reelvalue = action.value;

                if (this.items.length > 0 && (action.type == 'valuefromfield' || action.type == 'createoptionsfromarray')) {

                    //dans le cas ou on est dans un textarea et que le user à selectionner une option ds une liste, on fait le changement, sinon non
                    // car si il ecrit, il n'y aura pas de clé à transférer.. cela se fait uniquement quand on selectionne
                    if (action.value == "AllArray") {
                        actions[key].reelvalue = (getValue) ? getValue : {}
                    }
                    //si l'action ne récupère pas tout l'array, mais juste une clé de l'array :
                    if (action.value != "AllArray") {
                        actions[key].reelvalue = (getValue) ? getValue[action.value] : "";
                    }

                    // si on est dans un textarea et qu'une action consiste a mettre une clé de la liste du textarea, on ne le fait si la personne
                    //ecrit. seulement si elle selectionne
                    if (firstLoad && !action.firstload) delete actions[key];

                }
            })

            return actions
        },

        applySpecificCondition(cond) {
            switch (cond.type) {

                case 'simplevalue':
                case 'valuefromfield':
                    var modi = (isNaN(cond.reelvalue)) ? cond.reelvalue : Number(cond.reelvalue)
                    this.keyup(this.item, modi)
                    break;

                case 'props':
                    // je fais le let pour faire la diff entre les props d'avants et ceux de maintenant.
                    this.ApplyProps(cond.value)
                    break;
                case 'filterbykey':

                    if (Array.isArray(cond.value)) {
                        this.items = []
                        cond.value.forEach((f) => {
                            let value = (!isNaN(f.reelvalue)) ? Number(f.reelvalue) : f.reelvalue;
                            switch (f.signe) {
                                case '=':
                                    this.originalItems.filter((el) => el[f.key] == value).forEach((v) => {
                                        this.items.push(v);
                                    })
                                    break;
                                case '!=':
                                    this.originalItems.filter((el) => el[f.key] !== value).forEach((v) => {
                                        this.items.push(v);
                                    })
                                    break;
                                case '>':
                                    this.originalItems.filter((el) => el[f.key] > value).forEach((v) => {
                                        this.items.push(v);
                                    })
                                    break;
                                case '<':
                                    this.originalItems.filter((el) => el[f.key] < value).forEach((v) => {
                                        this.items.push(v);
                                    })
                                    break;
                            }
                        })

                        // si sur la liste refaite, la valeur actuelle du champ ne correspond pas à une des options, on remet sa valeur à zero
                        if (!this.items.find((el) => el[this.defaultValue] === this.valField)) {
                            this.change(this.item, null, this.defaultValue)
                        }
                    }

                    break;

                case 'reloadOptions':
                    var fields = (this.$store.getters.listForm(this.page)) ? this.$store.getters.listForm(this.page)['fields'] : {};
                    this.API({
                            type: 'post',
                            url: 'engine/reloadOptions',
                            params: { field: { cle: this.item.cle }, filter: { params: this.params, fields: fields } }
                        })
                        .then((resp) => {
                            this.items = resp.data


                            this.getDefaultValue(this.items)

                            //newvalue : utilisé dans le mixins Fields.js, permet de mettre la nouvelle valeur fraichement inséré, après le rechargement de la liste
                            if (cond.newvalue) {
                                this.change(this.item, cond.newvalue, this.defaultValue)
                            } else {

                                if (!this.items.find((el) => el[this.defaultValue] === this.valField)) {
                                    this.change(this.item, null, this.defaultValue)
                                }
                                if (this.items.length == 1) {
                                    this.change(this.item, this.items[0], this.defaultValue)
                                }

                            }


                        })

                    break;
                case 'createoptionsfromarray':

                    if (Array.isArray(cond.value)) {
                        this.items = []
                        let createoptions = []
                        for (var i in cond.value) {
                            const option = { text: cond.value[i], value: cond.value[i] }
                            createoptions.push(option)
                        }
                        this.items = createoptions
                    }

                    break;

                case 'decalefurthur':
                    var mod = this.$store.getters.listForm(this.page).fields[cond.value.fromField];
                    for (i in cond.value) {
                        if (i != 'fromField' || cond.value[i] != 0) mod = this.moment(mod).add(cond.value[i], i);
                    }
                    mod = this.moment(mod).format('YYYY-MM-DD HH:mm:00');
                    this.change(this.item, mod, this.defaultValue);

                    break;
                case 'decaleforward':
                    mod = this.$store.getters.listForm(this.page).fields[cond.value.fromField];
                    for (i in cond.value) {
                        if (i != 'fromField' || cond.value[i] != 0) mod = this.moment(mod).subtract(cond.value[i], i);
                    }
                    mod = this.moment(mod).format('YYYY-MM-DD HH:mm:00');
                    this.change(this.item, mod, this.defaultValue);

                    break;
            }
        },



        // fonction qui permet d'appliquer les propriété du champ
        ApplyProps(array, originalProps) {
            this.prop = array
                // si le champs n'est pas affiché, on remet sa valeur à zéro (uniquement si c'est dans un form et si les props ne sont pas celles d'origines) :
                // ex : j'ouvre un form avec un champ non visible par défaut, mais qui a une valeur. si une condition me dit de montrer ce champ, il faudra 
                //que la valeur soit présente, et non supprimée.. 
            if (!array.show && this.type == 'form' && !originalProps) {
                // this.keyup(this.item, '')
            }
            // on applique les propriété du champ par defaut           
            for (var cle in array) {
                this.applyRules(cle, array[cle])
            }

        },

    },

    mounted() {

        if (this.type == 'form' && this.directives) {
            // on lance les directives si toutefois il y en a qui ont un firstload
            this.launchDirectives(this.valField, true);
            //on regarde si il n'y a pas deja de condition active pour le champ
            this.watchSpecificConditions(this.specificCondition);
        }

    }

}