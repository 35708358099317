<template>
    <div>
        <v-row v-if="showCrud" no-gutters>
    <v-col class="text-center">
        <v-btn
        color="secondary"
        icon
        @click="expandLogs = !expandLogs"
        >
        <v-icon>mdi-eye-refresh</v-icon>
        </v-btn>
    <Logs :table="tableLogs" :id="idLogs" :page="page" :modal="modal" :hook="hook" v-if="expandLogs"></Logs>

    </v-col>

    
</v-row>
    <v-row no-gutters><v-col>
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
        >

            <page-template :customquerys="customquerys" :customparams="customparams" v-if="loadValues" :page="page" :modal="modal" :hook="hook" :form="$refs.form"></page-template>
                    

        </v-form>
    </v-col></v-row>
</div>

</template>



<script>

    export default {

        // url est passé en props dans le composant modalComponent
    props: ['page','modal','hook','customparams','customquerys'],

    components : {
        Logs : () => import('../../engine/Logs/crudLogs.vue'),
    },

        data: function() {

            return {
                valid : true,

                loadValues : false,
                // serv à centraliser les valeurs du formulaire dans le store avec une valeur de clé unique
                urlSend : '',
                params : {},
                alert : false,
                showCrud : false,
                tableLogs:false,
                idLogs:false,
                expandLogs : false,
            }
        },

        computed : {

            isValidate() {
                return (this.$store.getters.listForm(this.page)) ? this.$store.getters.listForm(this.page)['validate'] : false;
            }
        },

        watch : {

            valid(val) {
                this.$store.getters.listForm(this.page)['valid'] = val
            },

            isValidate(val) {
                if(val!=0) { 
                    this.validate(); 
                    }
            }

        },

        methods :  {
            // fonction globale d'enregistrement des formulaires
            validate : function() {
                this.$store.dispatch('textOverlay','enregistrement en cours')
                
                    this.validateForm({form:this.$refs.form,page:this.page,
                        ifNotValid:()=>{
                        this.$store.dispatch('changeParamForm',{page:this.page,type:'validate',value:0}) 
                        },
                        callback:()=> {  
                        this.$store.dispatch('changeParamForm',{page:this.page,type:'validate',value:0}) 
                        this.$store.dispatch('changeParamForm', { page: this.page, type: 'returnActions', value: {} });                    
                        this.$store.dispatch('removeForm', { uid:this.page })
                     }}) 
               
            }


        },

        mounted() {
        // si ce n'est pas une modal on n'envoi pas d'url dans le props, donc on va prendre le current path pour editer notre formulaire
        this.urlSend = (!this.modal) ? this.$route.path : this.$store.getters.modalShowParams({type:'to',val:this.page}).url
        this.params = (!this.modal) ? this.$route.params : this.$store.getters.modalShowParams({type:'to',val:this.page}).params
        this.params = (this.customparams) ? this.customparams : this.params;

        //appel de l'api pour récupérer les valeurs des champs
        // les params permettent de récupérer des POST à envoyer dans l'API afin de préremplir les champs l'ors de l'insert
        this.API({type:'get',url:this.urlSend,params:this.params}).then((resp)=> {
            var typeform = (this.params.id =='new') ? 'new' : 'edit';

            this.$store.dispatch('initForm', { page:this.page,valid:this.valid,typeform: typeform});
            

            for(const array in resp.data.valFields) {
                
                this.$store.dispatch('pushForm', { page:this.page, champ:array,value: resp.data.valFields[array]});
            } 
            
            this.loadValues  = true 

            if(resp.data.crud) {
                    this.showCrud = true
                    this.tableLogs = resp.data.crud;
                    this.idLogs = this.params.id;
            }
        })
          
 
        },

        created() {    

            this.$store.dispatch('initConditionForm',{page : this.page})
        }

    }

</script>
