import axios from 'axios'

const routingaxios = {
    state: {

        list: [],

        datas: []

    },

    getters: {

        returnDatasFromAPI: function(state) {
            return state.datas;
        }
    },

    mutations: {

        push: function(state, axios) {
            state.list.push(axios);
        },

        set: function(state) {
            Promise.all(state.list).then(axios.spread((...responses) => {
                state.datas = responses
            })).catch(errors => {
                console.log(errors)
            })
            state.list = []
        }
    },

    actions: {

        push: function({ commit }, axios) {

            commit('push', axios)

        },
        set: function({ commit }) {
            commit('set')
        }

    },
}

export default routingaxios;