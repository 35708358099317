<template>
    
    <div>
        <loadSaveTemplate 
            @closeLST="openSaveTemplate = false" 
            :openModal="openSaveTemplate" 
            type="main"
            :templateToSave="getPage" 
            @loadTemplate="loadAllTemplate"/>
            <insertElement :openvisu="openInsertElement" 
            @closeModal="openInsertElement = false" 
            @add="(el) => {addElem({place:'top',elem:el})}" />

<v-row no-gutters v-if="mod" class="justify-center align-center">
    <v-col class="pa-1 ma-1 dashed text-center text-caption red lighten-5 rounded" cols="2" @click="addElem({place:'top',elem:'row'})">
    <v-icon>mdi-plus</v-icon><br>Ajouter une ligne
    </v-col>
    <v-col class="pa-1 ma-1 dashed text-center text-caption indigo lighten-5 rounded" cols="2" @click="openInsertElement = true">
    <v-icon>mdi-plus</v-icon><br>Ajouter un élément
    </v-col>
    <v-col class="pa-1 ma-1 dashed text-center text-caption blue lighten-5 rounded" cols="2" @click="openSaveTemplate = true">
    <v-icon>mdi-harddisk</v-icon><br>éléments Sauvegardés
    </v-col>
</v-row>

 <draggable @start="drag = true" @end="drag = false"  :class="[mod ? 'heightdiv list-group': ''] " v-bind="dragOptions" :list="getPage" handle=".handle" group="a">
        <routing v-for="(rowitem,rowindex) in getPage" 
            :key="rowindex"
            :item="rowitem"
            :mod="mod"
            :page="page"
            :modal="modal"
            :parent="rowindex" 
            :index="rowindex"
            :hook="hook"
            :form="form"
            :drag="drag"
            :customquerys="customquerys"
            :customparams="customparams"
            @addElem="addElem"
            @removeElem="removeElem"
            @changeElem="changeElem"
            @sendDatas="sendDatas"
            :datasSend="datasSend"
        />

        </draggable>
            <v-row no-gutters v-if="mod" class="justify-center align-center">
                <v-col class="pa-1 ma-1 dashed text-center text-caption red lighten-5 rounded" cols="2" @click="addElem({place:'bottom',elem:'row'})">
                    <v-icon>mdi-plus</v-icon><br>Ajouter une ligne
                </v-col>
            </v-row>

    </div>

</template>




<script>

export default {
        name : 'constructPage',
        // inModifField permet de savoir si on est dans le filtre de relationnel global (bool true) ou simplement dans une requete (bool false).//
        props:['pageTemplate','page','modal','form','hook','mod','datasSend','customquerys','customparams'],

        components : {
            draggable: () => import('vuedraggable'),
            //routing: () => import('./Routing'),
            routing: () => import('./Routing'),
            loadSaveTemplate: () =>
            import ('../../../main/templates/page/Edit/loadAndSaveTemplate.vue'),
            insertElement: () =>
            import ("./insertElement"),
        },

        data: function() {
            return {
                openSaveTemplate :false,
                //récupère tous les templates et les conditions
                getPage : this.pageTemplate,
                //intègre le template actif
                activTemplate : [],

                allowMod : this.mod,

                row : {type:'row',class:'',style:'',content:[],visuelcond:[]},
                col : {type:'col',class:'',style:'',content:[],visuelcond:[]},
                drag : false,
                openInsertElement : false,
            }
        },

        computed: {
            dragOptions() {
                return {
                animation: 0,
                group: "description",
                disabled: false,
                //ghostClass: "ghost",
                
                };
            }
        },

        watch : {
            mod(val) {
                this.allowMod = val
            },

           pageTemplate: {
                handler(val) {
                    this.getPage = val
                },
            deep: true,
            } 
        },

        methods : {

            loadAllTemplate(temp) {
                if(confirm('Vous allez remplacer tout le contenu existant par celui importé, continuer ?')) {
                    this.getPage = temp; 
                    this.openSaveTemplate = false;
                    this.$emit('recepVal',this.getPage);
                }
            },

            addElem(p) {

                let elem = (!this[p.elem]) ? p.elem : this[p.elem];

                    if(p.place=='top') {
                        this.getPage.unshift(JSON.parse(JSON.stringify(elem)))
                        return; 
                    }
                    if(p.place=='bottom') {
                        this.getPage.push(JSON.parse(JSON.stringify(elem)))
                        return; 
                    }
                const deep = (p.place.toString().length < 3) ? [p.place] : p.place.split('-');
                var length = deep.length
                
                var ee = this.getPage;
                deep.forEach((v, i) => {
                if (i == length - 1) {
                    ee[v].content.push(JSON.parse(JSON.stringify(elem)))
                } else {
                    ee = ee[v]['content']
                }

                })

                this.$emit('recepVal',this.getPage);

            },

            removeElem(place) {
            const deep = (place.toString().length < 3) ? [place] : place.split('-');
            var length = deep.length

            var ee = this.getPage;
            
            deep.forEach((v, i) => {
                if (i == length - 1) {
                    ee.splice(v, 1)
                } else {
                    ee = ee[v]['content']
                }

            })

            this.$emit('recepVal',this.getPage);

            },

            changeElem(p) {
            const deep = (p.place.toString().length < 3) ? [p.place] : p.place.split('-');
            var length = deep.length  
            var ee = this.getPage;
                deep.forEach((v, i) => {

                    if (i == length - 1) {
                        ee = ee[v][p.key] = p.value
                    } else {
                        ee = ee[v]['content']
                    }

                })              
            this.$emit('recepVal',this.getPage);
            },

            sendDatas(p) {
                this.$emit('sendDatas',p);
            }

        },

        created() {
        }
    }
</script>

<style>

.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.list-group-item {
  cursor: move;
}
.list-group-item i {
  cursor: pointer;
}
.dashed {
    border:2px rgb(212, 212, 212) dashed;
    opacity:0.8;
    cursor:pointer;
}
.dashed:hover {
    opacity:1;
}
.heightdiv {
    min-height:120px;
}

</style>