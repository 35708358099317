<template>


    <div> 

        {{ item.name }}
            <editor
            v-model="model"
            api-key="bgo8w0j6apq7w4byhpcdxp25pr4pi7dmpusju4qkiqqxmyn1"
            :init="config"
            v-on:Onkeyup="keyup(item,model,false)"
            v-on:Onchange="keyup(item,model,false)"
            :key="key"
            />      

    </div> 
    </template>  
    
    
    
    <script>
    import mixinFields from '../../mixins/Fields'
    import mixinConditionsFields from '../../mixins/conditionsFields'
    import Editor from '@tinymce/tinymce-vue';
    import { getTinymce } from '@tinymce/tinymce-vue/lib/cjs/main/ts/TinyMCE';
        export default {
            name : 'TextEditor',
    
            mixins : [mixinFields,mixinConditionsFields],
 
            components : {
                'editor': Editor
            },

            data: function() {
                return { 

                    config : {
                        language: 'fr_FR',
                        height: 500,
                        menubar: true,
                        plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount'
                        ],
                        toolbar:
                        ['undo redo | formatselect | bold italic backcolor | \
                        alignleft aligncenter alignright alignjustify | \
                        bullist numlist outdent indent','']
                        },
                        firstload : true,
                        buttons : [],

                        key : 0,
                }
            },

            watch : {

                store() {
                    if(this.firstload) this.key++;
                },
                closeChildModal: {
                    // permet de rafraichir l'encart de la photo après selection
                    handler(val) {
                        
                        if (val && val.show === false
                            //si c'est bien le champ d'ou provient le formulaire dans la modal :
                            &&
                            this.formThisField === true
                        ) {
                            this.formThisField = false
                            let img = this.$store.getters.getPicture

                            if(img.image_url) getTinymce().activeEditor.insertContent('<img src="'+img.image_url+'" width=30%>');

                            
                            this.$store.dispatch('removePicture')
                        } 

                    },
                    deep: true

                },

            },

            computed : {
                store() {
                    return this.mod;

                },
                closeChildModal() {
                    return this.$store.getters.modalShowParams({ type: 'from', val: this.page })
                }
            },
    
            methods : {

                addbutton() {
                     
                    this.config.setup = (editor) => {
                        
                        this.item.otherparams.menueditor.forEach((elem,i) => {
                            editor.ui.registry.addMenuButton('button'+i, {
                            text: elem.namelist,
                            icon : elem.icon,
                            fetch: (callback) => {
                                var ee = [];
                                elem.list.forEach((link)=> {
                                    var item = {
                                        type: link.type,
                                        text : link.text,
                                        icon : link.icon,
                                        onAction:  () => {
                                            if(link.link) {
                                                this.formThisField = true
                                                this.goTo({
                                                    rootPage: this.page,
                                                    name: link.link,
                                                    replace:{},
                                                    type: 'modal',
                                                })
                                            } else {
                                                editor.insertContent(link.value);
                                            }
                                        
                                        }
                                    }
                                    ee.push(item);
                                })
                                callback(ee);
                            }
                        });
                        this.config.toolbar[1] = this.config.toolbar[1]+' button'+i;
                        })

                    }

                    if(this.firstload) this.key++;
                },


            },

            created() {
                this.addbutton()
                this.firstload = false
            }
    
        }
    
    </script>