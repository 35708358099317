<template>
  <v-dialog v-model="dialog" persistent max-width="600px" min-width="360px">
    <div>
      <v-tabs
        v-model="tab"
        show-arrows
        background-color="deep-purple accent-4"
        icons-and-text
        dark
        grow
      >
        <v-tabs-slider color="purple darken-4"></v-tabs-slider>
        <v-tab>
          <v-icon large>mdi-email</v-icon>
          <div class="caption py-1">réinitialisation du mot de passe</div>
        </v-tab>
        <v-tab-item>
          <v-card class="px-4">
            <v-card-text>
              <v-form ref="loginForm" :v-model="valid" lazy-validation>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="password"
                      :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                      :rules="[(v) =>(v && v.length >= 8) || 'Le mot de passe doit faire au minimum 6 caractères']"
                      :type="show1 ? 'text' : 'password'"
                      name="input-10-1"
                      label="Tapez un nouveau mot de passe"
                      hint="Le mot de passe doit faire au minimum 6 caractères"
                      counter
                      @click:append="show1 = !show1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      block
                      v-model="confirmPassword"
                      :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                      :rules="[passwordMatch]"
                      :type="show1 ? 'text' : 'password'"
                      name="input-10-1"
                      label="Confirmer le nouveau mot de passe"
                      counter
                      @click:append="show1 = !show1"
                    ></v-text-field>
                  </v-col>
                  
                  <v-col class="d-flex">
                  </v-col>
                </v-row>
              </v-form>
              <v-card-actions>
                <v-row>
                <v-col class="col-12 text-center rounded red lighten-3" v-if="verified && error">
                    {{message}}
                  </v-col>
                  <v-col class="col-12 text-center rounded green lighten-3" v-if="verified && !error">
                    {{message}}
                  </v-col>
                  <v-col class="col-12">
                    <v-btn :disabled="!valid" color="success" @click="validate" v-if="(!verified || error)" class="mx-2">
                      Réinitialiser mon mot de passe
                    </v-btn>
                    <v-btn color="primary" @click="retour" class="mx-2">
                      retour connexion</v-btn>   
                  </v-col>
                </v-row>             
              </v-card-actions>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </div>
  </v-dialog>
</template>

<script>

export default {
  name: "LognComponent",

  components: {
  },

  data: function () {
    return {
      dialog: true,
      tab: 0,
      message: "",
      token: null,
      email: null,
      valid : true,
      password: null,
      show1: false,
      confirmPassword: "",
      error : false,
      verified : false
    };
  },
  computed: {
    passwordMatch() {
      return () =>
        this.password === this.confirmPassword ||
        "Les mot de passes ne sont pas les mêmes";
    },
  },
  methods: {
    validate() {
      if (this.$refs.loginForm.validate()) {

        this.API({
          type: "post",
          url: "auth/resetPassword",
          params: {
            token: this.$route.query.token,
            password: this.password,
            password_confirmation: this.confirmPassword,
          },
        }).then((res) => {
          this.verified = true
          this.error = !res.data.success;
          this.message = res.data.message;

        });

      }
    },

    retour() {
      this.$router.push({ name: "login" });
    },
  },

  created() {
    this.$store.dispatch("setUser").then(() => {
      window.location.href = '/'
    })
  },
};
</script>
