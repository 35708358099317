const displayMenu = {
    state: {

        listleft: [],
        listtop: [],
        listAll: [],
        overlay: false,
        current: false,
        textOverlay: '',

    },

    getters: {

        listleft: (state) => {
            return state.listleft
        },

        listtop: (state) => {
            return state.listtop
        },

        specificPage: (state) => (id) => {
            return state.listAll.find(specific => specific.props.page === id)
        },

        isoverlay: (state) => {
            return state.overlay
        },
        textOverlay: (state) => {
            return state.textOverlay
        },
        listAll: (state) => {
            return state.listAll
        },
        currentPage: (state) => {
            return state.current
        }
    },

    mutations: {

        textOverlay: function(state, value) {
            state.textOverlay = value
        },

        pushMenuLeft: function(state, liste) {
            state.listleft.push(liste)
        },

        pushMenuTop: function(state, liste) {
            state.listtop.push(liste)
        },

        pushAll: function(state, liste) {
            state.listAll.push(liste)
        },
        getoverlay: function(state, is) {
            state.overlay = is
        },
        current: function(state, page) {
            state.current = page
        },
        changeParamPage: function(state, params) {

            // on fait un filter pour faire une boucle sur les pages car les pages form ont 2 pages sur vuejs : 1 pour l'édition, 1 pour l'insertion
            if (params.key) {
                state.listAll.filter(specific => specific.props.page === params.page).forEach((page)=> {
                    page[params.item][params.key] = params.value
                })
            } else {
                state.listAll.filter(specific => specific.props.page === params.page).forEach((page)=> {
                    page[params.item] = params.value
                })

            }

        }

    },

    actions: {

        pushMenuLeft: function({ commit }, liste) {
            commit('pushMenuLeft', liste)
        },

        pushMenuTop: function({ commit }, liste) {
            commit('pushMenuTop', liste)
        },

        pushAll: function({ commit }, liste) {
            commit('pushAll', liste)
        },
        getoverlay: function({ commit }, val) {
            commit('getoverlay', val)
        },
        changeParamPage: function({ commit }, params) {
            commit('changeParamPage', params)
        },
        textOverlay: function({ commit }, value) {
            commit('textOverlay', value)
        },
        current: function({ commit }, value) {
            commit('current', value)
        },
    }

}
export default displayMenu;