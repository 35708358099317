<template>

<v-card>

    <not-allowed v-if="!AllowedDisplay"></not-allowed>

                <v-tabs
                v-model="tab"
                :right="paramTabs.right"
                :background-color="paramTabs.bgcolor"
                :centered="paramTabs.centered"
                :dark="paramTabs.dark"
                :icons-and-text="paramTabs.iconText"
                :vertical="paramTabs.vertical"
                >

                    <v-tabs-slider></v-tabs-slider>

                    <v-tab 
                    v-for="(tabulation,i) in listTabs" :key="i"
                    :href="'#tab-'+i"
                    :class="tabulation.paramstab.class"
                    :dark="tabulation.paramstab.dark"
                    @click="setActiveTab(i);"
                    >
                    {{tabulation.name}}
                    <v-icon v-if="tabulation.paramstab.icon">{{tabulation.paramstab.icon}}</v-icon>
                    </v-tab> 

                <v-tabs-items v-model="tab">

                <v-tab-item                   
                    v-for="(tabulation,i) in listTabs"
                    :key="i"
                    :id="'tab-'+i"
                    :value="'tab-'+i"
                    :dark="tabulation.paramstab.dark">

                <v-switch
                v-if="($store.getters.currentUser.type==3) && tabulation.content"
                v-model="isModified[tabulation.id]"
                class="pa-0 ma-0 text-center"
                hide-details
                ></v-switch>
                <v-btn icon v-if="isModified[tabulation.id]" @click="savePage(tabulation)" class="blue" dark><v-icon>mdi-content-save</v-icon></v-btn>

                                <CPage 
                                v-if="tabulation.content"
                                :pageTemplate="tabulation.content" 
                                :page="page" 
                                :modal="modal" 
                                :form="form" 
                                :hook="hook"
                                :mod="isModified[tabulation.id]"
                                @recepVal="(temp) => recupTemplate(tabulation,temp)"
                                />

                                <main-component 
                                v-if="!tabulation.content && tabulation.page && getReload[i]==1" 
                                :customparams="tabulation.params" 
                                :customquerys="tabulation.querys"  
                                :page="tabulation.page" 
                                :modal="modal"
                                :independant="true"
                                :key="getReload[i]"
                                :typeform="defineTypeFormPage(i,tabulation)"
                                :hook="hook"></main-component>

                </v-tab-item>
                </v-tabs-items>
            </v-tabs >   
    </v-card>

</template>



<script>

    export default {

    name :'TabsModule',
    props: ['idmodule','page','modal','form','hook'],

    components : {
            CPage    : () => import('../engine/outils/constructPage/Page.vue'),
        },

        data: function() {

            return {

                show : false,
                AllowedDisplay : true,
                tab : '',
                listTabs : [],
                paramTabs : [],
                isModified: [],
                params : {},
                getReload : {}

            }
        },

        computed : {
            activeTab() {
                return this.$store.getters.activeTab['tabs_'+this.idmodule];
            }
        },

        watch : {

            activeTab(val) {

                this.tab = val
            }

        },

        methods :  {

            readDatas : function() {
                this.API({type:'post', url:'displaymodule', params:{id:this.idmodule,params:this.params}}).then((resp) => {
                        this.manageDatas(resp.data)              
                })


            },

            manageDatas(datas) {
                if(!datas.success) {
                        this.AllowedDisplay = false;
                        this.$emit('isloaded',{mod:this.idmodule,'resp':true}); 
                        return false;
                        }

                    
                    //permet le farchissement de main-component :
                    datas.tabs.forEach((tab,i) => {
                        this.getReload[i] = 0
                    })

                    this.listTabs = datas.tabs
                    this.paramTabs = datas.params

                    //en cas d'onglet dynamiques on check si la longueur des onglets et est plus petite que celui stocké dans vuex. si c'est le cas,
                    //on remet à zero
                    if(this.$store.getters.activeTab[this.idmodule] && this.$store.getters.activeTab[this.idmodule] > this.listTabs.length) {
                        this.setActiveTab(0)
                    }

                    this.show = true 
            },

            savePage(tab) {
                let template = this.listTabs.find(el => el.id == tab.id)
                this.API({type:'put', url:'tabulation/savecontent', params:{id :template.id, content : template.content}}).then(() => {                           
                        
                    })
            },

            recupTemplate(tab,template) {
                this.listTabs.find(el => el.id == tab.id).content = template
            },


            setActiveTab(tab) {
                this.$store.dispatch("setActivTab",{hook:this.idmodule,tab:tab});
                Object.keys(this.getReload).forEach((key) => {
                    this.getReload[key] = 0; // Réinitialiser chaque clé à 0
                });
                this.getReload[tab]=1

            },

            defineTypeFormPage(i,tab) {
                //permet de définir si cette page est un form, dans ce cas, obligatoirement en mode edit, sinon page simple
                    let type = this.newurl({page:tab.page,replace:{},returnElement:'all'});
                    return type.props.typeform


            }

        },
        mounted() {


                this.params = this.$store.getters.specificPage(this.page).params
            
            let getdatas = this.$store.getters.getModuleDatas({page:this.page,id:this.idmodule});
                if(getdatas) {
                    this.manageDatas(getdatas.datas)
                    this.$store.dispatch('removeModuleDatas',{page:this.page,id:this.idmodule})
                } else {
                    this.readDatas() 
                } 

            if(!this.$store.getters.activeTab[this.idmodule]) {
                this.setActiveTab(0)
            } else {
                this.tab = 'tab-'+this.$store.getters.activeTab[this.idmodule];
                this.setActiveTab(this.$store.getters.activeTab[this.idmodule])
            } 
        }

    }

</script>