<template>
    <div>
        <v-autocomplete
            :autofocus="prop.focus"
            :items="items"
            :loading="isLoading"
            :filter="customFilter"
            :search-input.sync="search"
            :item-text="defaultText"
            :item-value="defaultValue"
            return-object
            :label="item.name"
            :multiple="prop.multiple"
            v-model="model"
            @change="handleSelection(item,model,defaultValue)"
            :name="item.value"
            :chips="prop.chips"

            :rules="prop.modelRules"
            v-if="prop.show"
            :outlined="prop.outlined"
            :filled="prop.filled"
            :solo="prop.solo"
            :hint="prop.description"
            :persistent-hint="prop.persistenthint"
            :disabled="prop.disabled"
            :class="prop.class"
            :clearable="prop.clearable"
            :color="prop.coloronselect"
            @click:clear="change(item,'',defaultValue)"

            :deletable-chips="prop.deletablechips"


            :prepend-icon="(addIcon && positionAddIcon=='leftout') ? addIcon : ''"
            :prepend-inner-icon="(addIcon && positionAddIcon=='leftin') ? addIcon : ''"
            :append-outer-icon="(addIcon && positionAddIcon=='rightout') ? addIcon : ''"
            :append-icon="(addIcon && positionAddIcon=='rightin') ? addIcon : ''"

            @click:prepend="clickOnPosition('prepend')" 
            @click:prepend-inner="clickOnPosition('prepend-inner')" 
            @click:append="clickOnPosition('append')" 
            @click:append-outer="clickOnPosition('append-outer')"            
        >


      <template v-slot:no-data>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              Aucun résultat pour "<strong>{{ search }}</strong>".
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
        </v-autocomplete>     
</div>

</template>



<script>

import mixinFields from '../../mixins/Fields'
import mixinConditionsFields from '../../mixins/conditionsFields'
    export default {
        name : 'TextField',
        
        mixins : [mixinFields,mixinConditionsFields],

        data: function() {
            return { 

                defaultValue : 'value',   
                defaultText : 'text',             
                items : [],
                //variables utilisés pour les appels API
                isLoading: false,
                APIUrl : false,
                APITypeSend : '',
                constructParams : {},
                search : null,
                isself : false,
                loadOnSearch : true,
                debounceTimeout: null,
                // petit probleme quand :chips=false. si false, la valeur affichée dans le champ de saisie de v-autocomplete est synchronisée avec la sélection. 
                //Cela signifie que search-input.sync est déclenché chaque fois que la sélection change, car v-autocomplete met à jour search 
                //pour afficher l’élément sélectionné. (de chatgpt...)
                //donc on doit contourner si ships est false et que la liste est dynamique...
                isSearching : true

            }
        },

        watch : { 

           valField(val) {

            if(this.prop.multiple && Array.isArray(val)===false) {
                 this.model = []
            } else {

                this.model = val
            }
               this.changeStoreOnField({item:this.item,model:this.model,page:this.page})               
            
           },

           item : {
            handler(val) {
                this.items = val.liste
                this.constructListe();
            },deep :true
           },

            //permet de récupérer en direct la valeur du keyup (pour les appels API)
            search (val) {

                //lancement affichage de chargement
                if (this.isSearching) {
                    this.isSearching = false; // Empêche la recherche lors de la sélection
                } else {                
                    clearTimeout(this.debounceTimeout);
                    this.debounceTimeout = setTimeout(() => {
                        console.log('search')
                        this.dynamicSearch(val);
                    }, 500); 
                }
            },

        },

        methods : {

            handleSelection(item,model,defaultValue) {
                this.isSearching = true;
                this.change(item,model,defaultValue)
            },

            dynamicSearch(val) {

                if(this.APIUrl) {
                    this.isLoading = true
                    if(val && this.item.liste.startnumberchar > val.length) {
                        this.loadOnSearch = true;
                        return;
                    }
                
                // permet de lancer l'API sur le premier onkeyup après avoir atteint le nombre de caractère requis
                //this.item.liste.loadOnSearch : true = charge une fois la liste sur le premier keyup, false = charge la liste sur chaque keyup effectué
                if(this.item.liste.loadOnSearch===false ) {
                    if(this.loadOnSearch===false) return 
                    this.loadOnSearch = false
                } 

                //creation de l'array des paramètres, constructParams est créé sur le mounted, liste les éléments qui seront dans l'option
                var sendParams = Object.assign({}, this.constructParams);

                    for (var param in sendParams) {   
                        //[[self]] représente la string du champ de l'utilsateur. param sera la clé envoyé vers l'api
                        if(this.constructParams[param]=='[[self]]') sendParams[param] = val
                    }
                
                this.API({type:this.APITypeSend,url:this.APIUrl,params:sendParams}).then((resp)=> {
                    this.items = []
                    if(Object.keys(resp.data).length>0) {
                        //this.item.liste.beginLoop : si cette clé est fournis, beginLoop sera la clé ou commencer la boucle de résultat 
                        let loopResult = (this.item.liste.beginLoop!= null) ? resp.data[this.item.liste.beginLoop] : resp.data;

                        loopResult.forEach((retour) => {

                            let constructItems = {}
                                constructItems['text'] = '';
                                this.item.liste.values.forEach((v) => {
                                    // on split le key pour vérifier la profondeur afin de récup la valeur du retour de l'API
                                    let splitKey = v.deepKey.split('->');
                                    let deepRetour = retour;
                                        splitKey.forEach((k) => {                                            
                                            deepRetour = deepRetour[k]
                                        })
                                        
                                    
                                    constructItems[v.key] = deepRetour
                                    if(v.ishtml=='html') constructItems['text'] = constructItems['text']+' '+deepRetour;
                                })

                            this.items.push(constructItems)
                       

                        })
                    }

                    this.isLoading = false
                    
                })

                } // fin de l'appel API


                //si c'est une recherche dynamique via les options de la BDD
                if(this.item.dynamicsearch) {
                    
                    if(val && 0 > val.length) {
                        this.loadOnSearch = true;
                        return;
                    }

                    if(this.item.liste.loadOnSearch===false ) {
                    if(this.loadOnSearch===false) return 
                    this.loadOnSearch = false
                    } 

                    this.isLoading = true
                    this.API({
                            type: 'post',
                            url: 'engine/reloadOptions',
                            params: { field: { cle: this.item.cle }, filter: { userSearch: val } }
                        })
                        .then((resp) => {
                            this.items = resp.data
                            this.getDefaultValue(this.items)
                            this.isLoading = false
                        })



                }
                //si l'option de selection à partir du champ de recherche est activé :
                if(this.isself) {
                    // on supprime les anciennes entrées :
                    this.items = this.items.filter(el => el.isself !== true)
                    // si la valeur n'est vide, on place l'option dans la liste
                        if(val) {
                            this.items.push({text:val.toString(),value:val,isself:true});
                        } else {
                            this.change(this.item,null,this.defaultValue)
                        }
                    
                }

            },

            customFilter (item, queryText, itemText) {

                const Text = itemText.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/-/g, " ").toLowerCase()
                const searchText = queryText.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/-/g, " ").toLowerCase()
                // permet de supprimer les accents ou la casse pour une recherche plus profonde
                    // item : array du résultat retourné
                    //itemText : valeur de la clé text de l'array pour l'affichage
                    //queryText : valeur du search
                return Text.indexOf(searchText) > -1
            },


            constructListe() {

                //permet de définir la clé par défaut pour l'envoie dans le form
                if(this.item.liste.length>0 && typeof this.item.liste[0]['default'] !== 'undefined') this.defaultValue = this.item.liste[0]['default']
            
                // si c'est une selection multiple et que le résult par défaut n'est pas un array, on met un array vide
                if(this.prop.multiple && Array.isArray(this.valField)===false) {
                this.model = []
                } else {
                    this.model = this.valField

                }

                


                //si c'est un appel API on place les valeurs dnas les variables respectives 
               if(this.item.liste.url) {
                                // on remplace des variables d'environement  par le replaceBy
                this.APIUrl = this.replaceBy({text:this.item.liste.url,page:this.page})
                this.APITypeSend = this.item.liste.typeSend;
                // le fait que ce soit un API le this.item.lite est null, on va donc le redéfinir comme un array vide, avant qu'il soit alimenté par l'api
                this.items = []
                // liste des variables envoyé à l'API, défini dans le paramétrage du champ
                this.item.liste.listvariables.forEach((variable) => {
                    this.constructParams[variable.var] = this.replaceBy({text:variable.value,page:this.page})
                })

                //permet de savoir si le retour du résultat va faire l'objet d'une boucle en fonction d'un array
                let constructItems = [];
                this.item.liste.values.forEach((v) => {

                    if(v.defaultValue) this.defaultValue = v.key

                // lors du premier affichage si c'est un API, il faut quand meme afficher la valeur si elle est saisie, 
                //on va donc créer l'option qui va permettre d'afficher ce résultat :
                if(this.valField) {
                    if(v.ishtml=='html') constructItems['text'] = this.valField;
                    this.items.push(constructItems)
                }

                })

               }

                if(!this.item.liste.url) {
                    //on stocke les éléments dans l'array pour afficher les résultats
                    this.items = this.item.liste
                    
                    // permet de créer une option correspondant à la valeur du champ écrit par l'utilisateur. permet de sélectionner sa saisie meme si elle n'est pas dans la liste déroulante                   
                    this.item.liste.forEach((v) => {
                            if(v.text=='[[self]]') {
                                // on met a true pour créer l'option dynamiquement dans le watch du search(val)
                                this.isself = true
                                // on supprime l'option slef
                                this.items = this.items.filter(el => el.text !== '[[self]]')
                            }


                    })
                    //permet d'afficher la valeur du formulaire (si il y a une valeur), meme si celle-ci n'est pas listé dans la liste déroulante :
                    if(this.valField) {
                        let constructItems = []
                        if(!this.prop.multiple && this.items.filter(el => el[this.defaultValue] === this.valField).length==0) {
                        constructItems['text'] = this.valField.toString(); 
                        constructItems[this.defaultValue] = this.valField;
                        this.items.push(constructItems)
                        }

                    }                    
                }

            }


        },
    
        mounted() {    

                this.constructListe()


               
        },

    }

</script>

