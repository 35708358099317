<template>
    <v-card elevation="2" shaped class="ma-1">
      <v-toolbar flat color="primary" dark>
        <v-toolbar-title>Paramètres du module PDF </v-toolbar-title>
      </v-toolbar>
  

      <div v-if="!displays.params.root">
      <v-row >
        <v-col>
          {{ typegenerate }}
          <Select
              :item="{name: 'Comment souhaitez-vous créer le document ?',value: 'type',liste: [{ text: 'Générer le document', value: 'generate' },{ text: 'mentionner le chemin du fichier', value: 'manual' }],
              defaultprops:{ show: true, column: false }}"
              :change="(item,value,select) => { typegenerate = value[select]; type }"
              :valField="typegenerate"
            />  
            <TextField
            v-if="typegenerate=='manual'"
            :item="{ name: 'Chemin du fichier', value: 'root' ,defaultprops:{ show: true }}"
            :valField="manual"
            :keyup="(item,model)=> { manual = model; displays.params.root =model;  }"
            /> 

            <v-btn @click="generate" :disabled="!typegenerate || (typegenerate=='manual' && manual=='')">
              Appliquer
            </v-btn>
        </v-col>
      </v-row>
    </div>

      <div v-else>


        <v-tabs align="start" background-color="blue lighten-5" v-model="tab">
          <v-tab> Paramètres généraux </v-tab>
          <v-tab> requetes </v-tab>
          <v-tab> Champs </v-tab>
          <v-tab> Stockage </v-tab>

          <v-tabs-items v-model="tab" class="pl-4">

            <v-tab-item>
              <v-row>
                <v-col class="ma-1">
                  <TextField
                    :item="{ name: 'chemin du document', value: 'root' ,defaultprops:{ show: true }}"
                    :valField="displays.params.root"
                    :keyup="(item,model,selectedKey)=> { displays.params.root = model; $emit('recepVal',displays); }"
                    /> 
                    <p>Vous pouvez utiliser la variable [[pdf||mon_as]] pour personnaliser le nom (ne pas mettre l'extension .pdf)</p>
                    <TextField
                    :item="{ name: 'Nom du document', value: 'documentname' ,defaultprops:{ show: true }}"
                    :valField="displays.params.documentname"
                    :keyup="(item,model,selectedKey)=> { displays.params.documentname = model; $emit('recepVal',displays); }"
                    /> 
                    <SwitchC
                    :item="{name: 'Afficher le bouton de téléchargement',value: 'download',
                    defaultprops:{ show: true, column: false }}"
                    :change="(item,model,selectedKey)=> { displays.params.download = model; $emit('recepVal',displays); }"
                    :valField="displays.params.download"
                    /> 
                    <TextField
                    :item="{ name: 'height', value: 'height' ,defaultprops:{ show: true }}"
                    :valField="displays.params.iframe.height"
                    :keyup="(item,model)=> { displays.params.iframe.height = model; $emit('recepVal',displays); }"
                    /> 
                    <TextField
                    :item="{ name: 'width', value: 'width' ,defaultprops:{ show: true }}"
                    :valField="displays.params.iframe.width"
                    :keyup="(item,model)=> { displays.params.iframe.width = model; $emit('recepVal',displays); }"
                    /> 
                </v-col>
              </v-row>
            </v-tab-item>

            <v-tab-item>
              <Filtre
                :filtre="displays.filter"
                :table="module.table"
                @recepVal="changeFilter"
              />
            </v-tab-item>

          <v-tab-item>
            <Fields
              :table="module.table"
              :champs="displays.params.champs"
              :filter="displays.filter"
              :page="page"
              @recepVal="changeEC"
              />
          </v-tab-item>

          <v-tab-item>
            Vous pouvez stocker le PDF une fois celui-ci généré.
            <Select
              :item="{name: 'Configuration de stockage',value: 'type',liste: [{ text: 'pas de stockage', value: '' },{ text: 'dans une table', value: 'table' },{ text: 'dans la GED', value: 'ged' }],
              defaultprops:{ show: true, column: false }}"
              :change="(item,value,select) => { displays.params.storage.type = value[select]; $emit('recepVal',displays); }"
              :valField="displays.params.storage.type"
            /> 

            <div v-if="displays.params.storage.type=='ged' && listGedModule.length">
             Pour stocker le document dans la GED, vous devez créer un module GED pour les paramétrages.
             <Select
              :item="{name: 'Choix du module GED',value: 'type',liste: listGedModule,
              defaultprops:{ show: true, column: false }}"
              :change="(item,value) => {displays.params.storage.ged.module = value['value']; $emit('recepVal',displays);}"
              :valField="displays.params.storage.ged.module"
            /> 
              Param à faire passer pour ouvrir la GED
            <ArrayTable 
              :item="{otherparams:{
                  arrayTable:[
                      {type:'',key:'name',name:'nom du paramètre'},
                      {type:'',key:'value',name:'valeur du paramètre',description:'Vous pouvez insérer la variable d\'envirronnement [[line||mon_as]]'}]}
              ,defaultprops:{show:true,tableDraggable:false,tableDel:true,hideFooter:true,hidesearch:true,addTitle:'Ajouter un paramètre pour l\'utilisation de la GED'}}"
              :valField="displays.params.storage.ged.params"
              :change="(item,value) => {displays.params.storage.ged.params = value; $emit('recepVal',displays);}"
              />
              <!--
            <Select
            v-if="displays.params.storage.ged.module!='' && gedFolder().length!=0"
              :item="{name: 'Dossier',value: 'folder',liste: gedFolder(),
              defaultprops:{ show: true, itemtext:'name',description:'La sélection est facultative' }}"
              :change="(item,value) => {displays.params.storage.ged.folder = value['name']; $emit('recepVal',displays);}"
              :valField="displays.params.storage.ged.module"
            /> -->

            </div>
            <div v-if="displays.params.storage.type=='table'">
              Sélectionnez la table qui va faire l'objet de l'entrée
              <Select
              :item="{name: 'Table',value: 'type',liste: listTables,
              defaultprops:{ show: true, column: false }}"
              :change="tableStockage"
              :valField="displays.params.storage.params.table"
            /> 

              <div v-for="(field, i) in fieldsTableStockage" :key="i">
                <TextField
                  :item="{ name: field.table+' '+field.name, value: 'width' ,defaultprops:{ show: true }}"
                  :valField="displays.params.iframe.width"
                  :keyup="(item,model)=> { displays.params.iframe.width = model; $emit('recepVal',displays); }"
                  /> 
              </div>
            </div>

          </v-tab-item>

          </v-tabs-items>
        </v-tabs>
    </div>
    </v-card>
  </template>
  
  
  
  <script>
  export default {
    name: "PdfModule",
    props: ["module", "page"],
  
    components: {
      Filtre: () => import("../outils/constructFiltre/Filtre.vue"),
      Fields: () => import("../outils/constructField/Field.vue"),
      TextField: () => import("../../fields/textfield.vue"),
      SwitchC: () => import("../../fields/switch.vue"),
      Select: () => import("../../fields/select.vue"),
      ArrayTable: () => import("../../fields/arrayTable.vue"),
    },
  
    data: function () {
      return {

        displays: this.module.displays,
        typegenerate : '',
        manual : '',
        tab : null,
        listTables : [],
        fieldsTableStockage : [],
        listGedModule : [],

      };
    },
  
    watch: {

    },
  
    methods: {

      generate() {
        this.API({type:'post', url:'pdf/generatefile', params:{id:this.module.id, type:this.typegenerate,manual : this.manual}}).then((resp) => {
          if(resp.data.success) {
            this.$toast.success('génération effectuée')
          } else {
            this.$toast.error(resp.data.message)
          }
        })
      },

      changeFilter(array) {
      this.displays.filter = array
      this.$emit('recepVal',this.displays)
    },

    changeEC(array) {
      this.displays.params.champs = array
      this.$emit('recepVal',this.displays)     
    },

      tableStockage(iem,value,selectedKey) {

        this.displays.params.storage.params.table = value[selectedKey]; 
        
        this.getListField({filter:{table:value[selectedKey]}}).then((resp) => {
          this.fieldsTableStockage = resp       
        })
        this.$emit('recepVal',this.displays);
      },

      // ne serv pas encore mais c'est pour choisir le dossier (créé automatiquement sur 1ere ouverture de GED) ou le pdf va etre stocké
      gedFolder() {

        return this.listGedModule.find( el => el.value == this.displays.params.storage.ged.module).listfolder
      }
  
    },
  
    mounted() {
        //récupère toutes les tables pour le choix du champ pour la liste déroulante au dessus du calendrier
        this.API({type:'get', url:'engine/getlisttable'}).then((resp) => {
            resp.data.forEach((table) => {
                this.listTables.push({text:table,value:table})
            })
            
        })

        this.API({type:'post', url:'/engine/module/list',params:{type:'ged'}}).then((resp) => {                                             

                       resp.data.forEach((ged) => {
                        this.listGedModule.push({text:ged.name,value:ged.id,listfolder:ged.displays.params.foldersonopen});
                       })
               })
    },
  };
  </script>
  
  