const getFormValues = {
    state: {

        listForm: [],

        IndependantForm: [],

    },

    getters: {

        allListForm: (state) => {
            return state.listForm
        },
        listForm: (state) => (id) => {
            return state.listForm.find(specific => specific.page === id)
        },

        listIFormByKey: (state) => (key) => {
            return state.IndependantForm.find(specific => specific.key === key)['values']
        },

        listIForm: (state) => {
            return state.IndependantForm
        },

    },

    mutations: {

        pushForm: function(state, liste) {
            var ee = {};
            if (liste.key) {

                ee[liste.champ] = {};
                ee[liste.champ][liste.key] = liste.value
            } else {
                ee[liste.champ] = (liste.value == null) ? '' : liste.value;
            }
            var list = state.listForm.find(specific => specific.page === liste.page)['fields'];
            state.listForm.find(specific => specific.page === liste.page)['fields'] = {...list, ...ee };
        },

        pushIForm: function(state, liste) {
            var ee = {};
            if (!state.IndependantForm.find(specific => specific.key === liste.key)) {
                state.IndependantForm.push({ key: liste.key, values: {} });
            }
            ee[liste.champ] = (liste.value == null) ? '' : liste.value;

            var list = state.IndependantForm.find(specific => specific.key === liste.key)['values'];

            state.IndependantForm.find(specific => specific.key === liste.key)['values'] = {...list, ...ee };
        },

        initForm: function(state, liste) {
            var form = { page: 0, fields: {}, valid: false, validate: 0, typeform: liste.typeform, params: {}, url: '' }
            form = {...form, ...liste }
            state.listForm = state.listForm.filter(x => {
                return x.page != liste.page;
            })
            state.listForm.push(form)
        },

        pushFirstForm: function(state, liste) {
            var form = { page: liste.page, fields: liste.values, validate: false }
            state.listForm = state.listForm.filter(x => {
                return x.page != liste.page;
            })
            state.listForm.push(form)
        },

        removeForm: function(state, page) {
            delete state.listForm.find(specific => specific.page === page)
        },

        changeParamForm: function(state, param) {
            state.listForm.find(specific => specific.page === param.page)[param.type] = param.value
        },

    },

    actions: {

        pushForm: function({ commit }, liste) {
            commit('pushForm', liste)
        },

        pushIForm: function({ commit }, liste) {
            commit('pushIForm', liste)
        },

        initForm: function({ commit }, liste) {
            commit('initForm', liste)
        },
        pushFirstForm: function({ commit }, liste) {
            commit('pushFirstForm', liste)
        },

        removeForm: function({ commit }, page) {
            commit('removeForm', page);
        },
        changeParamForm: function({ commit }, param) {
            commit('changeParamForm', param);
        },

    }

}
export default getFormValues;