import api from '../services/api'

import axios from 'axios';

const api2 = axios.create();

const axiosQ = {
    state: {

        listQueue: [],
        axiosLoading: false,
        axiosError: false,
        messageError: [],
        loading: false

    },

    getters: {
        getErrorAPI: (state) => {
            return state.error
        },
        axiosLoading: (state) => {
            return state.axiosLoading
        },
        axiosError: (state) => {
            return state.axiosError
        },
        messageError: (state) => {
            return state.messageError
        },
        Queue: (state) => {
            return state.listQueue
        },
    },

    mutations: {

    },

    actions: {


        callAxios: function({ state, dispatch }, query) {

            return new Promise((resolve) => {
                state.axiosLoading = true;

                if (!state.listQueue.find(el => el.token === query.token)) state.listQueue.push(query)

                const querySend = state.listQueue[0];

                var paramsSend = (querySend.type == 'get') ? { params: querySend.params } : querySend.params;
                state.loading = true



                if (querySend.token != query.token) {
                    setTimeout(function() { resolve(dispatch('callAxios', query)) }, 200);


                } else {

                    // permet d'appliquer d'autres instances dans la fonction api 

                    if (querySend.apidefault) {
                        var guarded = {}

                        for (let i in querySend.apidefault) {

                            if (typeof querySend.apidefault[i] === 'object') {
                                for (let p in querySend.apidefault[i]) {
                                    guarded[i] = {}
                                    guarded[i][p] = api.defaults[i].common[p];
                                    api.defaults[i].common[p] = querySend.apidefault[i][p];
                                    api2.defaults[i].common[p] = querySend.apidefault[i][p];
                                }
                            } else {
                                guarded[i] = api.defaults[i]
                                api.defaults[i] = querySend.apidefault[i];
                                api2.defaults[i] = querySend.apidefault[i];
                            }
                        }

                    }

                    if (querySend.url.includes('https://')) {

                        api2[querySend.type](querySend.url, paramsSend)
                            .then((resp) => {
                                state.loading = false

                                state.listQueue = state.listQueue.filter(el => el.token !== querySend.token)
                                if (state.listQueue.length == 0) state.axiosLoading = false;

                                resolve(resp)



                            }, (error) => {
                                dispatch('getoverlay', false);
                                dispatch('loadfalse')
                                state.listQueue = state.listQueue.filter(el => el.token !== querySend.token)
                                state.axiosError = true
                                state.axiosLoading = false;
                                state.messageError.push({ send: querySend, error: error })
                                    //reject(error)
                            })

                    } else {

                        api[querySend.type](querySend.url, paramsSend, {
                            validateStatus: function(status) {
                                return status == 200 || status == 500;
                            }
                        }).then((resp) => {
                            state.loading = false
                            if (guarded) {
                                for (let i in guarded) {
                                    if (typeof guarded[i] === 'object') {
                                        for (let p in guarded[i]) {
                                            api.defaults[i].common[p] = guarded[i][p];
                                            api2.defaults[i].common[p] = guarded[i][p];
                                        }
                                    } else {
                                        api.defaults[i] = guarded[i];
                                        api2.defaults[i] = guarded[i];
                                    }
                                }
                            }
                            state.listQueue = state.listQueue.filter(el => el.token !== querySend.token)
                            if (state.listQueue.length == 0) state.axiosLoading = false;

                            if (resp.status == 500) {
                                let ee = { data: { success: false, message: resp.data.message } };
                                //console.log(querySend.url)
                                //console.log(resp.data.message)
                                //console.log(resp.data.file)
                                //console.log(resp.data.line)
                                resolve(ee)
                            } else {
                                resolve(resp)
                            }




                        }).catch(error => {

                            dispatch('getoverlay', false);
                            dispatch('loadfalse');

                            // si il y a une deconnection :
                            if (error.response.data.message == 'noconnect') {
                                localStorage.removeItem("tokenMinu");
                                location.reload();
                            }

                            state.listQueue = state.listQueue.filter(el => el.token !== querySend.token)
                            state.axiosError = true
                            state.axiosLoading = false;
                            state.messageError.push({ send: querySend, error: error })
                        })
                    }
                }


            })

        },

    }

}
export default axiosQ;