<template>
  <div>

        <v-row>

                <v-col class="col-12 col-md-2 mx-auto">
            <datepicker 
            :valField="moment(calendarDate).format('YYYY-MM-DD')"
            :item="{value:'ee',defaultprops:{show:true,iconlr:'prepend',iconinout:'in',iconmdi:'mdi-calendar'}}"
            :change="(item,model) => { if(!firstLoad) calendarApi.gotoDate( model ); $router.push({ query: { ...$route.query, date: model } }).catch(() =>{}); }"
            />
            </v-col>
        </v-row>


<v-progress-linear
:indeterminate="onLoading"
></v-progress-linear>

    <FullCalendar 
    ref="fullCalendar" 
    :options="calendarOptions"
    >
 
    <template v-slot:eventContent='arg' >  
                
        <v-tooltip right :min-width="arg.event.extendedProps.tooltipMinWidth" :disabled="isDropOrResize">
        <template v-slot:activator="{ on, attrs }" >

                <div class='fc-event-main-frame' v-bind="attrs" v-on="on"  >
                    <v-expand-transition>
                        <div
                        v-if="attrs['aria-expanded']=='true' && isDropOrResize===false"
                        class="grey lighten-3"
                        style="height: 30px;"
                        >
                            <v-tooltip top v-for="(action,i) in arg.event.extendedProps.actions" :key="i">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon small >
                                        <v-icon v-bind="attrs" @click="callAction(action,arg.event.extendedProps.otherfields)" v-on="on">{{action.icon}}</v-icon>
                                        </v-btn>                                
                                </template>
                                <span>{{action.name}}</span>
                        </v-tooltip>

                        <v-btn icon small class="red--text" @click="delEvent(arg.event)"><v-icon right>mdi-delete-outline</v-icon></v-btn>


                        </div>
                    </v-expand-transition>
                    <div class='fc-event-time' v-if="params.vue.hoursbar" 
                    :style="[
                        { 'background-color': arg.event.extendedProps.colortime }, arg.event.extendedProps.colorcontourtime ? { 'border': '2px solid '+arg.event.extendedProps.colorcontourtime } : ''
                        ]"
                        >
                    {{ arg.timeText }}</div>
                    <div class='fc-event-title-containur er'>
                        <div class='fc-event-title'>
                            <div>

                                <Cpage 
                                :pageTemplate="arg.event.extendedProps.encart" 
                                :page="page" 
                                :modal="modal" 
                                :form="false" 
                                hook="hook"
                                :mod="false"/>

                            </div>
                        </div>
                    </div>
                </div>

            </template>

            <Cpage 
            :pageTemplate="arg.event.extendedProps.tooltip" 
            :page="page" 
            :modal="modal" 
            :form="false" 
            hook="hook"
            :customparams="arg.event.extendedProps.otherfields"
            :mod="false"/>

            </v-tooltip>
    </template>
 
    </FullCalendar>
    <!-- on ne met pas le template v-slot car les texte dépase l'encart du RDV... -->
  </div>
</template>

<script>

import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'

import datepicker from '../../fields/datePicker'

import frLocale from '@fullcalendar/core/locales/fr';
import Cpage from '../../engine/outils/constructPage/Page'

export default {

  components: {
    FullCalendar, // make the <FullCalendar> tag available
    datepicker,
    Cpage
  },

  props: ["idmodule", "page", "modal", "form"],

  data() {
    return {

        // permet d'&ttribuer les options du calendrier sur le premier chargement
        firstLoad : true,
        onLoading : false,
        fromThisModule : false,
        //représente l'instance de fullcalendar (voir dans le mounted)
        calendarApi : '',
        calendarDate : '',

        params : [],
        table : '',

        // permet de définir si ce module est intégré dans une page form (nouvelle:newform ou en mode édition:editform), ou une page classique : page
        typePage : (!this.$store.getters['listForm'](this.page)) ? 'page' : this.$store.getters['listForm'](this.page).typeform ,
        
        args : [],
        searchField : false,
        isDropOrResize : false,

        defaultFilter : false,
        initialDate : false,
        clickAction : false,

      calendarOptions: {

        plugins: [ dayGridPlugin, timeGridPlugin, interactionPlugin ],
        headerToolbar: {
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay'
        },
        initialView: 'timeGridWeek',
        hiddenDays : [],
        editable: true,
        selectable: true,
        eventDisplay: "block",
        selectMirror: true,
        dayMaxEvents: true,
        searchmodule: [],
        slotMinTime : '05:00:00',
        slotMaxTime : '22:00:00',
        height : 'auto',
        titleFormat : { year: 'numeric', month: 'long', day: 'numeric' },
        

        eventSources : [
            {
                events : (date, callback) => {
                    this.onLoading = true
                this.API({type:'post', url:'displaymodule', params:{params:this.args,id:this.idmodule,dates:date,querys:this.defaultFilter}}).then((resp) => {
                    let datas = resp.data;
                    // si premier chargement, on personnalise la vue en fonction des paramètres du module
                    if(this.firstLoad) {
                        this.params = datas.params
                        this.table = datas.table
                        this.searchmodule = datas.searchmodule
                        this.calendarOptions.hiddenDays = datas.params.vue.hiddenDays
                        this.calendarOptions.slotMinTime = datas.params.vue.minslot+":00:00";
                        this.calendarOptions.slotMaxTime = datas.params.vue.maxslot+":00:00";                                               
                        // le changeView oblige un rechargement des events
                        if(datas.params.vue.initialView!='timeGridWeek') this.calendarApi.changeView(datas.params.vue.initialView)

                        // creationd du champ de recherche :
                        this.searchField = datas.selectionliste

                        this.firstLoad = false
                    }
                    // permet de rafraichir la date dans le datepicker
                    this.calendarDate = this.calendarApi.getDate()

                    // on met dans le callback, la liste des rdv en format json
                    callback(datas.events)
                    this.onLoading = false
                    
                })
                }
            },

        ],

        progressiveEventRendering : true,
        select: this.newRDV,
        eventClick: this.clickRDV,
        eventResize: this.endResize,
        eventDrop : this.Drop,
        eventDragStart : ()=>{ this.isDropOrResize = true},
        eventDragStop : ()=>{ this.isDropOrResize = false},
        eventResizeStart : ()=>{ this.isDropOrResize = true},
        eventResizeStop : ()=>{ this.isDropOrResize = false},
        locale: frLocale
      },
    }
  },

  computed : {
        //si on est dans une modal, si la query change, on reload les tableau qui sont liés à la recherche
    isNewFilterOnModal() {
      return (this.$store.getters.modalShowParams({ type: 'to', val: this.page })) ? this.$store.getters.modalShowParams({ type: 'to', val: this.page }).query : this.$route.query;
    }
  },

  watch: {
    showMod: {
      // permet de rafraichir quand la modal de la page se ferme
      handler(val) {
        if (val.show === false && this.fromThisModule) {
            this.calendarApi.refetchEvents()
            this.fromThisModule = false;
        }
      },
      deep: true,
    },

        isNewFilterOnModal(val) {
            var find = false
            for(var key in val) {
            let findModSearch = key.split(/_(.*)/s);

            if(this.searchmodule) this.searchmodule.forEach( (idsearch) => {
                if (findModSearch[0] == idsearch)  {
                    this.defaultFilter[key] = val[key]
                    find = true
                }
            })
            if(find) this.calendarApi.refetchEvents();

    }

},

  },


    methods: {

        constructParams(selectInfo) {
            let allDay = (selectInfo.allDay) ? 1 : 0;
            let params = {}
            params['allday'] = selectInfo.allDay;
            // variables par défaut envoyé par le module
            this.params.vue.sendget.forEach((get)=> {

                switch(get['value']) {
                    case '[[start]]' :
                    params[get['key']] = this.moment(selectInfo.start).format('YYYY-MM-DD LT')
                        break;
                    case '[[end]]' :
                    params[get['key']] = this.moment(selectInfo.end).format('YYYY-MM-DD LT')
                        break;

                    case '[[allday]]' :
                    params[get['key']] = allDay
                        break;
                    default :
                    params[get['key']] = this.switchValue(get['type'],this.replaceBy({page:this.page,text:get['value']}))
                }
                
            })
            //variable extérieures :
            for (var el in this.args) {
                params[el] = (isNaN(this.args[el])) ? this.args[el] : Number(this.args[el]) ;

            }

            params = {...params, ...this.defaultFilter}

            return params;
        },

        newRDV(selectInfo) {


            if(this.params.vue.newpage) {

        // si ce module est intégré dans une page de type form et que ce form on enregistre avant d'ajouter une ligne dans la table de ce module
        if (this.typePage=='new' || this.typePage=='edit') {
          
          // on enregistre le formulaire avant l'envoi sur la page d'édition de ce module' :
          this.validateForm({form:this.form,page:this.page,validate:1,
            ifNotValid:()=>{
              this.$toast.warning('Vérifiez certains champs non complétés',{position: "top-center",timeout: 3000});
            },
            callback:()=> {
                this.initArguments();
                let params = this.constructParams(selectInfo);
                this.goTo({rootPage:this.page,name:this.params.vue.newpage,typeform:'new',type:'modal',replace:params,paramDialog:{width:1000}})
                this.fromThisModule = true;
            }
          }) 

        } else {
          // si ce module est dans une page, on envoi la page d'édition de la table de ce module
          let params = this.constructParams(selectInfo);
          this.goTo({rootPage:this.page,name:this.params.vue.newpage,typeform:'new',type:'modal',replace:params,paramDialog:{width:1000}})
          this.fromThisModule = true;         
        }


                

            } else {

                if (confirm("la page d'édition de la table " + this.table + " n'est pas créée, voulez-vous la créer maintenant ? ")) {
                        // on créé la page d'édition à la volé
                        this.createNewPage({ name: 'edit' + this.table, type: 'form', table: this.table,url:'/interface/'+this.table+'/:id/edit' ,
                            callback:()=> {
                            this.goTo({ rootPage: this.page, name: 'new'+this.table,typeform:'new', type: 'modal', replace: { id:'new' }, paramDialog: {width:1000} })
                            this.fromThisModule = true;
                            }
                        }); 
                    //this.$toast.error('Page d\'édition non paramétrée');
                    }
            }
            

        },

        switchValue(type,value) {
            var val = value
            switch(type) {
                case 'number' :
                val = Number(value);
                break;
                case 'text' :
                val = value.toString();
                break;
                case 'bool' :
                val = (value=='false') ? false : true;
                break;
            }
            return val;
        },

        clickRDV(info) {
            // info.jsEvent.layerY est l'emplacement du click sur l'event. si il est en dessous des boutons d'actions, on affiche l'edition du RDV
            if(this.clickAction===false) {
                if(this.params.vue.editpage) {
                    var filter = {"id":info.event.id};
                    filter = {...filter, ...this.defaultFilter}
                    this.goTo({rootPage:this.page,name:this.params.vue.editpage,typeform:'edit',type:'modal',replace:filter,paramDialog:{width:1000}})
                    this.fromThisModule = true;
                } else {

                    if (confirm("la page d'édition de la table " + this.table + " n'est pas créée, voulez-vous la créer maintenant ? ")) {
                        // on créé la page d'édition à la volé
                        this.createNewPage({ name: 'edit' + this.table, type: 'form', table: this.table,url:'/interface/'+this.table+'/:id/edit' ,
                            callback:()=> {
                                this.params.vue.editpage = 'edit' + this.table
                                this.params.vue.newpage = 'new' + this.table
                            this.goTo({ rootPage: this.page, name: 'new'+this.table,typeform:'new', type: 'modal', replace: { id:info.event.id }, paramDialog: {width:1000}})
                            this.fromThisModule = true;
                            }
                        }); 
                    //this.$toast.error('Page d\'édition non paramétrée');
                    }
                }
            
            }

        },

        endResize(el) {
            this.API({type:'post', url:'displaymodule', params:{id:this.idmodule,action:'resize',event:el.event}}).then((resp) => {
                if(!resp.data.success) {
                    this.$toast.error(resp.data.message);
                    this.calendarApi.refetchEvents()
                }
            })

        },

        Drop(el) {

            this.API({type:'post', url:'displaymodule', params:{id:this.idmodule,action:'drop',event:el.event}}).then((resp) => {
                if(!resp.data.success) {
                    this.$toast.error(resp.data.message);
                    this.calendarApi.refetchEvents()
                }
            })
            
        },

        delEvent(event) {
            this.clickAction = true
            if(confirm('Vous allez supprimer ce rendez-vous, confirmer ?')) {
            this.API({ type: 'post', url: 'interface/'+this.table+'/'+event._def.publicId+'/delete' }).then((resp) => {
              if(resp.data.success) {
                if(resp.data.message) this.$toast.success(resp.data.message);
                this.calendarApi.refetchEvents()
              } else {
                this.$toast.error(resp.data.message);
              }
            });
          } 
          setTimeout(()=>{
                this.clickAction = false
            },1000)
        },

        callAction(action,event) {
            this.clickAction = true
                this.clickActions({args:event,action:action,idmodule:this.idmodule,page:this.page,selectedLines:false,
                success:(datas) => {
                    if(datas.message) this.$toast.success(datas.message);
                    this.calendarApi.refetchEvents()
                },
                whenlink:() => {
                    this.fromThisModule = true;
                }
                }) 

            setTimeout(()=>{
                this.clickAction = false
            },1000)
            
        },

        initArguments() {
            // permet de récupérer les "POST" pour voir si gotodate (ou autre) est défini
            this.args = this.$store.getters.specificPage(this.page).params
            //permet de supplenter la vue par défaut du calendrier si le POST defaultFilter est crée
            this.defaultFilter = this.$store.getters.specificPage(this.page).querys            
        }

    },

    mounted()
 {
    
    // permet de faire un next() ou gotoDate() etc.
    this.calendarApi = this.$refs.fullCalendar.getApi()  
    // permet l'affichage de la date dans le datepicker
    this.calendarDate = this.calendarApi.getDate()

 }, 
 
    created() {
    
        this.initArguments();

    this.calendarOptions.initialDate = (this.defaultFilter['date']) ? this.defaultFilter['date'] : this.moment().format('YYYY-MM-DD');


    }

}
</script>

<style>

.fc-timegrid-event {
    padding:0px;
}
.fc-event-main {
    padding:0px;
    font-size:12px;
}
div.fc-event-time {

font-size:9px;
background-color:grey;
}
/* permet sur la vue du mois, que les events ne dépassent pas en width.. */
.fc-daygrid-event {
    white-space:none;
}

</style>