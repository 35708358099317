var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-row',[_c('v-col',{staticClass:"col-md-6"},[_c('TextField',{attrs:{"item":{name:'Nom du bouton d\'action',value:'name',defaultprops:{show:true,modelRules:[v => !!v || 'Ce champ ne doit pas être vide']}},"keyup":_vm.updateParams,"valField":_vm.params.name}}),(_vm.params.params)?_c('TextField',{attrs:{"item":{name:'Texte de confirmation avant action',value:'ifconfirm',defaultprops:{show:true}},"keyup":_vm.updateDeepParams,"valField":_vm.params.params.ifconfirm}}):_vm._e(),_c('Select',{attrs:{"valField":_vm.params.typelink,"change":_vm.updateParams,"item":{name:'que souhaitez vous faire ?',
                    value:'typelink',
                    liste:[{text:'effectuer une action sur l\'API',value:'api'},{text:'envoi sur une page',value:'link'},{text:'lien href classique',value:'href'}],
                    defaultprops:{show:true,modelRules:[v => !!v || 'Ce champ ne doit pas être vide']}}}}),(_vm.params.typelink=='api')?_c('Select',{attrs:{"valField":_vm.params.params.apilink.type,"change":_vm.updateAPIlink,"item":{name:'type d\'envoi',
                    value:'type',
                    liste:[{text:'GET',value:'get'},{text:'POST',value:'post'},{text:'PUT',value:'put'}],
                    defaultprops:{show:true,modelRules:[v => !!v || 'Ce champ ne doit pas être vide']}}}}):_vm._e(),(_vm.params.typelink=='api' || _vm.params.typelink=='href')?_c('TextField',{attrs:{"item":{name:'URL',value:'url'
                    ,defaultprops:{show:true,modelRules:[v => !!v || 'Ce champ ne doit pas être vide'],
                    description:'Vous pouvez placer la variable d\'envirronnement \'table\' pour cibler les valeurs du tableau',persistenthint:true }},"keyup":_vm.updateAPIlink,"valField":_vm.params.params.apilink.url}}):_vm._e(),(_vm.params.typelink=='api')?_c('SwitchC',{attrs:{"item":{name:'placer en entete de tableau (visible si une sélection est faite)',value:'head',
                    defaultprops:{show:true,description:'modifiez la valeur du tableau \'[[table||id]]\' par \'selected\' dans l\'URL afin de cibler toutes les lignes sélectionnées ',
                    persistenthint:true}},"change":_vm.updateDeepParams,"valField":_vm.params.params.head}}):_vm._e(),(_vm.params.typelink=='link')?_c('ListMultiple',{attrs:{"change":_vm.updateParams,"item":{name:'Envoi sur la page :',value:'page_name',liste:_vm.listePages,
                    defaultprops:{show:true,modelRules:[v => !!v || 'Ce champ ne doit pas être vide']}},"valField":_vm.params.page_name}}):_vm._e()],1),_c('v-col',{staticClass:"col-md-6"},[_c('ArrayTable',{attrs:{"item":{otherparams:{
                        arrayTable : _vm.urlParams }
                    ,defaultprops:{show:true,tableDraggable:false,tableDel:true,hideFooter:true,hidesearch:true,addTitle:'ajouter un param. URL'}},"valField":_vm.params.get,"change":_vm.updateGet}}),(_vm.params.params)?_c('div',{staticClass:"elevation-1 ma-1 pa-2 grey lighten-4"},[_vm._v(" Conditions pour affichage : variable utilisé pour cibler la ligne : [[line||mon_AS]] "),_c('groupConditions',{attrs:{"conditions":_vm.params.params.conditions},on:{"recepVal":(array) => { _vm.updateConditions(array) }}})],1):_vm._e(),(_vm.params.typelink=='link')?_c('Select',{attrs:{"valField":_vm.params.target,"change":_vm.updateParams,"item":{name:'Type d\'ouverture',value:'target',liste:[{text:'mode dialog',value:'modal'},{text:'en centre',value:'center'}]
                    ,defaultprops:{show:true,modelRules:[v => !!v || 'Ce champ ne doit pas être vide']}}}}):_vm._e(),_c('TextField',{attrs:{"valField":_vm.params.icon,"keyup":_vm.updateParams,"item":{name:'Icone',value:'icon',defaultprops:{show:true}}}})],1)],1),(_vm.params.target=='modal' && _vm.params.typelink=='link')?_c('div',[_c('paramDialog',{attrs:{"page":_vm.page,"params":_vm.params.paramdialog},on:{"returnParamDialog":_vm.ChangeParamDialog}})],1):_vm._e()],1)],1),_c('v-row',[_c('v-col',{staticClass:"col-12 text-center"},[_c('v-btn',{staticClass:"primary",attrs:{"disabled":!_vm.valid},on:{"click":_vm.validate}},[_vm._v("Enregistrer")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }