<template>
<div v-if="module">

<v-form 
    ref="form"
    v-model="valid"
    lazy-validation
>
<v-row>

    <v-col>

                <component 
                v-if="loaded"
                v-bind:is="type" 
                :module="module"
                :page="page" 
                :listJonctionsTables="listJonctionsTables"
                @recepVal="changeDisplay"/>

    </v-col>
</v-row>
    <v-row>
        <v-col class="text-center">
            <v-spacer></v-spacer>
         <v-btn class="primary" @click="validate" :disabled="!valid" >Enregistrer</v-btn>   
        </v-col>
    </v-row>
</v-form>
</div>

</template>


<script>

import recherche from './Recherche'
import formulaire from './Formulaire'
import table from './Table'
import notype from './noType'
import calendar from "./Calendar/Calendar.vue"
import tabs from "./Tabulations/Tabs.vue"
    export default {
        name : 'editVariant',
        props : ['page','modal'],

        data: function() {
            return { 

                valid : false,
                module : false,
                loaded : false,
                listJonctionsTables : [],
                // permet de connaitre l'id qui va etre updaté (si modal on prend les infos de la modal, si page pleine on prend le router.params.id)
                args : {},
                

            }
        },


        methods : {

            validate(){

                 this.$store.dispatch('getoverlay',true)
                 this.API({type:'put', url:'engine/module/editvariant', params:{id:this.args.id, displays:this.$store.getters.listForm(this.page)['fields']['displays'],variant : this.args.variant}}).then((resp) => {
                    
                    this.module = resp.data.module
                    delete this.module.allFields
                    delete this.module.actions

                        this.$store.dispatch('pushFirstForm', { page: this.page, values: this.module });
                        this.$store.dispatch('getoverlay',false)
                    })


            },
            changeDisplay(data) {

                this.$store.dispatch('pushForm', { page:this.page, value:data, champ:'displays' })
            },
            
            getFromAPI() {

                this.API({type:'post', url:'engine/module/edit', params:{filter:this.args}}).then((resp) => {    

                    this.module = resp.data.module
                    delete this.module.allFields
                    delete this.module.actions
                    this.listJonctionsTables = resp.data.listJonctionsTables;

                    this.$store.dispatch('pushFirstForm', { page: this.page, values: this.module });
                    this.getTemplate();
                        this.loaded = true;
                    })

            },

        getTemplate() {

            switch (this.module.type) {
                case 'table':
                    this.type = table
                    break;
                case 'form':
                    this.type = formulaire
                    break;
                case 'search':
                    this.type = recherche
                    break;
                case 'calendar':
                    this.type = calendar
                    break;
                case 'tabs':
                    this.type = tabs
                    break;
                default:
                    this.type = notype
                    break;
            }

        }


        },
    
        mounted() {          
            
        this.args = (!this.modal) ? this.$route.params : this.$store.getters.modalShowParams({type:'to',val:this.page}).params

        this.getFromAPI()
        }

    }

</script>