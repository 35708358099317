<template>
    <v-card elevation="2" shaped class="ma-1">
      <v-toolbar flat color="primary" dark>
        <v-toolbar-title>Paramètres FrontDatas</v-toolbar-title>
      </v-toolbar>

      <div>

        <v-tabs align="start" background-color="blue lighten-5" v-model="tab">
          <v-tab> Liste des requetes </v-tab>
          <v-tab>Module de recherche</v-tab>
          
          <v-tabs-items v-model="tab" class="pl-4">

            <v-tab-item>
              Nouvelle requete <v-btn fab small @click="addQuery()"><v-icon>mdi-plus</v-icon></v-btn>
                <v-expansion-panels variant="popout" v-model="accordion" class="pa-3">
              <v-expansion-panel v-for="(query, i) in displays.params.querys" :key="i">
                <v-expansion-panel-header>
nom du store : {{ query.storename }}
                  <template v-slot:actions>
                    <v-btn icon @click="removeQuery(i)"><v-icon>mdi-close-thick</v-icon></v-btn>
                  </template>                 
                </v-expansion-panel-header>

                <v-expansion-panel-content class="grey lighten-3">
                    <TextField 
                    :item="{name:'nom du store',value:'storename',defaultprops:{show:true}}" 
                    :keyup="(item,value,selectedKey) => {changeOptionsQuery(item,value,selectedKey,i)}"
                    :valField="query.storename" />
                  <v-tabs v-model="tab2">
                      <v-tab> Filtre </v-tab>
                      <v-tab> Champs </v-tab>
                      <v-tabs-items v-model="tab2" class="pl-4">

                        <v-tab-item>
                          <Filtre :page="page" :filtre="query.filtre" :table="module.table" @recepVal="(data)=> {changeFilter(data,i)}" />
                        </v-tab-item>
                        <v-tab-item>
                          <Fields :page="page" :champs="query.champs" :filter="query.filtre" :table="module.table" />
                        </v-tab-item>
                      </v-tabs-items>
                  </v-tabs>
                </v-expansion-panel-content>  

              </v-expansion-panel>
            </v-expansion-panels>

            </v-tab-item>

            <v-tab-item>
            
              <!-- Modules de recherche -->
              <div class="text-h6">Si le ou les modules sélectionnés se trouvent sur la même page, ils interagieront avec
                le tableau</div>

              <SimpleTable :Headers="[
                { text: 'ID', value: 'id' },
                { text: 'champs', value: 'champs' },
              ]" :datas="dataTableSearch" :selected="displays.params.searchmodule" keyRow="id"
                @getselected="(val) => {this.displays.params.searchmodule = val; }" />

            </v-tab-item>


          </v-tabs-items>
        </v-tabs>
    </div>
    </v-card>
  </template>
  
  
  
  <script>
  export default {
    name: "BibliothequeModule",
    props: ["module", "page"],
  
    components: {
      //Autocomplete: () => import("../../fields/autocomplete.vue"),
      Filtre: () => import("../outils/constructFiltre/Filtre.vue"),
      Fields: () => import("../outils/constructField/Field.vue"),
      TextField: () => import("../../fields/textfield.vue"),
      SimpleTable: () => import("../outils/simpleTable.vue"),

    },
  
    data: function () {
      return {

        displays: this.module.displays,
        listFields : [],
        accordion : false,
        tab : '',
        tab2 : '',
        dataTableSearch : []

      };
    },
  
    watch: {
    showMod: {
      // permet de rafraichir quand la modal de la page se ferme
      handler(val) {
        if (val.show === false) {
          //this.reload++;
        }

      },
      deep: true,
    },

  },
  
    methods: {
        addQuery() { 
      let copieDefault = JSON.parse(JSON.stringify(this.displays.params.default))
      this.displays.params.querys.push(copieDefault);
      this.$emit('recepVal',this.displays);
    },

    removeQuery(key) {
      this.displays.params.querys= this.displays.params.querys.filter((el,i)=> i!=key);
      this.$emit('recepVal',this.displays);
    },

    changeOptionsQuery(item,value,selectedKey,key) {
      var res = (typeof value ==='object') ? value[selectedKey] : value
      this.displays.params.querys[key][item.value] = res
      this.$emit('recepVal',this.displays);
    },

    changeFilter(data,key) {
      this.displays.params.querys[key]['filtre'] = data
      this.$emit('recepVal',this.displays);
    },

    getsearchFromAPI() {
      this.API({ type: 'post', url: "/engine/module/list", params: { type: 'search' } }).then((resp) => {

        resp.data.forEach((v) => {
          this.dataTableSearch.push({ id: v.id, champs: v.displays.params.champs.join(', ') });
        });
        // pour intégrer la recherche globale :
        this.dataTableSearch.push({ id: 'global', champs: 'sensible au champ de la recherche globale' });
      });
    },
  
    },
  
    mounted() {
      this.getsearchFromAPI()
    },
  };
  </script>
  
  