<template>

    <div>
        <v-text-field
          v-model="barcode"
          label="Scannez un code-barres"
          @keyup.enter="onBarcodeScanned"
        />
        </div>
    
    </template>
    
    
    
    <script>

        export default {
        props: ['idmodule','page','modal','parent'],
    
            data: function() {
    
                return {
                    AllowedDisplay : true,
                    datas : [],
                    params:{},
                    querys: {},
                    barcode: '',
                }
            },

        computed : {

        },

        watch : {

        },

            methods :  {    
                callAPI : function() {
                    this.API({type:'post', url:'displaymodule', params:{id:this.idmodule,params:this.params,querys:this.querys,barcode:this.barcode}}).then((resp) => {

                        this.manageDatas(resp.data)
                            
                    })
    
                },  

                manageDatas(data) {

                    if(data.ventilation) {

                        this.checkScenario({ ventilation: [data], page: this.page, row: 0 })
                        this.barcode = '';
                    }

                    if(!data.success) {
                        this.AllowedDisplay = false; 
                        return false;
                    }
                },

                onBarcodeScanned() {
                    this.callAPI()
                }
                
            },
    
            created() {  

            },

            mounted() {
                this.params = this.$store.getters.specificPage(this.page).params    
                this.querys = this.$store.getters.specificPage(this.page).querys
            }
    
        }
    
    </script>
    