<template>
    <v-card elevation="2" shaped class="ma-1">
      <v-toolbar flat color="primary" dark>
        <v-toolbar-title>Paramètres de la GED</v-toolbar-title>
      </v-toolbar>

      <div>

        <v-tabs align="start" background-color="blue lighten-5" v-model="tab">
          <v-tab> requete </v-tab>
          <v-tab> Paramètres de ciblage dossier </v-tab>
          
          <v-tabs-items v-model="tab" class="pl-4">

            <v-tab-item>


              <v-tabs align="start" background-color="blue lighten-5 ma-3" v-model="tab2">
          <v-tab> filtre </v-tab>
          <v-tab> champs </v-tab>
          
          <v-tabs-items v-model="tab2" class="pl-4">
              <v-tab-item>
                  <Filtre
                    :filtre="displays.filter"
                    :table="module.table"
                    @recepVal="(data) => {displays.filter = data; $emit('recepVal',displays); }"
                  />
              </v-tab-item>

              <v-tab-item>
                <Fields
                  :table="module.table"
                  :champs="displays.params.champs"
                  :filter="displays.filter"
                  :page="page"
                  @recepVal="changeChamps"
                  />
              </v-tab-item>
          </v-tabs-items>
          </v-tabs>




            </v-tab-item>

            <v-tab-item>

              <TextField 
              :item="{name:'Dossier racine',value:'root',defaultprops:{show:true,persistenthint:true,description:'chemin ou la GED va trouver les dossiers (Il peut être vide)'}}" 
              :keyup="(item,value) => { displays.params.root = value; $emit('recepVal',displays);}"
              :valField="displays.params.root" /> 

              <Select
              :item="{name: 'Type de navigation',value: 'navigation',liste: [{ text: 'libre', value: 'free' },{ text: 'ciblé à un dossier spécifique', value: 'folder' }],
              defaultprops:{ show: true}}"
              :change="(item,value,select) => { displays.params.navigation = value[select]; $emit('recepVal',displays); }"
              :valField="displays.params.navigation"
            /> 

            <div  v-if="displays.params.navigation=='folder'">
            <ArrayTable 
                :item="{otherparams:{
                    arrayTable:[
                        {type:'',key:'name',name:'Nom du dossier'}]}
                ,defaultprops:{show:true,tableDraggable:false,tableDel:true,hideFooter:true,hidesearch:true,addTitle:'ajouter un dossier dès ouverture'}}"
                :valField="displays.params.foldersonopen"
                :change="(item,value) => {displays.params.foldersonopen = value; $emit('recepVal',displays);}"
                />
                  <v-row>
                    <v-col class="grey lighten-3 rounded pa-3 ma-3">
                          <div class="text-h6">Ciblage du dossier</div>
                        Le dossier sera automatiquement créé dès la première ouverture de la GED
                        <Select
                          :item="{name: 'table ciblée',value: 'navigation',liste: listTables,
                          defaultprops:{ show: true}}"
                          :change="(item,value,select) => { displays.params.folderID.table = value[select]; $emit('recepVal',displays); }"
                          :valField="displays.params.folderID.table"
                        />    
                        <TextField 
                        :item="{name:'id ciblé',value:'id',defaultprops:{show:true,description:'(vous pouvez mettre la variable d\'envirronnement [[line||mon_as]]) qui provient de la requete du module'}}" 
                        :keyup="(item,value) => { displays.params.folderID.id = value; $emit('recepVal',displays);}"
                        :valField="displays.params.folderID.id" /> 

                        Vous pouvez rajouter un intitulé supplémentaire après l'identifiant du dossier (au moment de sa création, éléments séparés automatiquement par des espaces)
                        <ArrayTable 
                          :item="{otherparams:{
                              arrayTable:[
                                  {type:'',key:'name',name:'Intitulé',description:'Vous pouvez insérer la variable d\'envirronnement [[line||mon_as]]'}]}
                          ,defaultprops:{show:true,tableDraggable:false,tableDel:true,hideFooter:true,hidesearch:true,addTitle:'ajouter un intitulé sur le nom du dossier'}}"
                          :valField="displays.params.folderName"
                          :change="(item,value) => {displays.params.folderName = value; $emit('recepVal',displays);}"
                          />


                    </v-col>
                    <v-col class="grey lighten-3 rounded pa-3 ma-3">
                      <div class="text-h6">Dossier parent ?</div>
                        Mentionnez si le dossier ciblé appartient à un dossier ciblé parent
                        <Select
                          :item="{name: 'table ciblée',value: 'navigation',liste: listTables,
                          defaultprops:{ show: true}}"
                          :change="(item,value,select) => { displays.params.isChildFolder.table = value[select]; $emit('recepVal',displays); }"
                          :valField="displays.params.isChildFolder.table"
                        />    
                        <TextField 
                        :item="{name:'id ciblé',value:'id',defaultprops:{show:true,description:'(vous pouvez mettre la variable d\'envirronnement [[line||mon_as]]) qui provient de la requete du module'}}" 
                        :keyup="(item,value) => { displays.params.isChildFolder.id = value; $emit('recepVal',displays);}"
                        :valField="displays.params.isChildFolder.id" /> 
                    </v-col>
                  </v-row>                          
                </div>

            </v-tab-item>

          </v-tabs-items>
        </v-tabs>
    </div>
    </v-card>
  </template>
  
  
  
  <script>
  export default {
    name: "BibliothequeModule",
    props: ["module", "page"],
  
    components: {
      //Autocomplete: () => import("../../fields/autocomplete.vue"),
      Filtre: () => import("../outils/constructFiltre/Filtre.vue"),
      Fields: () => import("../outils/constructField/Field.vue"),
      TextField: () => import("../../fields/textfield.vue"),
      //listActions: () => import("../outils/listActions.vue"),
      Select: () => import("../../fields/select.vue"),
      ArrayTable: () => import("../../fields/arrayTable.vue"),

    },
  
    data: function () {
      return {

        displays: this.module.displays,
        tab : '',
        tab2 : '',
        listTables : [],
        listFields : [],

      };
    },
  
    watch: {


  },
  
    methods: {

      changeChamps(data) {
        this.displays.params.champs = data;
        this.listFields = [];
        data.forEach((field) => {
          this.listFields.push(field.as)
        })
        this.$emit('recepVal',this.displays);
      }

  
    },
  
    mounted() {
      this.API({type:'get', url:'engine/getlisttable'}).then((resp) => {
                    resp.data.forEach((table) => {
                        this.listTables.push({text:table,value:table})
                    })
                    
                })
    },
  };
  </script>
  
  