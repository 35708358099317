export default {

    methods: {

        visuelConditions(condParams) {
            let arrayCond = [];
            if (!condParams || !Array.isArray(condParams)) return [];
            condParams.forEach((cond) => {


                switch (cond.type) {

                    case 'groupcond':

                        var returnCond = this.visuelConditions(cond.content);
                        //si condition ET et qu'une des condition n'est pas remplis on retourn false
                        if (cond.andor == 'and') {
                            if (returnCond.includes(false) || !returnCond.length) { arrayCond.push(false) } else { arrayCond.push(true); }
                        }
                        //si condition OU est qu'on ne trouve pas de condition remplis on retourne false
                        if (cond.andor == 'or') {
                            if (!returnCond.includes(true) || !returnCond.length) { arrayCond.push(false) } else { arrayCond.push(true); }
                        }
                        break;


                    case 'value':
                        var variable = this.replaceBy({ text: cond.variable, page: this.page })
                        var value = this.replaceBy({ text: cond.value, page: this.page })
                        switch (cond.condition) {
                            case 'value':
                                arrayCond.push(variable === value);
                                break;
                            case 'like':
                                arrayCond.push(variable.indexOf(value) != -1);
                                break;
                            case '!=':
                                arrayCond.push(variable != value);
                                break;
                            case '*':
                                arrayCond.push(variable != '' && variable != 'undefined');
                                break;
                            case 'null':
                                arrayCond.push(variable == 'null');
                                break;
                            case 'empty':
                                arrayCond.push(variable == '');
                                break;
                            case 'istrue':
                                arrayCond.push(variable === true);
                                break;
                            case 'isfalse':
                                arrayCond.push(variable === false);
                                break;
                            case 'issup':
                                if (!isNaN(parseInt(variable)) && !isNaN(parseInt(value))) {
                                    arrayCond.push(parseInt(variable) > parseInt(value));
                                }
                                break;
                            case 'isinf':
                                if (!isNaN(parseInt(variable)) && !isNaN(parseInt(value))) {
                                    arrayCond.push(parseInt(variable) < parseInt(value));
                                }
                                break;
                            case 'isequal':
                                arrayCond.push(variable == value);
                                break;
                            default:
                                arrayCond.push(false);
                        }
                        break;
                }

            })
            return arrayCond;
        }

    }

}