<template>
    <v-card elevation="2" shaped class="ma-1">
      <v-toolbar flat color="primary" dark>
        <v-toolbar-title>Paramètres du module de Code barre </v-toolbar-title>
      </v-toolbar>
      <v-tabs align="start" background-color="blue lighten-5" v-model="tab">
            <v-tab> tab 1 </v-tab>
            <v-tab> scenario </v-tab>

            <v-tabs-items v-model="tab">
              <v-tab-item>

                <v-row>
                  <v-col class="ma-1">
                    <Select :item="{
                      name: 'type de code barre', value: 'typecode', liste: [{ text: 'Code barre libre', value: 'free' }, { text: 'Formaté sur le système', value: 'formatted' }],
                      defaultprops: { show: true }
                    }" :change="(item,value) => {displays.params.typecode = value['value']}"
                      :valField="displays.params.typecode" />
                  </v-col>
                </v-row>
  
              </v-tab-item>
  
            <v-tab-item>
              <div v-if="displays.params.typcode=='free'">
                variable pour le code barre : [[scenario||barcode]]
              </div>
              <div v-else>
                code barre formaté est décrypté : [id-entreprise]-[id-personnel]-[id-module]-[id-ligne]
                <br>
                variables utilisés : code barre cripté : [[scenario||barcode]]
                code barre décrypté avec ligne : [[scenario||idligne]]

              </div>
              <Cventilation :page="page" :table="module.table" :conditions="displays.params.scenario"/>
            </v-tab-item>
            
            </v-tabs-items>
              </v-tabs>
  
  
  
    </v-card>
  </template>
  
  
  
  <script>
  export default {
    name: "codebarreModule",
    props: ["module", "page"],
  
    components: {
      //Autocomplete: () => import("../../fields/autocomplete.vue"),
      //SimpleTable: () => import("../outils/simpleTable.vue"),
      //SwitchC: () => import("../../fields/switch.vue"),
      //Filtre: () => import("../outils/constructFiltre/Filtre.vue"),
      //Fields: () => import("../outils/constructField/Field.vue"),
      //TextField: () => import("../../fields/textfield.vue"),
      Select: () => import("../../fields/select.vue"),
      Cventilation: () => import("../outils/constructVentilation/Ventilation.vue"),
    },
  
    data: function () {
      return {
        tab : '',
        tab2 : '',
        allFields : [],
        displays: this.module.displays,

      };
    },
  
    watch: {

    },
  
    methods: {

  

  
      getFromAPI() {
        this.getListField({filter:{table:this.module.table}}).then((resp) => {
            this.allFields = resp;
          });
      },
  
  
    },
  
    mounted() {
      this.getFromAPI();
    },
  };
  </script>
  
  