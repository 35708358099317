<template>

 <div>

      <v-navigation-drawer
      v-model="drawer" 
      temporary       
        app

      >

        <v-list>
          <v-list-item class="px-2">
            <v-list-item-avatar v-if="$store.getters.currentUser.picture">
              <v-img :src="$store.getters.currentUser.picture"></v-img>
            </v-list-item-avatar>
          </v-list-item>
          <v-list-item link @click="(idPageMenuHead) ? $router.push({ name: idPageMenuHead }) : ''">

            <v-list-item-content>
              <v-list-item-title class="text-h6" >
                {{$store.getters.currentUser.name}} {{$store.getters.currentUser.nickname}}
              </v-list-item-title>
              <v-list-item-subtitle>{{$store.getters.currentUser.email}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list
          nav
          dense
        >

        <draggable class="menu" v-if="PagesLeft" :list="PagesLeft" group="b" @change="changeElem" handle=".handle">
          <div v-for="(route, a) in PagesLeft" :key="a">

          <v-list-item v-if="!route.children || route.children.length==0" :key="route.url" @click="goToPage(route)">
            

          <v-btn icon x-small class="handle" v-if="$store.getters.currentUser.type==3"><v-icon >mdi-arrow-up-down</v-icon></v-btn>


            <v-list-item-icon v-if="route.displays.params.icon">
              <v-icon >{{route.displays.params.icon}}</v-icon>
            </v-list-item-icon> 
            <v-list-item-title>
            {{ route.displays.displayname }}
            </v-list-item-title>



          </v-list-item>

          <v-list-group
            :prepend-icon="route.displays.params.icon"
            v-if="route.children && route.children.length>0" 

            
          >         

              <template v-slot:activator>

              <v-btn icon x-small class="handle" v-if="$store.getters.currentUser.type==3"><v-icon >mdi-arrow-up-down</v-icon></v-btn>
              <v-list-item-title v-if="route.displays.params.displayifparent" v-text="route.displays.params.displayifparent"></v-list-item-title>
                <v-list-item-title v-else>{{route.displays.displayname}}</v-list-item-title>

              </template>

                  <v-list-item
                    :to="route.url"
                    sub-group
                    link
                    class="blue lighten-5"
                  >
                  
                    <v-list-item-icon >
                      <v-icon size=12>mdi-arrow-right-bottom</v-icon>
                      <v-icon v-if="route.displays.params.icon">{{route.displays.params.icon}}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title  v-text="route.displays.displayname"></v-list-item-title>
                  </v-list-item>

        <draggable class="submenu" :list="route.children" group="c" @change="changeSub(route.children)" handle=".subhandle">
          <div v-for="subpages in route.children" :key="subpages.url">
                  <v-list-item
                    :to="subpages.url"
                    sub-group
                    link
                    class="blue lighten-5"
                  >

                    <v-btn icon x-small class="subhandle" v-if="$store.getters.currentUser.type==3"><v-icon >mdi-arrow-up-down</v-icon></v-btn>
                    <v-list-item-icon >
                      <v-icon size=12>mdi-arrow-right-bottom</v-icon>
                      <v-icon v-if="subpages.displays.params.icon" v-text="subpages.displays.params.icon"></v-icon>
                      
                    </v-list-item-icon>
                    <v-list-item-title v-text="subpages.displays.displayname"></v-list-item-title>



                  </v-list-item>
          </div>
          </draggable>
          </v-list-group>
          </div>

         </draggable>
        </v-list>

      </v-navigation-drawer>



      <v-navigation-drawer
      v-model="drawerRight"
      right      
      app>

    <v-row>
      <v-col class="pa-3 text-center">
<v-btn class="primary"
@click="createPage"
>
  <v-icon>mdi-plus</v-icon>
  nouvelle page
</v-btn>

      </v-col>
    </v-row>
    <main-component :page="idPageRightSide" hook="rightside" v-if="idPageRightSide"></main-component>

    </v-navigation-drawer>
<!--
    <v-navigation-drawer
      v-model="drawerLeft"
      left      
      app>
      <main-component :page="idPageLeftSide" type="page" hook="leftside" v-if="idPageLeftSide"></main-component>
      </v-navigation-drawer> -->
    <v-app-bar 
    color="blue accent-4"
      dense
      outlined
      dark
    app>
      <v-app-bar-nav-icon @click="drawer = !drawer">

      <v-progress-circular
      :indeterminate="$store.getters.axiosLoading"
      :color="$store.getters.currentUser.preferences.color"
      value="100"
      :size="40"
      >
          <v-list-item-avatar size="30" class="ml-4" v-if="!$store.getters.currentUser.picture">
            {{ $store.getters.currentUser.name.charAt(0) }} {{ $store.getters.currentUser.nickname.charAt(0) }}
          </v-list-item-avatar>
          <v-list-item-avatar size="30" class="ml-4" v-if="$store.getters.currentUser.picture">
            <v-img :src="$store.getters.currentUser.picture"></v-img>
          </v-list-item-avatar>
      </v-progress-circular>

      </v-app-bar-nav-icon>
      <v-toolbar-title> 
        <v-icon @click="putFavorite('topfavoris')" v-if="!isFav" color="green">mdi-star-outline</v-icon>
        <v-icon @click="putFavorite('topfavoris')" v-if="isFav" color="green accent-4">mdi-star</v-icon>
      
        <span v-if="$store.getters.currentPage">{{replaceBy({text:$store.getters.specificPage($store.getters.currentPage).displayname,page:$store.getters.currentPage}) }} </span>- {{$store.getters.currentUser.companie}}
        </v-toolbar-title>


      <v-spacer></v-spacer>


      <div>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <router-link to="/">
              <v-btn icon v-bind="attrs" v-on="on" @click="goToAccueil">

                <v-icon  >mdi-home</v-icon>
              </v-btn>
            </router-link>
          </template>
          <span>Page d'accueil</span>
        </v-tooltip>        
      </div>
      <div v-for="(fav,i) in listFavories.topfavoris" :key="i" >

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        
          <v-btn icon v-bind="attrs" v-on="on" @click="goToFav(fav)">

            <v-icon  v-if="fav.icon">{{fav.icon}}</v-icon>
            <span v-else>{{fav.displayname.charAt(0)}}</span>
          </v-btn>

      </template>
      <span>raccourcie vers {{fav.displayname}}</span>
    </v-tooltip>
        
      </div>

      <v-spacer></v-spacer>
      <div class="text-center ma-4" v-if="idPageSearch && !$vuetify.breakpoint.xs">
                <v-text-field
                style="margin-bottom:-20px;"
                class="pa-0"
                v-model="resultSearch"
                @keyup="$store.dispatch('changeStringSearch',resultSearch)"
                @keydown.enter.prevent="keyDownSearch"
                label="rechercher"
                filled
                prepend-inner-icon="mdi-magnify"
                ></v-text-field>


            <v-menu 
            v-model="menusearch"
            :close-on-content-click="false"
            min-width="100%"
            transition="fade-transition"
            offset-y
            right
            max-width="100%"
            :nudge-left="menusearch ? -10 : 0"
            
            >
							<template v-slot:activator="{on}">
                <div v-on="on"></div>
							</template>
							<v-card class="blue-grey lighten-4">
                
								<v-card-text class="pa-0 ma-0">
                  
                  <v-row>
                    <v-col class="text-overline">Recherche pour : {{ resultSearch }}</v-col>
                    <v-col class="text-right">
                      <v-btn small icon @click="menusearch=false"><v-icon primary>mdi-close-box-outline</v-icon></v-btn>
                    </v-col>
                  </v-row>
                  <main-component :page="idPageSearch" type="page" hook="search" :key="reloadSearch" v-if="menusearch && reloadSearch"></main-component>
								</v-card-text>
							</v-card>
						</v-menu>

    </div>


    <v-btn icon @click="putFavorite('onopen')" v-if="!$vuetify.breakpoint.xs">
      <v-icon v-if="!isDemmarage" color="white">mdi-heart-outline</v-icon>
    <v-icon v-if="isDemmarage" color="white">mdi-heart</v-icon>
      </v-btn>         

      <v-btn icon @click="logout" v-if="!$vuetify.breakpoint.xs">
          <v-icon>mdi-logout</v-icon>
      </v-btn>

      <v-menu
        left
        bottom
        offset-y
        v-if="$vuetify.breakpoint.xs || PagesTop.length || getProfils.length"
        :close-on-content-click="false"
      >

        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

          <v-list
            nav
            dense
          >
          <!-- Liste d'option quand on est sur la version mobile -->
           <v-list-item>
            <Select 
              valField="" 
              v-if="getProfils.length"
              :change="changeProfil"
              :item="{name:'Profils',value:'typeImport',liste:getProfils
              ,defaultprops:{show:true}}" />
           </v-list-item>
          <v-list-item v-if="$vuetify.breakpoint.xs">
            <div class="text-center ma-4" v-if="idPageSearch">
                <v-text-field
                style="margin-bottom:-20px;"
                class="pa-0"
                v-model="resultSearch"
                @keyup="$store.dispatch('changeStringSearch',resultSearch)"
                @keydown.enter.prevent="keyDownSearch"
                label="rechercher"
                filled
                prepend-inner-icon="mdi-magnify"
                ></v-text-field>


            <v-menu min-width="100%" v-model="menusearch" :close-on-content-click="false" style="position: absolute; top: 0; right: 0; width: 100%;">
							<template v-slot:activator="{on}">
                <div v-on="on"></div>
							</template>
							<v-card>
                
								<v-card-text class="pa-0 ma-0">
                  
                  <v-row>
                    <v-col class="text-overline">Recherche pour : {{ resultSearch }}</v-col>
                    <v-col class="text-right">
                      <v-btn small color="secondary" @click="menusearch=false"><v-icon dark>mdi-close-box-outline</v-icon></v-btn>
                    </v-col>
                  </v-row>
                  <main-component :page="idPageSearch" hook="search" :key="reloadSearch" v-if="menusearch && reloadSearch"></main-component>
								</v-card-text>
							</v-card>
						</v-menu>

    </div>
          </v-list-item>
          <v-list-item v-if="$vuetify.breakpoint.xs">
              <v-list-item-content @click="logout">
                 <v-icon>mdi-logout</v-icon>
              </v-list-item-content>         
            
            <v-list-item-content @click="putFavorite('onopen')">
              <v-icon v-if="!isDemmarage" color="blue">mdi-heart-outline</v-icon>
              <v-icon v-if="isDemmarage" color="blue">mdi-heart</v-icon>
              </v-list-item-content> 

              <v-list-item-content v-if="$store.getters.currentUser.type==3" @click="drawerRight = !drawerRight">
              <v-icon>mdi-cog-outline</v-icon>
              </v-list-item-content>
            </v-list-item>
          <!-- Fin de liste d'option quand on est sur la version mobile -->

          <!-- Debut de liste automatique de page -->
            <v-list-item v-for="route in PagesTop" :key="route.url" :to="route.url">
              <v-list-item-icon>
                <v-icon v-if="route.displays.params.icon">{{route.displays.params.icon}}</v-icon>
              </v-list-item-icon> 
              <v-list-item-title>
              {{route.displays.displayname}}</v-list-item-title>

              
            </v-list-item>
          
          </v-list>



      </v-menu>
      <v-btn
      v-if="($store.getters.currentUser.type==3) && !$vuetify.breakpoint.xs"
          icon
          @click="drawerRight = !drawerRight"
          >
          <v-icon>mdi-cog-outline</v-icon>
      </v-btn>
    </v-app-bar>

</div>
</template>



<script>
import draggable from 'vuedraggable'
import Select from '../fields/select'
  export default {
    name : 'NavBar',

        components : {
        draggable,
        Select
        },
        data: function() {
            return {

                drawer: false,
                drawerRight : false,
                drawerLeft : false,
                bottom : false,
                tab : [],
                dialogSearch : false,
                menusearch : false,
                resultSearch : '',
                idPageSearch : false,
                idPageRightSide : false,
                idPageLeftSide : false,
                idPageMenuHead : false,
                reloadSearch : 0,
                PagesLeft : [],
                PagesTop : [],
                listFavories : [],
                PageAll : [],
                PagesAdmin : [],
                isFav : false,
                isDemmarage : false,
                getProfils : [],

               
            }
            
        },

computed : {


  changePage() {
    return this.$store.getters.currentPage
  }
},
  watch : {


    '$route' (to) {
      let find = false
      var Toparams = (to.params.length==0) ? {} : to.params;
      var Toquerys = (to.query.length==0) ? {} : to.query;
      // pour la liste de favories
          this.listFavories.topfavoris.forEach((fav)=> {
                var Favparams = (fav.params.length==0) ? {} : fav.params;
                var Favquerys = (fav.querys.length==0) ? {} : fav.querys;
                  
                if(fav.name==to.name
                && JSON.stringify(Toparams)==JSON.stringify(Favparams)
                && JSON.stringify(Toquerys)==JSON.stringify(Favquerys)        
                ) {
                  find = true            
                }
          })
      this.isFav = find
      //pour la page de démarrage :
      let find2 = false
      let onopen = this.listFavories.onopen
      if(onopen.length==0) { this.isDemmarage = false; return false }
      var Favparams = (onopen.params.length==0) ? {} : onopen.params;
      var Favquerys = (onopen.querys.length==0) ? {} : onopen.querys;
                          
                        if(onopen.name==to.name
                        && JSON.stringify(Toparams)==JSON.stringify(Favparams)
                        && JSON.stringify(Toquerys)==JSON.stringify(Favquerys)        
                        ) {
                          find2 = true            
                        }

              this.isDemmarage = find2
    }, 

    'listFavories.topfavoris' : {
            handler(val) { 

              let find = false
              var Toparams = (this.$router.currentRoute.params.length==0) ? {} : this.$router.currentRoute.params;
              var Toquerys = (this.$router.currentRoute.query.length==0) ? {} : this.$router.currentRoute.query;
                val.forEach((fav)=> {
                        var Favparams = (fav.params.length==0) ? {} : fav.params;
                        var Favquerys = (fav.querys.length==0) ? {} : fav.querys;
                          
                        if(fav.name==this.$router.currentRoute.name
                        && JSON.stringify(Toparams)==JSON.stringify(Favparams)
                        && JSON.stringify(Toquerys)==JSON.stringify(Favquerys)        
                        ) {
                          find = true            
                        }
                  })
              this.isFav = find


            }, deep:true           
        }, 

        'listFavories.onopen' : {
            handler(val) { 
              if(val.length==0) { this.isDemmarage = false; return false }
              let find = false
              var Toparams = (this.$router.currentRoute.params.length==0) ? {} : this.$router.currentRoute.params;
              var Toquerys = (this.$router.currentRoute.query.length==0) ? {} : this.$router.currentRoute.query;

                        var Favparams = (val.params.length==0) ? {} : val.params;
                        var Favquerys = (val.querys.length==0) ? {} : val.querys;
                          
                        if(val.name==this.$router.currentRoute.name
                        && JSON.stringify(Toparams)==JSON.stringify(Favparams)
                        && JSON.stringify(Toquerys)==JSON.stringify(Favquerys)        
                        ) {
                          find = true            
                        }

              this.isDemmarage = find


            }, deep:true           
        }, 

  },

        methods : {

          goToPage(route) {
            this.$router.push({ path: route.url, query : route.displays.parameters.query, params: route.displays.parameters.params }).catch(()=> {})
            //permet de rafraichir le router-view.

          },

          goToAccueil() {
            this.$router.push("/").catch(()=> {})

          },

          keyDownSearch() {

              this.reloadSearch++;
              //ouverture du menu
              this.menusearch= (this.resultSearch!='') ?true : false
          },
          logout() {
            this.$store.dispatch('logOut')
          },

            changeElem() {
              
              var orderPages = []
              this.PagesLeft.forEach(function(v,i) {
                orderPages[i] = v.id;
              })
              this.API({type:'post',url:'pageorder',params : orderPages})
             
            
            },

            changeSub(childs) {

              var orderPages = []
              childs.forEach(function(v,i) {
                orderPages[i] = v.id;
              })

              this.API({type:'post',url:'pageorder',params : orderPages})
  
            },

            putFavorite(type) {
              var thisPageName = this.$router.currentRoute.name
              var thisPageParams = this.$router.currentRoute.params
              var thisPageQuerys = this.$router.currentRoute.query
              var thisPageId = this.$router.currentRoute.matched[0].props.default.page
              // on ne stocke pas la page d'acceuil, elle y est d'office
              if(this.PageAll.find(el => el.name === thisPageName).url=='/' && Object.values(thisPageQuerys).length==0 && Object.values(thisPageParams).length) return false;

              this.API({type:'post',url:'favorite',params : {type:type,id:thisPageId,name:thisPageName,params:thisPageParams,querys:thisPageQuerys}}).then((resp)=>{
                this.listFavories = resp.data
              })

            },


            createPage() {
              this.createNewPage({callback:(data)=> {
              this.$router.push({ name: data[0].name })
                //ferme le volet de droite :
                this.drawerRight = false
                // ouvre l'edition automatiquement
                setTimeout(()=>{ 
                  this.ModifTemplate = true; 
                  this.$store.dispatch("setActivTab",{hook:'listElemTab',tab:'parampage'})
                },500);
              }})
            },

            goToFav(fav) {
              // il faut changer le type d'arrayn car le back met [] si c'est vide, et les params (ou query) sont en {}
              var params = (fav.params.length==0) ? {} : fav.params
              var querys = (fav.querys.length==0) ? {} : fav.querys
              
              this.$router.replace({
                name:fav.name,
                params:params,
                query:querys
              })

              
            },


            changeProfil(item,model) {
              this.API({type:'post',url:'changeProfil',params : model}).then((resp)=> {
                if(!resp.data.success) {
                  this.$toast.warning(resp.data.message)
                  return false;
                }
                window.location.href = '/';
              })
            }

        },
        mounted() {

// on récupère la liste des profils si ce personnel en a plusieurs

this.getProfils = JSON.parse(JSON.stringify(this.$store.getters.listProfils));

        },

        created() {
          this.API({ type: 'get', url: 'allpage' }).then((resp) => {

              resp.data.forEach((page) => {

                    switch(page.displays.params.position) {

                      case 'left' :
                        this.PagesLeft.push(page);
                        
                        break;

                      case 'top' :
                      this.PagesTop.push(page);
                        break;

                      case 'search' :
                      this.idPageSearch = page.id;
                      break;
                      case 'menuhead' :
                      this.idPageMenuHead = page.name;
                      break;
                      case 'rightside' :
                      this.idPageRightSide = page.id;
                      break;
                      case 'leftside' :
                      this.idPageLeftSide = page.id;
                      break;
                    }

                      this.PageAll.push(page);


              })

          })

          this.API({ type: 'get', url: 'favorite' }).then((resp) => {
            this.listFavories = resp.data
          });

        }


  }
</script>