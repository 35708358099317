
<template>
<v-form 
    ref="form"
    v-model="valid"
    lazy-validation
>

  <div v-if="lodaded">
      <v-row>
        <v-col class="col-md-4 col-12">
          <TextField :item="{ value: 'name', name: 'nom de la tabulation',defaultprops:{ show: true }}"
            :keyup="(item,value) => { thisTab.name = value }"
            :valField=" thisTab.name " />

            <TextField :item="{ value: 'class', name: 'class',defaultprops:{ show: true }}"
            :keyup="(item,value) => { thisTab.params.class = value }"
            :valField="thisTab.params.class" />

            <TextField :item="{ value: 'icon', name: 'Icône',defaultprops:{ show: true }}"
            :keyup="(item,value) => { thisTab.params.icon = value }"
            :valField="thisTab.params.icon" />

            <SwitchC
            :item="{ name: 'dark mode', value: 'dark',defaultprops:{ show: true }}"
            :valField="thisTab.params.dark"
            :change="(item,value) => { thisTab.params.dark = value }"
            />

            (permet de créer des onglets dynamique en fonction d'un résultat de requete)
            <SwitchC
            :item="{ name: 'Tabultation customisé ', value: 'setcustom',defaultprops:{ show: true }}"
            :valField="thisTab.params.setcustom"
            :change="(item,value) => { thisTab.params.setcustom = value }"
            />
            

            <Select :item="{
            name: 'table ciblé pour la requete', value: 'table', liste: listTables,
            defaultprops: { show: true, column: false }}" 
            :change="(item,value) => { thisTab.params.custom.table = value['value'] } "
            :valField="thisTab.params.custom.table" 
            v-if="thisTab.params.setcustom"/>
        </v-col>


        <v-col class="col-md-8 col-12" v-if="thisTab.params.setcustom && thisTab.params.custom.table">

            <Autocomplete :item="{
                name: 'Affiche une page complète ?',
                value: 'idpage',
                defaultprops: { show: true, clearable:true},
                liste: routes,
              }" :valField="thisTab.params.idpage" 
              :change="(item,value) => { thisTab.params.idpage = (value) ? value['value'] : 0; } " />
            <p>Si vous choisissez une page complète, celle-ci supplentera le contenu manuel de l'onglet</p>
            
        <v-tabs align="start" v-model="tab">
          <v-tab> Filtre </v-tab>
          <v-tab> champs </v-tab>
          <v-tabs-items v-model="tab" class="pa-1">

            <v-tab-item>
              <Filtre :filtre="thisTab.params.custom.filter" :table="thisTab.params.custom.table"
              @recepVal="(array) => { thisTab.params.custom.filter = array }" />
            </v-tab-item>

            <v-tab-item>
              <p>Pour chaque onglet, il faut un nom et des paramètres à intégrer.</p>
              <ul>
                <li>AS pour le nom de l'onglet : ongletname</li>
                <li>AS pour les paramètres params : params-[intitulé du param]</li>
                <li>AS pour les paramètres querys : querys-[intitulé de la query]</li>
              </ul>
              <Fields :table="thisTab.params.custom.table" :champs="thisTab.params.custom.fields" :filter="thisTab.params.custom.filter" :page="page"
              @recepVal="(array) => { thisTab.params.custom.fields = array }" /> 
            </v-tab-item>

          </v-tabs-items>
        </v-tabs>
        </v-col>
      </v-row>


  </div>
  <div class="text-center">
    <v-btn class="primary" @click="validate" :disabled="!valid">Enregistrer</v-btn>
  </div>
  </v-form>
</template>



<script>

export default {

    props : ['page','modal'],

    components: {
        TextField: () => import('../../../fields/textfield.vue'),
        SwitchC: () => import('../../../fields/switch.vue'),
        Filtre: () => import("../../outils/constructFiltre/Filtre.vue"),
        Fields: () => import("../../outils/constructField/Field.vue"),
        Select: () => import("../../../fields/select.vue"),
        Autocomplete: () => import("../../../fields/autocomplete.vue"),
    },

    data: function () {
        return {
          thisTab : {},
          valid : false,
          lodaded : false,
          params : this.$store.getters.modalShowParams({type:'to',val:this.page}).params,
          tab : '',
          listTables : [],
          routes: [], // pour stocker la liste des routes
        }
    },


  methods : {


        validate() {
            if(this.$refs.form.validate()) {

                this.$store.dispatch('getoverlay',true)
                this.API({type:'put', url:'/engine/module/'+this.params.idmodule+'/tabulation/'+this.params.id+'/edit', params:this.thisTab}).then((resp) => {

                    this.$store.dispatch('getoverlay',false)
                    if(resp.data.success) {
                        this.$store.dispatch('closeModal',{page:this.$store.getters.modalShowParams({type:'to',val:this.page}).from})
                    }

                    }) 
            }

        },

        getTabFromAPI() {
          this.API({type:'get', url:'/engine/module/'+this.params.idmodule+'/tabulation/'+this.params.id+'/edit'}).then((resp) => {  
                
                this.thisTab = resp.data
                this.lodaded = true
                }
          
            )

        },
  },


  mounted() {

    this.urlSend = (!this.modal) ? this.$route.path : this.$store.getters.modalShowParams({type:'to',val:this.page}).url
    this.getTabFromAPI()

    this.API({type:'get', url:'engine/getlisttable'}).then((resp) => {
                    resp.data.forEach((table) => {
                        this.listTables.push({text:table,value:table})
                    })
                    
                })
        
                this.routes =                 this.$store.getters['listAll'].map(page => ({
                    text:page.displayname+" ("+page.path+") ",
                    value:page.props.page,
                }))

  },


}




</script>

