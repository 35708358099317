<template>
    <div>


        <v-row>
            <v-col class="text-center col-md-2 mx-auto">
                {{currentPassword}}
                    <TextField 
                    :item="{name:'Mot de passe actuel',value:'currentPassword',
                    defaultprops:{show:true,modelRules:[v => !!v || 'Ce champ ne doit pas être vide'],outlined:true}
                    }" 
                    :keyup="changeCurrentPassword"
                    :valField="currentPassword" />
            </v-col>
        </v-row>
        <v-row>
            <v-col class="text-center col-md-2 mx-auto">
                    <TextField 
                    :item="{name:'Nouveau mot de passe',value:'newPassword',defaultprops:{show:true,modelRules:[v => !!v || 'Ce champ ne doit pas être vide'],outlined:true}}" 
                    :keyup="changeNewPassword"
                    :valField="newPassword" />                
            </v-col>
        </v-row>
    </div>
</template>


<script>

    export default {
        name : 'changePassowrd',
        props : ['page','modal'],

        components : {
            TextField   : () => import('../../fields/textfield.vue'),

        },

        data: function() {
            return { 
                currentPassword : '',
                newPassword : '',

            }
        },


        methods : {

            changeNewPassword() {

            },

            changeCurrentPassword() {

            }

        }
    }

</script>