<template>
<div>
    
    <v-dialog
      v-model="cropDialog"
      :fullscreen="($vuetify.breakpoint.xs) ? true : false"
      width="50%"
      :retain-focus="false"
      @click:outside="closeCrop"
    >
    <v-card>
        <OverLay/>
    <cropper
    class="cropper"
    :src="imgCrop.image_url"
    :canvas="false"
    @change="changeCrop"
  ></cropper>
  <v-btn class="primary ma-1" @click="cropIMG">Enregistrer</v-btn>
</v-card>
</v-dialog>

<v-dialog
      v-model="replaceDialog"
      :fullscreen="($vuetify.breakpoint.xs) ? true : false"
      width="50%"
      :retain-focus="false"
      @click:outside="closeReplace"
    >
    <v-card>
        <OverLay/>
        <v-row no-gutter align="center" justify="center">
            <v-col align="center" justify="center" class="pa-4"><img :src="replaceImage.image_url" width="100" weight="200"></v-col>
            <v-col align="center" justify="center" class="pa-4"><v-icon x-large>mdi-swap-horizontal</v-icon></v-col>
            <v-col align="center" justify="center" class="pa-4">
                <v-file-input
                label="Importer le fichier"
                v-model="fileReplace"
                required
                accept=".jpg,.png"
                outlined
                filled
                clearable
                class="ma-0 pa-0"
                ></v-file-input> 
            </v-col>
        </v-row>
        <v-row no-gutter align="center" justify="center">
            <v-col class="text-center">
                <v-btn class="primary ma-1" @click="replaceIMG">Enregistrer</v-btn>
            </v-col>
        </v-row>
  
</v-card>
</v-dialog>

    <v-row>
        <v-col>
            <v-text-field
            label="Rechercher dans la bibliothèque"
            v-model="searchImage"
            @keyup.enter="getSearch"
            @click:clear="()=> {searchImage=''; getSearch() }"
            clearable
        
          ></v-text-field>       
        </v-col>
        <v-col>

            <v-file-input
            label="Importer un fichier"
            v-model="chosenFile"
            required
            accept=".jpg,.png"
            outlined
            filled
            clearable
            class="ma-0 pa-0"
            ></v-file-input>     
            <v-btn @click="uploadFile" small>Envoyer les images</v-btn>            
        </v-col>
    </v-row>
    <v-row no-gutters>

        <v-col v-for="(pic,d) in datas.display" :key="d" class="col-md-2 col-12 rounded">
            <v-hover v-slot="{ hover }">
                <template>
                    <v-card width="100" weight="100" class="rounded">

                                <v-overlay
                                absolute
                                :value="hover"
                                :opacity="0.2"
                                >
                                <v-btn v-for="(list, i)  in pic.actions" :key="i" small icon class="primary ma-1" @click="getAction(pic,list)"><v-icon small>{{list.icon}}</v-icon></v-btn>
                                <v-btn small icon class="primary ma-1" @click="() => {imgCrop=pic; cropDialog=true}"><v-icon small>mdi-crop</v-icon></v-btn>
                                <v-btn small icon class="primary ma-1" @click="()=> {replaceDialog=true; replaceImage=pic}"><v-icon small>mdi-sync</v-icon></v-btn>
                                <v-btn x-small class="primary ma-1" @click="selection(pic)">Selectionner</v-btn>
                                </v-overlay>

                            <img :src="(!pic.image_url) ? no_image : pic.image_url" width="100" weight="200">
                            {{pic.name}}
                    </v-card>
                    
                </template>
            </v-hover>

        </v-col>
    </v-row>
</div>
    </template>
    
    
    
    <script>
import { Cropper } from 'vue-advanced-cropper'

import 'vue-advanced-cropper/dist/style.css';
        export default {
        props: ['idmodule','page','modal','parent','hook'],

            components: {
                Cropper,
                OverLay: () => import('../outils/overlay.vue')
            },

            data: function() {
    
                return {
                    AllowedDisplay : true,
                    show : false,
                    listButtons : [],
                    datas : {all:[],display:[]},
                    no_image : '',
                    chosenFile : null,
                    searchImage:'',
                    cropDialog : false,
                    imgCrop : {},
                    paramsCrop:{},
                    replaceDialog : false,
                    replaceImage : {},
                    fileReplace : null,
                    params:{},
                    querys:{}
                }
            },

        watch : {
            showMod: {
            // permet de rafraichir quand la modal de la page se ferme
            handler(val) {
                if (val.show === false) {
                    this.callAPI()
                }
            },
            deep: true,
            }
        },

            methods :  {    
    
                callAPI : function() {
                    this.API({type:'post', url:'displaymodule', params:{id:this.idmodule,params:this.params,querys:this.querys}}).then((resp) => {

                        this.manageDatas(resp.data)
                            
                    })
    
                },   
                
                manageDatas(data) {
                    if(!data.success) {
                            this.AllowedDisplay = false; 
                            return false;
                            }
                            this.datas.all = data.pictures;
                            this.datas.display = data.pictures;
                            this.no_image = data.no_image
                            this.show = true 
                },

                uploadFile : function() {
                    if(!this.chosenFile) {
                        this.$toast.warning('Veuillez sélectionner un fichier avant d\'importer')
                        return false;
                    }
                    this.$store.dispatch('textOverlay','Importation en cours');
                    this.$store.dispatch('getoverlay', true)
                    const formData = new FormData();
                    formData.append('importFile', this.chosenFile);
                    formData.append('id', this.idmodule);

                    this.API({type:'post', url:'displaymodule', params:formData, apidefault:{headers:{'Content-Type':'multipart/form-data'}}}).then((resp) => {
                        this.$store.dispatch('getoverlay', false)
                            if(!resp.data.success) {
                            this.$toast.warning(resp.data.message) 
                            return false;
                            }
                            this.$toast.success('importation réussie');
                            this.chosenFile = null
                            this.datas.all = resp.data.pictures;
                            this.datas.display = resp.data.pictures;

                            
                    })
    
                },   

                getSearch() {
                    let result = JSON.parse(JSON.stringify(this.datas.all));
                    if (!this.searchImage) {
                        this.datas.display = this.datas.all;
                        return 
                    }

                    const filterValue = this.searchImage.toLowerCase()

                    const filter = event => event.name.toLowerCase().includes(filterValue)

                    this.datas.display = result.filter(filter)

                },

                getAction(pic,action) {
                    var getReplace = {}
                if (action.typelink == 'link') {

                    action.get.forEach((v) => {
                    if (v.type == 'table' || v.type == '') {
                        getReplace[v.key] = pic[v.value]
                    } else {
                        getReplace[v.key] = this.replaceBy({ text: v.value, page: this.page });
                    }

                    })

                    this.goTo({ rootPage: this.page, name: action.page_name, type: action.target, replace: getReplace, paramDialog: action.paramdialog})
                }



                if (action.typelink == 'api') {
                    if(action.params.ifconfirm && action.params.ifconfirm!='') {
                        if(confirm(action.params.ifconfirm)) {
                            this.API({ type: action.params.apilink.type, url: this.replaceBy({ text: action.params.apilink.url, page: this.page, line:pic }), params: pic }).then((resp) => {
                            if(resp.data.success) {
                                if(resp.data.message) this.$toast.success(resp.data.message);
                                this.callAPI()
                            } else {
                                this.$toast.error(resp.data.message);
                            }
                            });
                        }
                    } else {
                    this.API({ type: action.params.apilink.type, url: this.replaceBy({ text: action.params.apilink.url, page: this.page, line:pic }), params: pic }).then((resp) => {
                        if(resp.data.success) {
                        if(resp.data.message) this.$toast.success(resp.data.message);
                            this.callAPI()
                        } else {
                        this.$toast.error(resp.data.message);
                        }        
                    });          
                    }
                }
                },

                changeCrop({coordinates}) {
                    this.paramsCrop = coordinates
                },

                cropIMG() {
                    this.$store.dispatch('textOverlay','Redimensionnement en cours');
                    this.$store.dispatch('getoverlay', true)
                    this.API({type:'post', url:'displaymodule', params:{id:this.idmodule,crop:this.paramsCrop,image:this.imgCrop.image_id}}).then((resp) => {

                    this.$store.dispatch('getoverlay', false)

                    if(!resp.data.success) {
                    this.AllowedDisplay = false; 
                    return false;
                    }
                    this.datas.all = resp.data.pictures;
                    this.datas.display = resp.data.pictures;
                    this.closeCrop()
                    })
                },

                closeCrop() {
                    this.cropDialog=false; 
                    this.imgCrop={}
                },

                replaceIMG() {
                    this.$store.dispatch('textOverlay','Remplacement en cours');
                    this.$store.dispatch('getoverlay', true)
                    const formData = new FormData();
                    formData.append('fileReplace', this.fileReplace);
                    formData.append('id', this.idmodule);
                    formData.append('origin', this.replaceImage.image_id);
                    this.API({type:'post', url:'displaymodule', params:formData, apidefault:{headers:{'Content-Type':'multipart/form-data'}}}).then((resp) => {
                        this.$store.dispatch('getoverlay', false)
                            if(!resp.data.success) {
                            this.$toast.warning(resp.data.message) 
                            return false;
                            }
                            this.$toast.success('importation réussie');
                            this.closeReplace()
                            this.datas.all = resp.data.pictures;
                            this.datas.display = resp.data.pictures;

                            
                    })                   
                },
                closeReplace() {
                    this.replaceDialog=false; 
                    this.replaceImage={};
                    this.fileReplace = null
                },

                selection(pic) {

                    this.$store.dispatch('setPicture',pic);
                    if(this.$store.getters.modalShowParams({ type: 'to', val: this.page })) this.$store.dispatch('closeModal',{page:this.$store.getters.modalShowParams({type:'to',val:this.page}).from})

                }
                
            },
            mounted() {
                
                this.params = this.$store.getters.specificPage(this.page).params    
                this.querys = this.$store.getters.specificPage(this.page).querys
                //var mod = this.$store.getters.modalShowParams({ type: 'to', val: this.page })
                if(this.hook == 'search') this.querys.global = this.$store.getters.getStringSearch;

                let getdatas = this.$store.getters.getModuleDatas({page:this.page,id:this.idmodule});
                if(getdatas) {
                    this.manageDatas(getdatas.datas)
                    this.$store.dispatch('removeModuleDatas',{page:this.page,id:this.idmodule})
                } else {
                    this.callAPI() 
                } 

            },
    
            created() {  

            }
    
        }
    
    </script>


<style>
.cropper {
  height: 500px;
  background: #DDD;
}
</style>
    