<template>
    <div v-if="show">
    
        <not-allowed v-if="!AllowedDisplay" :page="page" :module="idmodule" :data="datas"></not-allowed>

        <v-btn @click="recharge">recharger</v-btn> 
        <v-btn @click="downloadFile">Telecharger</v-btn>
        <v-btn v-if="saveged" @click="saveGed">Sauvegarder dans la GED</v-btn>
        <br>
            <iframe ref="monIframe" width="100%" height="600" :src="pdfUrl" v-if="typePage=='edit' || customparams">
            </iframe>
            <div v-if="typePage=='new'">Veuillez enregistrer le formulaire avant de voir le PDF</div>
            
    </div>
    </template>
    
    
    
    <script>
const token = localStorage.getItem("tokenMinu");
import api from '../../services/api'   

        export default {
        props: ['idmodule','page','modal','parent','customparams','customquerys'],
    
            data: function() {
    
                return {
                    AllowedDisplay : true,
                    show : false,
                    pdfUrl : '',
                    datas : [],
                    displays : [],
                    saveged : false,
                    documentname : '',
                    params:{},
                    querys:{},
                          // permet de définir si ce module est intégré dans une page form (nouvelle:newform ou en mode édition:editform), ou une page classique : page
                    typePage : (!this.$store.getters['listForm'](this.page)) ? 'page' : this.$store.getters['listForm'](this.page).typeform ,
                }
            },

        computed : {
            modEditForm() {
                return this.$store.getters['listForm'](this.page);
            }
        },

        watch : {
            
            'modEditForm.typeform': {
                // permet de rafraichir quand la modal de modif du module de la page se ferme
                handler(newVal,oldVal) {
                    if(oldVal!='new') return;
                        this.typePage = newVal
                        this.initiateParams()
                        this.callAPI()
                },deep:true,
            },

        },

            methods :  {    
    
                callAPI : function() {
                    if(this.typePage=='new') return
                    this.API({type:'post', url:'displaymodule', params:{id:this.idmodule,params:this.params,querys:this.querys}}).then((resp) => {
                        this.manageDatas(resp.data)
                            
                    })
    
                },

                manageDatas(data) {
                    this.datas = data
                            if(!data.success) {
                            this.AllowedDisplay = false; 
                            return false;
                            }
                            this.saveged = data.saveged;
                            this.displays = data.displays
                            this.documentname = data.documentname;
                            this.pdfUrl = api.defaults.baseURL+'pdf?token='+token+'&id='+this.idmodule+'&params='+JSON.stringify(this.params);
                            this.show = true 
                },

                saveGed() {
                    this.$store.dispatch('textOverlay','Transfert du document en cours');
                    this.$store.dispatch('getoverlay', true)
                    this.API({type:'post', url:'displaymodule', params:{id:this.idmodule,saveged:true,params:this.params,querys:this.querys}}).then((resp) => {
                        this.$store.dispatch('getoverlay', false)
                        if(!resp.data.success) {
                            this.$toast.error(resp.data.message)
                            return false;
                            }       
                            this.$toast.success(resp.data.message)                                     
                    })
                },
                recharge() {
                // Changez l'URL de l'iframe pour provoquer son rechargement
                this.pdfUrl = ''; // Assurez-vous de vider d'abord l'URL
                    setTimeout(() => {
                        this.pdfUrl = api.defaults.baseURL+'pdf?token='+token+'&id='+this.idmodule+'&params='+JSON.stringify(this.params)+'&rebuild=ok'; // Remettez l'URL d'origine
                    },500);
                },
    

                downloadFile() {
                    
                    this.API({type:'get',url:'/pdf?token='+token+'&id='+this.idmodule+'&params='+JSON.stringify(this.params),apidefault:{responseType:'blob',headers:{'Accept':'application/pdf'}}}).then((resp) => {
                        const url = window.URL.createObjectURL(new Blob([resp.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        var name = (this.datas.displays.params.documentname) ? this.documentname : 'mon_pdf'
                        link.setAttribute('download', name+'.pdf');
                        document.body.appendChild(link);
                        link.click();
                    })
                    

                },      

                initiateParams() {
                this.params = this.$store.getters.specificPage(this.page).params
                this.querys = this.$store.getters.specificPage(this.page).querys
                }
                
            },
            mounted() {
                this.initiateParams()
                let getdatas = this.$store.getters.getModuleDatas({page:this.page,id:this.idmodule});
                if(getdatas) {
                    if(this.typePage=='new') return
                    this.reloadGed = true;
                    this.manageDatas(getdatas.datas)
                    this.$store.dispatch('removeModuleDatas',{page:this.page,id:this.idmodule})
                } else {
                    this.callAPI() 
                } 
                if(this.typePage == 'edit' && this.querys.download == this.idmodule) {
                    this.downloadFile()
                }
            },
    
            created() {  

            }
    
        }
    
    </script>
    