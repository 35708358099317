import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import search from './searchTable';

import modal from './modal';

import routingaxios from './routingAxios';

import displaymenu from './displayMenu';

import getuser from './getUser';

import getformvalues from './getFormValues';

import conditionform from './conditionForm';

//import constructTemplate from './constructTemplate-trash';

//import constructTabulation from './constructTabulation-trash';

import auth from "./auth"

import axiosQueue from "./axiosQueue"

import divers from "./diversFunctions";

export default new Vuex.Store({
    modules: {
        search: search,
        modal: modal,
        routaxios: routingaxios,
        displaymenu: displaymenu,
        getuser: getuser,
        getformvalues: getformvalues,
        conditionform: conditionform,
        //constructTemplate: constructTemplate,
        //constructTabulation: constructTabulation,
        auth: auth,
        axiosqueue: axiosQueue,
        diversFunc: divers


    }
});