const searchTable = {
    state: {
        // variable qui va s'implémenter en fonction des recherches. si le module (id=2) de recherche s'ouvre dans l'interface, ca va faire search[2]
        // si on met juste les "{}", qaund on va pusher dans insertform, ca ne va pas etre pris en compte dans le getter
        // par contre is je met "0"=>[] et que je met "state.search['0'] = pp.value" dans insertform, le getter marche
        // incompréhensible....
        search: { "0": {} },

        launchsearch: false,

    },

    getters: {
        //ca me permet de retourner mon state.search dans un autre composant afin que je l'envoi par axios
        getSearchValues: (state) => {
            return state.search
        },

        launchsearch: (state) => {
            return state.launchsearch
        }

    },

    mutations: {
        // permet d'insérer un array {"nomchamp":"valeurchamp"} dans mon state.search[2]
        insertform: function(state, pp) {

            if (pp.value == '') {
                delete state.search[pp.mod][pp.champ]
            } else {
                state.search[pp.mod][pp.champ] = pp.value
            }

            //permet de mettre a jour le state.search dans le getter : quand on modifie l'array, il ne s'affiche pas dinamiquement dans Searchcomponent....
            //incomprehensible... à déquoter pour le faire marche
            //state.search['0'] = {}
        },

        // permet de mettre une clé correspondant au module de recherche.
        // ca va devenir state.search[2] si le module de rechrche est 2
        init_search: function(state, form) {
            state.search[form] = {}
        },

        validateLaunch: function(state, form) {
            state.launchsearch = form;
        },
        removeLaunch: function(state) {
            state.launchsearch = false;
        }
    },

    actions: {

        insertform: function({ commit }, retour) {
            commit('insertform', retour);
        },

        init_search: function({ commit }, form) {
            commit('init_search', form);
        },

        validateLaunch: function({ commit }, form) {

            commit('validateLaunch', form);

        },
        removelaunch: function({ commit }) {

            //setTimeout pour laisser à tous les composants tableau de se rafraichir
            setTimeout(function() {
                commit('removeLaunch');
            }, 500);

        },

    }

}
export default searchTable;