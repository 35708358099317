<template>
  <div class="text-center">
    <v-icon class="text-h1">
    mdi-alert
    </v-icon>

          <div class="text-center">
            Affichage non disponible :
            {{data.message}}
          </div>
  </div>
</template>

<script>
export default {
    name : 'notAllowed',

    props:['data']

}
</script>
