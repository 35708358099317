<template>
    <div>
    
        <not-allowed v-if="!AllowedDisplay" :page="page" :module="idmodule" :data="datas"></not-allowed>
    <v-scale-transition>

    <div v-show="show">

        </div>
    
    </v-scale-transition>
    </div>
    </template>
    
    
    
    <script>

import { EventBus } from '../../services/EventBus';
        export default {
        props: ['idmodule','page','modal','parent'],
    
            data: function() {
    
                return {
                    AllowedDisplay : true,
                    show : false,
                    pdfUrl : '',
                    datas : [],
                    params:{},
                    querys: {},
                    searchmodule : [],
                }
            },

        computed : {
            isNewFilterOnModal() {
            return (this.$store.getters.modalShowParams({ type: 'to', val: this.page })) ? this.$store.getters.modalShowParams({ type: 'to', val: this.page }).query : this.$route.query;
            }
        },

        watch : {

            isNewFilterOnModal(val) {   
            var find = false;
                for(var key in val) {
                let findModSearch = key.split(/_(.*)/s);

                    this.searchmodule.forEach( (idsearch) => {
                        if (findModSearch[0] == idsearch) find = true
                    })
                }
                if(find) {
                    this.querys = val
                    this.callAPI();
                }

            },

        },

            methods :  {    
                callAPI : function() {
                    this.API({type:'post', url:'displaymodule', params:{id:this.idmodule,params:this.params,querys:this.querys}}).then((resp) => {

                        this.manageDatas(resp.data)
                            
                    })
    
                },  

                manageDatas(data) {
                    if(!data.success) {
                            this.AllowedDisplay = false; 
                            return false;
                            }
                            this.$store.dispatch('setFrontDatas',{datas:data.datas,module:this.idmodule});
                            let aa = {}
                            this.searchmodule = data.searchmodule;
                            aa[this.idmodule] = data.datas
                            EventBus.$emit('setFrontDatas');
                            this.show = true 
                }
                
            },
    
            created() {  
                let getdatas = this.$store.getters.getModuleDatas({page:this.page,id:this.idmodule});
                if(getdatas) {
                    this.manageDatas(getdatas.datas)
                    this.$store.dispatch('removeModuleDatas',{page:this.page,id:this.idmodule})
                } else {
                    this.callAPI() 
                } 
            },

            mounted() {
                this.params = this.$store.getters.specificPage(this.page).params
                this.querys = this.$store.getters.specificPage(this.page).querys
            }
    
        }
    
    </script>
    