
<template>

    <div>    

      <not-allowed v-if="allowed.success===false" :data="allowed"/>

    <!-- permet de modifier le nom et nom de clé du template actif-->
    <v-dialog v-model="dialogTemplate" width="800"
            @click:outside="closeDial">
            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                Modification du template 
                <v-spacer></v-spacer>
                <v-btn icon @click="closeDial"><v-icon>mdi-close-thick</v-icon></v-btn>      
                </v-card-title>

                <v-card-text v-if="indexTemplate !==false">

                    <TextField 
                    :keyup="(item,value) => { template[indexTemplate].name = value }"
                    :valField="template[indexTemplate].name"
                    :item="{name:'Nom du template',value:'name',defaultprops:{show:true}}"
                    /> 
                    <TextField 
                    v-if="indexTemplate"
                    :keyup="(item,value) => { template[indexTemplate].key = value }"
                    :valField="template[indexTemplate].key"
                    :item="{name:'Clé',value:'key',defaultprops:{show:true}}"
                    />  

                    l'élément sera affiché si toutes les conditions sont remplis)

                    <groupConditions v-if="indexTemplate" :conditions="template[indexTemplate].conditions" @recepVal="(array) => { template[indexTemplate].conditions = array }" />

                </v-card-text>

            </v-card>
            </v-dialog>


            <v-container 
            v-if="selectedTemplate"
            :class="paramsPage.class"
            :fluid="paramsPage.containerfluid"
            :id="paramsPage.containerid"
            >    
   

              <div v-if="$store.getters.currentUser.type==3" class="text-center grey lighten-3 text-caption ma-4">Entreprise {{variantPage}} avec template <b>{{verifyActiv()}}</b>

                  <v-switch
                      v-if="($store.getters.currentUser.type==3)"
                      absolute
                      v-model="ModifTemplate"
                      class="pa-0 ma-0 text-center float-left"
                      hide-details
                  ></v-switch> 
              </div>

         

                <!-- intégration des ventilation de cette page si il n'y a aucun crochet-->
                <ventilation-component :page="page" :modal="modal" :hook="hook" :form="$refs['form-'+page]"/>

                <v-row v-if="ModifTemplate">    
                    <v-col class="col-12">
                        <List v-if="ModifTemplate && (!hook || hook=='search')" :page="page" :modal="modal" :template="template" @manageTemplate="manageTemplate" :selected="selectedTemplate" :form="$refs['form-'+page]"/> 
                    </v-col>            
                    <v-col class="col-12 text-center">
                        <v-btn @click="savePage" class="blue" dark x-small><v-icon x-small>mdi-content-save</v-icon> {{selectedTemplate.name}}</v-btn>
                        <v-btn class="blue" dark x-small
                        @click="goTo({rootPage: page,page: 'editiondroit',type: 'modal',
                        // j'ai mis params:{id:item.module} juste pour permettre le rafraichissement du module... 
                        replace: { page:page, id: page, target:'page', root:'interface'},
                        })">                
                        <v-icon small>mdi-eye-outline</v-icon>
                        Droits
                        </v-btn> 
                    </v-col>
                </v-row>
            <v-row v-if="editTemplate!==false">
                <v-col class="text-center">
                    <v-alert outlined color="red">
                        Vous êtes en mode edition du template {{ selectedTemplate.name }}
                    <v-btn @click="savePage(); editTemplate = false" class="red" dark x-small><v-icon x-small>mdi-close-box</v-icon>Sauvegarder et sortir</v-btn>
                    </v-alert>
                </v-col>
            </v-row>
            
            <v-form
            :ref="'form-'+page"
            v-model="modelForm"
            lazy-validation
            v-if="typeform"
            >

            <v-row v-if="showFormCrud.visu" no-gutters>
                <v-col class="text-center">
                    <v-btn color="secondary" icon @click="expandFormLogs = !expandFormLogs">
                    <v-icon>mdi-eye-refresh</v-icon>
                    </v-btn>
                <formLogs :table="showFormCrud.table" :id="showFormCrud.id" :page="page" :modal="modal" :hook="hook" v-if="expandFormLogs"></formLogs>
                </v-col>
            </v-row>
              <CPage 
              :pageTemplate="selectedTemplate.template" 
              :page="page" 
              :modal="modal" 
              :form="$refs['form-'+page]" 
              :hook="hook"
              :customparams="customparams"
              :customquerys="customquerys"
              :mod="ModifTemplate"
              @recepVal="recupTemplate"
              />
              
            </v-form>

            <CPage 
            v-else
              :pageTemplate="selectedTemplate.template" 
              :page="page" 
              :modal="modal" 
              :hook="hook"
              :customparams="customparams"
              :customquerys="customquerys"
              :mod="ModifTemplate"
              @recepVal="recupTemplate"
              />

          </v-container>


            <v-container>
              <transition name="custom-fade">
                <v-overlay color="white" v-if="!selectedTemplate && !findError" :value="true" absolute opacity="1">
                    <v-row>
                        <v-col class="blue--text col-12">
                            Chargement de la page
                            <v-progress-linear
                            indeterminate
                            :width="5"
                            ></v-progress-linear>
                        </v-col>
                    </v-row>
                </v-overlay>
              </transition>
            </v-container>

  
        </div> 

</template>

<script>
// on va récupérer les informations de la page et les envoyer dans page ou form

import cond from "../../../mixins/visuelConditions";


  export default {
      name : 'MainComponent',
        // independant : provient de pages loadé à la volée. serv uniquement pour les formulaire.
        //si indépendant = true lors du l'enregistrement du form, ca va prendre l'url de la page en question
        props: ['independant','typeform','page','modal','hook','customparams','customquerys'],

        mixins : [cond],
        
        components : {

          List    : () => import('./page/listElem.vue'), 
          CPage    : () => import('../../engine/outils/constructPage/Page.vue'),
          TextField : () => import('../../fields/textfield.vue'),
          groupConditions : () => import('../../engine/outils/constructConditions/Conditions.vue'),
          formLogs : () => import('../../engine/Logs/crudLogs.vue'),

        },


        data: function() {
            return {
   
              params : {},
              querys : {},           
              //permet de voir si l'utilisateur a le droit de visu sur la page
              allowed : {message:'',success:''},
              // ouvre le dialog de condition du template
              dialogTemplate : false,
              //template en cours de visu
              selectedTemplate : false,
              // regarde si le retour API de la page n'a pas eu d'erreur php
              findError : false,
              //a voir :
              indexTemplate : false,
              //liste destemplate de page en attente de réception
              template : [],
              variantPage : 0,
              //int (index) : permet de selectionner le template à modifier via la liste des templates ds params page
              editTemplate : false,
              //bool : permet de lancer le mode de construction du template
              ModifTemplate : false,

              //modele de la balise pour le formulaire
              modelForm : true,
              //peremt de visualiser les logs pour les form
              expandFormLogs : false,
              //montre si l'utilisateur peut voir ou pas les logs du form en fonction de ses droits
              showFormCrud : {visu : false, id:0,table:''},

              paramsPage : {}

            }
        },

        titleBrowser () {
        if(!this.hook) return this.replaceBy({text:this.paramsPage.displayname,page:this.page})
        },


        computed : {
          isValidateForm() {
              return (this.$store.getters.listForm(this.page)) ? this.$store.getters.listForm(this.page)['validate'] : false;
          }
        },

        watch : {
            //permet de sortir automatiquement du mode construction du template lors de la désactiv de modif template
            ModifTemplate(val) {
                if(!val) this.editTemplate = false
            },

            modelForm(val) {
                this.$store.getters.listForm(this.page)['valid'] = val
            },

            isValidateForm(val) {

                if(val!=0) { 
                    this.validate(); 
                    }
            }

        },

        methods : {

          getFromAPI() {
          this.API({type:'post', url:'page', params:{id : this.page, params : this.params, querys : this.querys }}).then((resp) => {

                  if(!resp.data.success) {
                      this.allowed = resp.data
                      this.findError = true;
                      return;
                  }

                  this.paramsPage = resp.data.page.displays.params
                  if (this.typeform) {
                    this.initialiseForm().then(() => {
                      this.processPageData(resp.data);
                    });
                  } else {
                    this.processPageData(resp.data);
                  }
  
                  
              })

          },

          processPageData(data) {
            this.template = data.page.displays.template;
            this.$store.dispatch('insertModuleDatas', { page: this.page, modules: data.modules });
            // On prend directement celui par défaut
            this.selectedTemplate = this.template[0];
            // Vérifie si une condition n'est pas remplie sur le template
            this.verifyActiv();
            this.variantPage = data.page.displays.variant;
          },


          recupTemplate(arr) {
                this.template.find(el => el.key == this.selectedTemplate.key).template = arr;
            },

            //sauve la page en cours
            savePage() {
                this.API({type:'put', url:'savetemplate', params:{id:this.page, 
                                        template:this.template,
                                        variant:this.variantPage
                                        }})
            },

            //permet de gérer les actions du template, via listElem
            manageTemplate(elems) {
                if(elems.type=='delete' && confirm('Vous allez supprimer cette ce template, continuer ?')) {
                    //si on supprime le template qui est entrain d'etre modifié, on revient directement sur le template par défaut
                    if(elems.index === this.editTemplate) {
                       this.editTemplate = false
                       this.verifyActiv()
                    }
                    this.template = this.template.filter((el,i) => i != elems.index);
                }
                if(elems.type=='build') {
                    this.editTemplate = elems.index
                    this.verifyActiv()
                }
                if(elems.type=='insert') {
                    this.template.push({name:'nouveau template',key:'newtemp',template:[],'conditions':[]})
                }
                if(elems.type=='update') {
                    
                    this.indexTemplate = elems.index;
                    this.dialogTemplate = true
                }                
            },

            //ferme la modal de condition du template
            closeDial() {
                this.dialogTemplate = false
                this.indexTemplate = false
            },

            //permet de définir quel template est affiché en fonction des conditions sur la page
            verifyActiv() {
                var find = false;

                if(this.editTemplate!==false)  {

                    this.selectedTemplate = this.template[this.editTemplate];

                } else {
                    this.template.forEach((templ,i) => {
                        if(i!=0 && this.visuelConditions(templ.conditions).length && this.visuelConditions(templ.conditions).every(val => val === true)) {
                        find = templ;
                        }
                    })
                    if(find) {
                        this.selectedTemplate = find;
                    }  else {
                        this.selectedTemplate = this.template[0];
                    }
                }

                //j'ai fais un return pour afficher le nom dans le DOM, MAIS aussi, si je met la fonction dans le dom, ca va etre réactif à chaque 
                //changement de variables.... bizarre..
                return this.selectedTemplate.name
            },

            //function pour la validationdu formulaire. est envoyé lors du click d'un bouton form dans le template (voir le watch)

            validate : function() {
                this.$store.dispatch('textOverlay','enregistrement en cours')

                    this.validateForm({form:this.$refs['form-'+this.page],page:this.page,independant:this.independant,
                        ifNotValid:()=>{
                        this.$store.dispatch('changeParamForm',{page:this.page,type:'validate',value:0}) 
                        },
                        callback:()=> {  
                        this.$store.dispatch('changeParamForm',{page:this.page,type:'validate',value:0})                     
                        this.$store.dispatch('removeForm', { uid:this.page })
                     }}) 
               
            },

            //si le type de page est un form on va loader les valeurs des champs. se lance dans getFromAPI()
            initialiseForm() {

              return new Promise((resolve) => {

                this.$store.dispatch('initConditionForm',{page : this.page})
                

                  //on récupère les informations des champs en fonction de l'url :
                  //var urlSend = (!this.modal) ? this.$route.path : this.$store.getters.modalShowParams({type:'to',val:this.page}).url
                  let urlForm = this.newurl({page:this.page,replace:this.params,typeform:this.typeform})

                  this.API({type:'get',url:urlForm,params:this.params}).then((resp)=> {

                      this.$store.dispatch('initForm', { page:this.page,valid:this.modelForm,typeform: this.typeform,params:this.params,url:urlForm});
                      
                      for(const array in resp.data.valFields) {
                          
                          this.$store.dispatch('pushForm', { page:this.page, champ:array,value: resp.data.valFields[array]});
                      } 

                      if(resp.data.crud) {
                              this.showFormCrud.visu = true
                              this.showFormCrud.table = resp.data.crud;
                              this.showFormCrud.id = this.params.id;
                      }
                      resolve()
                  })
                })
            }


        },


        mounted() {

          //initiatlisation de la modal de la page
          if(!this.$store.getters.show.find(el => el.from == this.page)) {
          this.$store.dispatch('initModal',{page:this.page})
          }

          this.getFromAPI()
        },


        created() {

                if(!this.hook) this.$store.dispatch('current',this.page)
                //récupération des params et querys en fonction de l'environement de la page
                var modal = this.$store.getters.modalShowParams({ type: 'to', val: this.page })
                this.params = (this.modal && modal && modal.show) ? modal.params : this.$route.params   
                this.querys = (this.modal && modal && modal.show) ? modal.query : this.$route.query
                this.params = (this.customparams) ? this.customparams : this.params
                this.querys = (this.customquerys) ? this.customquerys : this.querys
                if(!this.querys) this.querys = {}
                //intégration des params de la page
                this.$store.dispatch('changeParamPage',{page:this.page,item:'params',value:this.params})
                this.$store.dispatch('changeParamPage',{page:this.page,item:'querys',value:this.querys})

        }


  }
</script>

<style>
/* Transition personnalisée */
.custom-fade-enter-active,
.custom-fade-leave-active {
  transition: opacity 0.5s ease-in-out; /* Durée de 500ms */
}

.custom-fade-enter,
.custom-fade-leave-to {
  opacity: 0;
}
</style>