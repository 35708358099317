<template>
    <div>
    <v-dialog v-model="dialog" persistent max-width="500">

    
  <v-card :loading="loading">

    <template slot="progress">
      <v-progress-linear
        color="deep-purple"
        height="10"
        indeterminate
      ></v-progress-linear>
    </template>

  <v-card-title v-if="loading">E-mail en cours de validation</v-card-title>

  <v-card-title v-if="isValidate===false">Votre activation à échouée, veuillez contacter le gérant</v-card-title>
  <v-card-title v-if="isValidate===true">Votre e-mail a bien été validé</v-card-title>
    <v-card-text>
        <div class="text-center h1">

            <v-icon x-large color="green" v-if="isValidate===true">mdi-check-circle</v-icon>
            <v-icon x-large color="orange" v-if="isValidate===false">mdi-alert</v-icon>
        </div>

      <div class="text-center" v-if="isValidate===true">Vous pouvez maintenant définir votre mot de passe en cliquant sur le lien ci-dessous</div>
    </v-card-text>

    <v-divider class="mx-4"></v-divider>

    <v-card-actions v-if="isValidate===true && tokenRecover">
      <v-btn
      class="mx-auto"
        color="blue lighten-2"
        :to="'/passrecover?token='+tokenRecover"
      >
        Définir mon mot de passe
      </v-btn>
    </v-card-actions>
  </v-card>
    </v-dialog>

</div>
  </template>
  
  <script>

  
  export default {
    name: "validateRegister",
  
    components: {
    
    },
  
    data: function () {
      return {
        dialog : true,
        loading : false,
        title : (this.loading) ? "E-mail en cours de validation" : "",
        isValidate : '',
        tokenRecover : '',
      };
    },
    computed: {

    },
    methods: {

    verifiedEmail() {
        this.loading = true
        this.API({
          type: "post",
          url: "auth/validationEmail",
          params: {
            token: this.$route.query.token,
            email: this.$route.query.email,
          },
        }).then((resp) => {
            this.loading = false
            
            this.isValidate = resp.data.success 
            this.tokenRecover = resp.data.token

        });

    }

    },
  
    mounted() {
        this.verifiedEmail()
    },
  };
  </script>
  