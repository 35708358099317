<template>


<div>

<v-row>     
    <v-col>
        <TextField
            :item="{ name: 'Classe', value: 'class', defaultprops:{ show: true }}"
            :valField="displays.params.class"
            :keyup="changeParams"
            />
    </v-col>
    <v-col>
        <colorPicker
            :item="{ name: 'Couleur de fond', value: 'bgcolor' , defaultprops:{ show: true }}"
            :valField="displays.params.bgcolor"
            :change="changeParams"
            />
    </v-col>
    <v-col>
            <SwitchC
            :item="{ name: 'dark mode', value: 'dark' , defaultprops:{ show: true }}"
            :valField="displays.params.dark"
            :change="changeParams"
            />
    </v-col>
    </v-row>
    <v-row>
    <v-col>
            <SwitchC
            :item="{ name: 'position center', value: 'centered', defaultprops:{ show: true }}"
            :valField="displays.params.centered"
            :change="changeParams"
            />
    </v-col>
    <v-col>
            <SwitchC
            :item="{ name: 'position droite', value: 'right', defaultprops:{ show: true }}"
            :valField="displays.params.right"
            :change="changeParams"
            />
    </v-col>
    <v-col>
            <SwitchC
            :item="{ name: 'tabs vertical ?', value: 'vertical', defaultprops:{ show: true }}"
            :valField="displays.params.vertical"
            :change="changeParams"
            />
    </v-col>
    <v-col>
            <SwitchC
            :item="{ name: 'laiser place à une icone dans les tabs ?', value: 'iconText', defaultprops:{ show: true }}"
            :valField="displays.params.iconText"
            :change="changeParams"
            />
    </v-col>
</v-row>

          <v-btn
            fab
            small
            :disabled="!module.id"
            @click="goTo({
                rootPage: page,
                name: 'editiontabulation',
                type: 'modal',
                replace: { id: 'new', idmodule: module.id },
              })">
            <v-icon>mdi-database-plus-outline</v-icon>
          </v-btn>        

              <SimpleTable
                :Headers="[
                  { text: 'Intitulé', value: 'name' },
                  { text: 'Ordre', value: 'order' }
                ]"
                :isDraggable="true"
                @recepVal="changeOrder"
                :datas="listTabs"
                :editTable="[{ icon: 'mdi-pencil', action: editTabs },{ icon: 'mdi-close', action: eraseTabs }]"
                :del="false"
              />

</div>


</template>



<script>

    export default {
        name : 'TabsModule',
        props: ["module", "page"],

        components: {
            TextField: () => import("../../../fields/textfield.vue"),
            colorPicker: () => import("../../../fields/colorPicker.vue"),
            SimpleTable: () => import("../../outils/simpleTable.vue"),
            SwitchC: () => import('../../../fields/switch.vue'),
        },

        data: function() {
            return { 
                displays: this.module.displays,
                dialog : null,
                listTabs : [],
            }
        },

    watch: {
        showMod: {
            // permet de rafraichir quand la modal de la page se ferme
            handler(val) {

                if (val.show === false && val.propsPage.name == 'editiontabulation') {
                    this.getTabsFromAPI()
                }
            }, deep: true

        }
    },

        methods : {

            changeParams(item,value) {
                this.displays.params[item.value] = value
                this.$emit('recepVal',this.displays)
            },

            getTabsFromAPI() {
                this.API({type:'get', url:"/engine/module/tabs/" + this.module.id}).then((resp) => {
                this.listTabs = resp.data
            });
            },

            editTabs(tabs) {

            this.goTo({
            rootPage: this.page,
            name: 'editiontabulation',
            type: 'modal',
            replace: { id: tabs.id, idmodule: this.module.id },
            })

            },

            eraseTabs(tabs) {
                if(confirm('Etes vous sur ??')) {
                    this.API({type:'post', url:"/engine/module/"+this.module.id+"/tabulation/modify", params :{tab:tabs,type:'erase'}}).then((resp) => {
                        if(resp.data.success) {
                            this.getTabsFromAPI()
                        } else {
                            this.$toast.error(resp.data.message);
                        }
                        
                    });                    
                }
            },

            changeOrder(tabs) {
                this.API({type:'post', url:"/engine/module/"+this.module.id+"/tabulation/modify", params :{tab:tabs,type:'order'}}).then((resp) => {
                        if(resp.data.success) {
                            this.$toast.success(resp.data.message);
                            this.getTabsFromAPI()
                        }
                        
                    });                
            }

        },
    
        mounted() { 
            if(this.module.id) this.getTabsFromAPI()
            
        }

    }

</script>

