<template>
    <div v-if="show">
    
        <not-allowed v-if="!AllowedDisplay" :page="page" :module="idmodule" :data="datas"></not-allowed>

        <!-- Dialog qui permet dle déplacement des documents de dossiers à dossiers-->
        <v-dialog v-model="moveDialog" max-width="50%" min-height="50%">
            <v-card v-if="fileMove">
                <v-card-title>
                    Déplacer le document "{{fileMove.path.replace(rootPath+'/','')}}" vers
                </v-card-title>
                <v-card-subtitle>Dossier ciblé : racine/{{ movePath.replace(rootPath,'') }}</v-card-subtitle>
                <v-toolbar dense flat outlined v-for="(doc,i) in listMove" :key="i" @click="move(doc.path)">
                {{ doc.path.replace(rootPath+'/','') }}
                </v-toolbar>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="applyMove(false)" >Annuler</v-btn>
                    <v-btn @click="applyMove(true)" class="primary">Déplacer</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>



        
        <v-card flat class="transparent">

            <!-- v-card qui permet de montrer l'overlay pour le rechergement de la GED-->
            <v-overlay absolute :value="reloadGed" :opacity="0.2">
                    <v-progress-circular
                        color="green"
                        indeterminate
                        rounded
                        height="2"
                    ></v-progress-circular>    
                    rechargement en cours..                            
            </v-overlay>            
        
<!-- Partie qui permet d'inéser un fichier ou de créer un dossiers-->
        <v-card class=" dashed pa-2" flat outlined
        @drop.prevent="(e) => { dropFile(e,false,{path:path,type:'dir'}) }" 
        @dragover="(e) => {e.preventDefault();}"
        @dragleave="(e) => {e.preventDefault();}"
        >
        <v-row no-gutters  justify="center"
        >
            <v-col class="col-1">
                <v-btn icon @click="newFolder = true"><v-icon>mdi-folder-plus</v-icon></v-btn>
            </v-col>
            <v-col class="col-8">        
                <v-file-input
                label="Importer un fichier"
                v-model="files"
                counter
                multiple
                class="ma-0 pa-0"
                ></v-file-input>
            </v-col>
            <v-col class="col-3" align="center">
                <v-btn @click="uploadFile" small>Envoyer</v-btn>
            </v-col>
        </v-row>

        <v-row v-if="newFolder">
            <v-col>
                <v-text-field
                        label="Nouveau dossier"
                        v-model="folderName"
                        @keyup.enter="addFolder"
                        style="margin-bottom:-25px;"
                        prepend-icon="mdi-close-thick"
                        @click:prepend="newFolder = false; folderName='';"     
                        append-icon="mdi-check"
                        @click:append="addFolder"      
                        ></v-text-field>               
            </v-col>
        </v-row>

        </v-card>

<v-icon @click="callAPI()" v-if="!path">mdi-refresh</v-icon>

        <draggable @start="drag = true" @end="drag = false" v-bind="dragOptions" :list="list" handle=".handleFile">
        <div v-for="(file,i) in list" :key="i"
        class="pa-1 rounded" 
        :style="isdragAndDrop[i] && file.type=='dir' ? 'border:2px green dashed;' : ''"
        @drop.prevent="(e) => {dropFile(e,i,file)}" 
        @dragover="(e) => { e.preventDefault(); isdragAndDrop[i] = true}"
        @dragleave="(e) => {e.preventDefault(); isdragAndDrop[i] = false}">
             
                <v-hover v-slot="{ hover }" >
                    
                    <v-card flat :loading="folderOpening[i]" >
                        <v-toolbar dense flat 
                        :color="file.type=='dir'? 'blue lighten-5':''" 
                        :style="file.type!='dir' ? 'border-bottom:1px solid #AEE8FF;':''"
                        :class="{'grey lighten-4':hover}"
                        >
                        <v-overlay absolute :value="editing[i]" :opacity="0.2">
                                <v-progress-circular
                                    color="green"
                                    indeterminate
                                    rounded
                                    height="2"
                                ></v-progress-circular>                                
                        </v-overlay>


                        <v-toolbar-title v-if="params.select && file.type=='file'">
                            <v-checkbox v-model="file.selected" class="ma-0" justify="center" align="center"
                            @click="toggleSelected(file)"
                            red></v-checkbox>
                        </v-toolbar-title>

                        <div
                        v-if="!isEdited[i]"
                        :style="file.type=='dir' ?'cursor:pointer;':''"
                        :class="{'blue lighten-4 rounded pt-3 pb-3 pr-3':file.type=='dir' && hover}"
                        @click="(file.type=='dir')?toExpand(i):''">
                            <v-icon v-if="!expand[i] && file.type=='dir'"  class="ml-2">mdi-chevron-right</v-icon> 
                            <v-icon v-if="expand[i] && file.type=='dir'" class="ml-2">mdi-chevron-down</v-icon> 

                            <v-icon v-if="!expand[i] && file.type=='file'">{{getIcon(file.mime_type)}}</v-icon>
                            <span :class="{'font-weight-bold':file.type=='dir'}" >{{ file.path.split("/").pop() }}</span>
                            <span v-if="file.type=='file'"> ({{ (file.file_size/1000).toFixed(2) }}Ko)</span>
                        </div>

                        <v-text-field
                        v-if="isEdited[i]"
                        label="Intitulé"
                        v-model="EditItem"
                        @keyup.enter="recItem(file,i)"
                        class="pa-0"
                        style="margin-bottom:-30px;"
                        prepend-icon="mdi-close-thick"
                        @click:prepend="isEdited[i] = false"     
                        append-icon="mdi-check"
                        @click:append="recItem(file,i)"      
                        ></v-text-field>


                        <v-spacer></v-spacer>
                        <div v-if="hover && !editing[i] && !isEdited[i]">
                        <!-- appliqué pour fichier et dossier, drag and drop et renommage-->
                        <v-icon icon class="ml-2 red--green" @click="move('',file)">mdi-folder-arrow-left-right-outline</v-icon>
                        <v-icon icon class="ml-2" @click="editItem(i,file)">mdi-pencil</v-icon>
                        <v-icon icon class="ml-2 red--text" @click="deleteItem(i,file)">mdi-close-thick</v-icon>
                        <v-icon v-if="file.type=='file'" icon class="ml-2 red--green" @click="download(file)">mdi-download-box-outline</v-icon>
                        </div>

                </v-toolbar>
                </v-card>

                </v-hover>

                <v-expand-transition>
                    <v-card v-show="expand[i]" class="ml-7" flat outlined>
                        <v-card-text>
                            <GedModule v-if="expand[i]" :idmodule="idmodule" :page="page" :modal="modal" :parent="parent" :path="file.path" @opened="opened" :folder="i" :customparams="customparams" />
                        </v-card-text>    
                    </v-card>
                </v-expand-transition>

        </div>
    </draggable>
</v-card>
    </div>

    </template>
    
    
    
    <script>

        export default {
            name :'GedModule',
            components : {
            //TextField   : () => import('../fields/textfield.vue'),
            draggable    : () => import('vuedraggable'),
            },

        props: ['idmodule','page','modal','parent','path','folder','customparams'],

            data: function() {
    
                return {
                    AllowedDisplay : true,
                    reloadGed : false,
                    filesUpload : [],
                    files : null,
                    isdragAndDrop : {},
                    show : false,
                    datas : [],
                    expand : {},
                    EditItem : '',
                    extensionItem : '',
                    list : [],
                    drag : false,
                    folderOpening : {},
                    isEdited : {},
                    editing : {},
                    goToPath : '',
                    params:{},
                    querys:{},
                    moveDialog : false,
                    movePath : "",
                    listMove : [],
                    fileMove : false,
                    rootPath : '',
                    newFolder : '',
                    folderName : '',

                          // permet de définir si ce module est intégré dans une page form (nouvelle:newform ou en mode édition:editform), ou une page classique : page
                    typePage : (!this.$store.getters['listForm'](this.page)) ? 'page' : this.$store.getters['listForm'](this.page).typeform ,
                }
            },

        computed : {
            modEditForm() {
                return this.$store.getters['listForm'](this.page);
            },
            dragOptions() {
            return {
                animation: 1,
                group: "description",
                disabled: false
            };
            },
        },

        watch : {
            'modEditForm.typeform': {
                // permet de rafraichir quand la modal de modif du module de la page se ferme
                handler(newVal,oldVal) {
                    if(oldVal!='new') return;
                        this.typePage = newVal
                        this.initiateParams()
                        this.callAPI()
                },deep:true,
            } 

        },

            methods :  {    

                callAPI : function() {
                    if(this.typePage=='new') return
                    this.reloadGed = true;
                    this.API({type:'post', url:'displaymodule', params:{id:this.idmodule,params:this.params,querys:this.querys,path:this.path}}).then((resp) => {
                        this.manageDatas(resp.data)
                    })
    
                },    

                manageDatas(data) {
                    this.datas = data
                            if(!data.success) {
                            this.AllowedDisplay = false; 
                            return false;
                            }
                            
                            //creation des expansions : 
                            data.list.forEach((file,i)=> {

                                if(file.type=='dir')  {
                                    this.$set(this.expand,i,false);
                                    this.$set(this.folderOpening,i,false);
                                }
                                this.$set(this.isEdited,i,false);
                                this.$set(this.editing,i,false);
                                this.$set(this.isdragAndDrop,i,false);
                            })
                            this.list = data.list
                            this.$emit('opened',this.folder);
                            this.rootPath = data.rootpath;
                            this.show = true 
                            this.reloadGed = false;
                },

                toggleSelected(item) {

                    if(!item.selected) this.$store.dispatch('removeGedFile',item)
                    if(item.selected) this.$store.dispatch('putGedFile',item)
                    },

                toExpand(i) {

                    this.expand[i] = !this.expand[i];

                    if(this.expand[i]) this.folderOpening[i] = true
                },

                opened(i) {
                    this.folderOpening[i] = false
                },

                editItem(i,file) {
                    this.isEdited[i] = true;
                    var name = file.path.split("/").pop()
                    this.EditItem = (file.type !='dir') ? name.substring(0, name.indexOf(".")) : name;

                },

                download(file) {
                    if(file.type=='dir') return;
                    var name = file.path.split("/").pop();

                    this.API({type:'post',url:'displaymodule',params:{id:this.idmodule,action:'download',path:file.path,params:this.params},apidefault:{responseType:'blob',headers:{'Accept':file.mime_type}}}).then((resp) => {
                        
                        const url = window.URL.createObjectURL(new Blob([resp.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', name);
                        document.body.appendChild(link);
                        link.click();
                    })
                },

                deleteItem(i,file) {

                    if(!confirm('Vous allez supprimer ce contenu de manière définitive, êtes-vous sur ?')) {
                        return false;
                    }
                    this.$store.dispatch('textOverlay','Suppression en cours');
                    this.$store.dispatch('getoverlay', true)
                    
                    this.API({type:'post', url:'displaymodule', params:{id:this.idmodule,params:this.params,delete:file,path:this.path}}).then((resp) => {
                        this.$store.dispatch('getoverlay', false)
                           if(resp.data.success) {  
                             delete this.list[i]    
                                   this.callAPI()
                           } else {
                               this.$toast.error(resp.data.message);
                           }
                       }) 
                },

                 recItem(file,i) {
                    //si le nom n'a pas changé, pas necessaire de faire de modif..
                    var name = file.path.split("/").pop()
                    var Originalname = name;
                    var extension = "";
                    if(this.EditItem == '') {
                        alert('veuillez remplir le champ avant d\'enregistrer');
                        return false;
                    }
                    if(file.type !='dir') {
                        Originalname = name.substring(0, Originalname.indexOf("."))
                        extension = "."+name.split('.').pop();
                    }
                    
                    if(this.EditItem == Originalname) {
                        this.isEdited[i] = false;
                        return;
                    }
                    this.editing[i] = true;
                    this.API({type:'post', url:'displaymodule', params:{id:this.idmodule,rename:file.path,name:this.EditItem+extension,type:file.mime_type,params:this.params}}).then((resp) => {
                           
                        if(resp.data.success) {      
                                this.callAPI()
                        } else {
                            this.$toast.error(resp.data.message);
                        }
                            this.editing[i] = false 
                            this.isEdited[i] = false;
                    })
                },

                upload (i,file) {

                // on enleve le border du div
                if(i) this.isdragAndDrop[i] = false;
                // si on drop pas dans un 
                if(file.type!="dir") return;
                this.$store.dispatch('textOverlay','Importation en cours');
                this.$store.dispatch('getoverlay', true)
                    const formData = new FormData();

                    for( let f = 0; f < this.filesUpload.length; f++ ){
                        formData.append('importFiles['+f+']', this.filesUpload[f]);
                    }

                    formData.append('id', this.idmodule);
                    formData.append('path', file.path);
                    formData.append('params', JSON.stringify(this.params));

                    this.API({type:'post', url:'displaymodule', params:formData, apidefault:{headers:{'Content-Type':'multipart/form-data'}}}).then((resp) => {
                        this.$store.dispatch('getoverlay', false)
                            if(!resp.data.success) {
                            this.$toast.warning(resp.data.message) 
                            return false;
                            }
                            if(resp.data.message) this.$toast.success(resp.data.message);  
                                 
                            this.callAPI()
                        })
                },

                move(path,file) {
                    if(file) this.fileMove = file;
                    this.movePath = (!path) ? this.rootPath : path;
                    this.API({type:'post', url:'displaymodule', params:{id:this.idmodule,params:this.params,querys:this.querys,path:this.movePath}}).then((resp) => {
                        this.listMove = [];
                        resp.data.list.forEach((doc)=> {
                            if(doc.type=='dir') this.listMove.push(doc)
                        })
                        this.moveDialog = true
                    })
                },

                applyMove(retour) {
                    if(!retour) {
                        this.listMove = [];
                        this.fileMove = false;
                        this.moveDialog = false
                        return false;                        
                    }
                    if(this.fileMove) {
                        this.API({type:'post', url:'displaymodule', params:{id:this.idmodule,params:this.params,move:this.fileMove,path:this.movePath}}).then((resp) => {
                            if(!resp.data.success) {
                            this.$toast.warning(resp.data.message) 
                            return false;
                            }
                        if(resp.data.message) this.$toast.success(resp.data.message);
                        this.listMove = [];
                        this.fileMove = false;
                        this.callAPI();
                        this.moveDialog = false
                    })                        
                    }
                },

                dropFile(e,i,file) {
                    if(e.dataTransfer.files.length==0) return;
                    this.filesUpload = e.dataTransfer.files
                    this.upload(i,file)
                },

                uploadFile() {
                    this.filesUpload = this.files
                    if(!this.files || this.files.length==0) return false;
                    this.upload(false,{path:this.path,type:'dir'})
                },

                addFolder() {
                    this.reloadGed = true;
                    this.API({type:'post', url:'displaymodule', params:{id:this.idmodule,params:this.params,newfolder:this.folderName,path:this.path}}).then((resp) => {
                        this.reloadGed = false;
                        if(!resp.data.success) {
                        this.$toast.warning(resp.data.message) 
                        return false;
                        }
                        if(resp.data.message) this.$toast.success(resp.data.message);                         
                        this.newFolder = false;
                        this.folderName = '';
                        this.callAPI();
                    })                    
                },

                getIcon(type) {
                    var icon = "mdi-file"
                    switch(type) {
                        case 'image/jpeg' : case 'image/jpg' : case 'image/png' :
                        icon = "mdi-image"
                        break;
                        case 'application/pdf' : 
                        icon = "mdi-file-pdf-box"
                        break;
                        case 'application/x-sql' : 
                        icon = "mdi-database-search"
                        break;
                        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' : 
                        icon = "mdi-file-word-outline"
                        break;
                        case 'text/csv' : 
                        icon = "mdi-file-delimited-outline"
                        break;
                        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' : 
                        icon = "mdi-file-excel-outline"
                        break;
                        case 'text/plain' : 
                        icon = "mdi-file-document-outline"
                        break;
                        default:
                        icon = "mdi-help-box-outline";
                    }

                    return icon;
                },


                initiateParams() {
                this.params = this.$store.getters.specificPage(this.page).params    
                this.querys = this.$store.getters.specificPage(this.page).querys
                },
                
            },
            mounted() {
                this.initiateParams()
                let getdatas = this.$store.getters.getModuleDatas({page:this.page,id:this.idmodule});
                if(getdatas) {
                    if(this.typePage=='new') return
                    this.reloadGed = true;
                    this.manageDatas(getdatas.datas)
                    this.$store.dispatch('removeModuleDatas',{page:this.page,id:this.idmodule})
                } else {
                    this.callAPI() 
                } 
                

            },
    
            created() {  

            }
    
        }
    
    </script>
    