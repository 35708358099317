<!-- 
Ce composant permet d'avoir les informations initiales obligatoires pour créer un module. 
Dupliquez ce composant pour créer un nouveau module
-->

<template>

    <div>
    <not-allowed v-if="!AllowedDisplay"></not-allowed>

                essai
    </div>

</template>



<script>

    export default {

    name :'PlanningModule',

    props: ['idmodule','page','modal','parent','customparams','customquerys'],

    components : {
 
        },

        data: function() {

            return {

                show : false,
                AllowedDisplay : true,
                params:{},
                querys:{},
            }
        },

        methods :  {
 

        },
        mounted() {
                this.show = true;
                this.params = this.$store.getters.specificPage(this.page).params    
                this.querys = this.$store.getters.specificPage(this.page).querys
 
        }

    }

</script>