<template>


        <v-card 
        elevation="2"
        shaped
        class="ma-1"
        >
        <v-tabs align="start" background-color="blue lighten-5" v-model="tab">
          <v-tab> Champs de recherches </v-tab>
          <v-tab> Filtre de date </v-tab>
          <v-tabs-items v-model="tab" class="pl-4">
            <v-tab-item>
                <v-row>
                    <v-col class="ma-1">

                            <v-btn  
                            fab
                            small
                            @click="goTo({rootPage:page,page:'editionfield',type:'modal',replace:{name:'new',table:module.table}})"
                            >
                            <v-icon>mdi-database-plus-outline</v-icon>
                            </v-btn>

                        <Autocomplete 
                        :item="item" 
                        :valField="displays.params.champs" 
                        :change="onChangeChamps"
                        />

                        <SimpleTable 
                        :Headers="[{text:'crée',value:'create'},{text:'Nom du champ',value:'name'},{text:'clé',value:'cle'},{text:'sur formulaire',value:'form'},{text:'sur recherche',value:'search'}]" 
                        :datas="dataTableField" 
                        :editTable="[{icon:'mdi-pencil',action:editField}]" 
                        @recepVal="changeFieldOrder"
                        :isDraggable="true"
                        :del="false"/>

                    </v-col>
                </v-row>
            </v-tab-item>

            <v-tab-item>
               
                <TextField
                :item="{ name: 'nom du GET donné au champ de début', value: 'valuedeb' ,defaultprops:{ show: true }}"
                :valField="displays.params.paramdatesearch.valuedeb"
                :keyup="(item,model,selectedKey)=> { displays.params.paramdatesearch.valuedeb = model; $emit('recepVal',displays); }"
                /> 
                <TextField
                :item="{ name: 'nom du GET donné au champ de fin', value: 'valuefin' ,defaultprops:{ show: true }}"
                :valField="displays.params.paramdatesearch.valuefin"
                :keyup="(item,model,selectedKey)=> { displays.params.paramdatesearch.valuefin = model; $emit('recepVal',displays); }"
                /> 

                <SwitchC
                :item="{name: 'Recherche par date de début',value: 'deatesearch',liste: [{ text: 'OUI', value: true },{ text: 'NON', value: false }],
                defaultprops:{ show: true, column: false }}"
                :change="(item,model,selectedKey)=> { displays.params.paramdatesearch.setdebdate = model; $emit('recepVal',this.displays); }"
                :valField="displays.params.paramdatesearch.setdebdate"
                /> 

                      <TextField
                      :item="{ name: 'Nom du champ', value: 'namedeb' ,defaultprops:{ show: displays.params.paramdatesearch.setdebdate }}"
                      :valField="displays.params.paramdatesearch.namedeb"
                      :keyup="(item,model,selectedKey)=> { displays.params.paramdatesearch.namedeb = model; $emit('recepVal',displays); }"
                      /> 


                <SwitchC
                :item="{name: 'Recherche par date de fin',value: 'deatesearch',liste: [{ text: 'OUI', value: true },{ text: 'NON', value: false }],
                defaultprops:{ show: true, column: false }}"
                :change="(item,model,selectedKey)=> { displays.params.paramdatesearch.setfindate = model; $emit('recepVal',this.displays); }"
                :valField="displays.params.paramdatesearch.setfindate"
                /> 

                <TextField
                    :item="{ name: 'Nom du champ', value: 'namefin' ,defaultprops:{ show: displays.params.paramdatesearch.setfindate }}"
                    :valField="displays.params.paramdatesearch.namefin"
                    :keyup="(item,model,selectedKey)=> { displays.params.paramdatesearch.namefin = model; $emit('recepVal',displays); }"
                    /> 

    
                <SwitchC
                :item="{name: 'Recherche par mois',value: 'setmonth',liste: [{ text: 'OUI', value: true },{ text: 'NON', value: false }],
                defaultprops:{ show: true, column: false }}"
                :change="(item,model,selectedKey)=> { displays.params.paramdatesearch.setmonth = model; $emit('recepVal',this.displays); }"
                :valField="displays.params.paramdatesearch.setmonth"
                /> 

            </v-tab-item>


            </v-tabs-items>
        </v-tabs>
        </v-card>



</template>



<script>

    export default {
        name : 'RechModule',
        props: ["module", "page"],

        components : {
            Autocomplete   : () => import('../../fields/autocomplete.vue'),
            SimpleTable : () => import('../outils/simpleTable.vue'),
            SwitchC: () => import("../../fields/switch.vue"),
            TextField: () => import("../../fields/textfield.vue"),
        },

        data: function() {
            return { 
                item : {'multiple':true,name:'Champs affichés pour la recherche',value:'champs',liste:[],
                    defaultprops:{show:true,multiple:true,chips:true,deletablechips:true,modelRules:[v => !!v || 'Au moins un champ doit etre renseigné']}},
                valField : [],
                displays : this.module.displays,
                allFields : [],
                dataTableField : [],
                tab : '',
            }
        },


        methods : {

            editField(field) {
                if (this.findName(field.cle)['create']) {
                this.goTo({
                    rootPage: this.page,
                    name: "editionfield",
                    type: "modal",
                    replace: { id: field.id, variant: 0 },
                    paramDialog: { width: 1000 },
                });
            } else {
                this.goTo({
                    rootPage: this.page,
                    name: "editionfield",
                    type: "modal",
                    replace: { name: field.name, table: field.table },
                    paramDialog: { width: 1000 },
                });
            }
            },
            // permet de construire les ligen du tableau : l'appel s'effectuer lors de l'insertion d'un nouveau champ
            constructDataField() {

                this.dataTableField = []
                this.displays.params.champs.forEach((cle) => {
                    var data = {create:this.findName(cle)['create'],id:this.findName(cle)['id'],name:this.findName(cle)['displays']['name'],table:this.findName(cle)['table'],cle:cle,'form':this.findName(cle)['format']['form']['type'],'search':this.findName(cle)['format']['search']['type']}
                    this.dataTableField.push(data)
                })

            },

            changeFieldOrder(data) {
                this.displays.params.champs = []
                data.forEach((val) => {
                    this.displays.params.champs.push(val.cle)
                })
            this.$emit('recepVal',this.displays);
            },

            findName(cle) {
                return this.allFields.find( el => el.cle === cle);
            },

            onChangeChamps(item,model,selectedKey) {
            var champs = []
                for(var selected in model) {
                if(typeof model[selected] == 'object') { champs.push(model[selected][selectedKey]) } else { champs.push(model[selected]); }
                }
                    this.displays.params.champs = champs
                    this.constructDataField()
                    this.$emit('recepVal',this.displays);
            },

            getFromAPI() {
                
                this.getListField({filter:{table : this.module.table}}).then((resp) => {         

                    resp.forEach((field)=>{
                        this.item.liste.push({"text":field.displays.name,"value":field.cle})
                        this.allFields.push(field)
                    })

                this.constructDataField()                            
                })
                
            },


        },
    
        mounted() { 
            
            this.getFromAPI()
            
        }

    }

</script>

