<template>
<div>

    <not-allowed v-if="!AllowedDisplay" :data="datas"></not-allowed>

    <div>
        <v-row v-for="item in list" :key="item.nom" no-gutters>

            <v-col>             
 <v-icon  x-small v-if="mod" @click="editField(item)">mdi-pencil</v-icon>

 <v-icon small v-if="mod && item.create" @click="goTo({
                rootPage: page,
                page: 'editiondroit',
                type: 'modal', 
                replace: { page:page, id: item.id, target:'field', root:'interface'},
                })">mdi-account-eye-outline</v-icon>


                <component 
                    :is="getFieldComponent(item.format)" 
                    :item="item" 
                    :keyup="onkeyup" 
                    :change="onChange"
                    type="form"
                    :page="page"
                    :modal="modal"
                    :hook="hook"
                    :mod="mod"
                    :valField="valFields[item.value]"
                    />            
            </v-col>


        </v-row>

        <v-row v-if="typeSave=='inside'">
            <v-col class="text-center">
                <v-btn class="primary" @click="validate" :disabled="!valid">{{dependencies.butname}}</v-btn>
            </v-col>
        </v-row>

        </div>
</div>
</template>



<script>

    export default {
    props: ['mod','idmodule','page','modal','parent','hook','customparams','customquerys'],

        data: function() {

            return {
                valid : true,
                show : false,
                AllowedDisplay : true,
                list : [],
                searchvalues : [],
                params : {},
                datas : [],
                dependencies : [],
                typeSave : '',
                keyForm : '',
                valFields : {},
                url : ''
            }
        },

        methods :  {

            validate() {
                this.validateForm({form:this.$refs.form,page:this.page,fields:this.valFields,urlSend:this.url,
                        ifNotValid:()=>{
                            this.$store.dispatch('changeParamForm',{page:this.page,type:'validate',value:0})
                        },
                        callback:()=> {                      
                        this.$store.dispatch('removeForm', { uid:this.page })
                     }})

            },

            callAPI : function() {
                this.API({type:'post', url:'displaymodule', params:{id:this.idmodule,params:this.params}}).then((resp) => {

                  this.manageDatas(resp.data);

                })


            },   

            manageDatas(data) {
                this.datas = data
                this.dependencies = data.dependencies
                if(!data.success) {
                this.AllowedDisplay = false; 
                return false;
                }
                this.typeSave = data.typesave;
                this.list   = this.datas.fields;
                this.keyForm = data.paramsave.key
                // si le form ne teint pas compte du store (indépendante de la page)


                switch(this.typeSave) {
                    case 'vuex' :
                        this.list.forEach((field)=>{                       
                            this.valFields[field.cle] = (this.dependencies.datas && this.dependencies.datas[field.cle]) ? this.dependencies.datas[field.cle] : "";
                            this.$store.dispatch('pushIForm', { value:this.valFields[field.cle], champ:field.cle, key:this.keyForm })
                        })
                        break;
                    case 'localstorage' :
                        this.list.forEach((field)=>{
                            this.valFields[field.cle] = (this.dependencies.datas[field.cle]) ? this.dependencies.datas[field.cle] : "";
                        })
                        localStorage.setItem(this.keyForm, JSON.stringify(this.dependencies.datas));
                        break;
                    case 'inside' :
                        this.valFields = this.dependencies.datas;
                        this.url = this.dependencies.url;
                        break;
                    default :
                    var newValFields = this.$store.getters.listForm(this.page).fields;
                    Object.keys(newValFields).forEach(key => {
                        this.$set(this.valFields, key, newValFields[key]);
                        });
                        break;
                } 

            this.datas.fields.forEach((f)=> {
                if(this.$store.getters.listForm(this.page) && typeof this.$store.getters.listForm(this.page).fields[f.value]=='undefined') {
                    this.$store.dispatch('pushForm', { page:this.page, value:null, champ:f.value })
                }
            })
            

                this.show = true  
            },

             onkeyup : function(item,model) {

                switch(this.typeSave) {
                    case 'vuex' :
                    this.$store.dispatch('pushIForm', { value:model, champ:item.value, key:this.keyForm })
                        break;
                    case 'localstorage' :
                        var vals = JSON.parse(localStorage.getItem(this.keyForm));
                        vals[item.value] = model;
                        localStorage.setItem(this.keyForm, JSON.stringify(vals));
                        break;
                    case 'inside' :
                        break;
                    default :
                        // on met a jour l'array qui permet l'intégration dans l'API
                        this.$store.dispatch('pushForm', { page:this.page, value:model, champ:item.value })

                        break;
                }
                    
                    this.$set(this.valFields, item.value, model);
                    
                
            },

            onChange : function(item,model,selectedKey) {
                var realValue = model;
                
                    if((item.format=='autocomplete' || item.format=='select')) {
                        realValue = [];
                        
                        if(item.defaultprops.multiple) {
                            for(var selected in model) {
                                if(typeof model[selected] == 'object') { realValue.push(model[selected][selectedKey]) } else { realValue.push(model[selected]); }
                            }
                        } else {
  
                        realValue = (typeof model =='object' && model!=null) ? model[selectedKey] : model;  
                          
                        }

                    } 
                
                    switch(this.typeSave) {
                    case 'vuex' :
                    this.$store.dispatch('pushIForm', { value:realValue, champ:item.value, key:this.keyForm })
                        break;
                    case 'localstorage' :

                        break;
                    case 'inside' :
                        break;
                    default :
                    // on met a jour l'array qui permet l'intégration dans l'API
                    this.$store.dispatch('pushForm', { page:this.page, value:realValue, champ:item.value })
                    break;
                    }

                this.$set(this.valFields, item.value, realValue);
            },

            editField(field) {
                if (field.create) {
                    this.goTo({
                    rootPage: this.page,
                    name: "editionfield",
                    type: "modal",
                    replace: { id: field.id, variant : 0 },
                    paramDialog: { width: 1000 },
                    });
                } else {

                    this.goTo({
                    rootPage: this.page,
                    name: "editionfield",
                    type: "modal",
                    replace: { name: field.name, table : field.table },
                    paramDialog: { width: 1000 },
                    });
                }
            },

            editDroit(field){
                this.goTo({
                rootPage: this.page,
                name: 'editiondroit',
                type: 'modal', 
                replace: { page:this.page, id: field.id, target:'field', root:'interface'},
                })                
            }


        },
        mounted() {

            this.params = this.$store.getters.specificPage(this.page).params    
            this.querys = this.$store.getters.specificPage(this.page).querys

           
           let getdatas = this.$store.getters.getModuleDatas({page:this.page,id:this.idmodule});
                if(getdatas) {
                    this.manageDatas(getdatas.datas)
                    this.$store.dispatch('removeModuleDatas',{page:this.page,id:this.idmodule})
                } else {
                    this.callAPI() 
                } 
 
        }

    }

</script>