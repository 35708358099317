<template>
  <v-card>

    <v-row>
      <v-col class="ma-1">
        <v-tabs align="start" background-color="blue lighten-5" v-model="tab">
          <v-tab> Paramètres généraux</v-tab>
          <v-tab> Filtre </v-tab>
          <v-tab> Champs affichés </v-tab>
          <v-tab> Modules de recherche </v-tab>
          <v-tab> Actions </v-tab>
          <v-tabs-items v-model="tab" class="pl-4">
            <v-tab-item>
              <!-- PARAM GENERAUX -->
              <span class="text-h6">Bouton d'ajout</span>
              <SwitchC :item="{
                name: 'Affichage du bouton', value: 'display', liste: [{ text: 'OUI', value: true }, { text: 'NON', value: false }],
                defaultprops: { show: true, column: false }
              }" :change="ChangeAddButton"
                :valField="displays.params.ajout.display" />

              <div v-if="displays.params.ajout.display">
                <v-row>

                  <v-col>
                    <TextField :item="{ name: 'Nom du bouton', value: 'text', defaultprops: { show: true } }"
                      :valField="displays.params.ajout.text" :keyup="ChangeAddButton" />
                    <TextField :item="{ name: 'icône', value: 'icon', defaultprops: { show: true } }"
                      :valField="displays.params.ajout.icon" :keyup="ChangeAddButton" />
                    <Select :item="{
                      name: 'type d\'affichage', value: 'type', liste: [{ text: 'en modal', value: 'modal' }, { text: 'en centre', value: 'center' }],
                      defaultprops: { show: true, column: false }
                    }" :change="ChangeAddButton"
                      :valField="displays.params.ajout.type" />
                  </v-col>

                  <v-col class="grey lighten-4" v-if="displays.params.ajout.type == 'modal'">
                    <span class="text-h6">Paramètre modal</span>
                    <paramDialog v-if="displays.params.ajout" :page="page" :params="displays.params.ajout.paramdialog"
                      @returnParamDialog="ChangeParamDialog" />
                  </v-col>
                  <v-col>
                    <ArrayTable 
                    :item="{otherparams:{arrayTable:[{type:'',key:'key',name:'Variable',ArrayValue:FieldsOfModuleTable},{type:'select',key:'type',name:'type de donnée',ArrayValue:[{text:'nombre',value:'number'},{text:'texte',value:'text'},{text:'booleen',value:'bool'}]},{type:'',key:'value',name:'Valeur',description:'(Vous pouvez insérer des variables d\'environnement)'}]},
                    defaultprops:{show:true,tableDraggable:false,tableDel:true,hideFooter:true,hidesearch:true,addTitle:'Ajouter une variable'}}"
                    :valField="displays.params.ajout.variables"
                    :change="(item,model) => { displays.params.ajout.variables = model; }"
                    />
                  </v-col>

                </v-row>
              </div>
              <!-- FIN AJOUT -->
              <!--
              <span class="text-h6">tri</span>
              <Autocomplete

                :item="itemSortBy"
                :valField="displays.params.paramtab.sortBy"
                :change="ChangeParam"
              />
                -->

              <SwitchC :item="{
                name: 'Afficher la checkbox par ligne', value: 'showselect',
                defaultprops: { show: true, column: false }
              }" :change="ChangeParam"
                :valField="displays.params.paramtab.showselect" />

              <SwitchC :item="{
                name: 'Checkbox en entete', value: 'singleselect',
                defaultprops: { show: true, column: false }
              }" :change="ChangeParam"
                :valField="displays.params.paramtab.singleselect" />

              <SwitchC :item="{
                name: 'Masquer la pagination', value: 'hidepagination',
                defaultprops: { show: true, column: false }
              }" :change="ChangeParam"
                :valField="displays.params.paramtab.hidepagination" />

                <SwitchC :item="{
                name: 'Afficher le tableau uniquement si un filtre est appliqué', value: 'set',
                defaultprops: { show: true, column: false }
              }" 
              :change="(item,value) => { displays.params.displayiffilter.set = value }"
              :valField="displays.params.displayiffilter.set" />

              <TextField :item="{ name: 'Texte à afficher si attente d\'un filtre', value: 'text', defaultprops: { show: true } }"
              v-if="displays.params.displayiffilter.set"
              :valField="displays.params.displayiffilter.text" 
              :keyup="(item,value) => {displays.params.displayiffilter.text = value }" 
              />

              <Autocomplete :item="{
                name: 'Ligne par page',
                value: 'itemsPerPage',
                defaultprops: { show: !displays.params.paramtab.hidepagination },
                liste: [
                  { text: '5', value: 5 },
                  { text: '10', value: 10 },
                  { text: '50', value: 50 },
                  { text: '100', value: 100 },
                ],
              }" :valField="displays.params.paramtab.itemsPerPage" :change="ChangeParam" />
            </v-tab-item>

            <v-tab-item>
              <!-- FILTRES -->
              <Filtre :filtre="displays.filter" :table="module.table"
                @recepVal="(array) => { changeEC(array, 'filter') }" />
            </v-tab-item>

            <v-tab-item>
              <!-- CHAMPS AFFICHES-->
              <v-tabs align="start" background-color="blue lighten-5" v-model="tab2">
                <v-tab> Pour la table</v-tab>
                <v-tab> Pour le fichier excel </v-tab>
              <v-tabs-items v-model="tab2" class="pl-4">
                <v-tab-item>
                  <p>Vous pouvez attribuer une couleur de fond pour une ligne avec la valeur as : 'cellBackColor'</p>
                  <p>Vous pouvez attribuer une couleur de texte pour une colonne avec valeur as : 'rowColor'</p>
                  <p>Vous pouvez attribuer une couleur de fond pour une colonne avec valeur as : 'rowBackColor'</p>

                  <Fields :table="module.table" :champs="displays.params.champs" :filter="displays.filter" :page="page"
                    @recepVal="(array) => { changeEC(array, 'champs') }" />                
                </v-tab-item>
                <v-tab-item>
                  <Fields :table="module.table" :champs="displays.params.champsexcel" :filter="displays.filter" :page="page"
                    @recepVal="(array) => { changeEC(array, 'champsexcel') }" />                  
                </v-tab-item>
            </v-tabs-items>
            </v-tabs>


            </v-tab-item>

            <v-tab-item>
              <!-- Modules de recherche -->
              <div class="text-h6">Si le ou les modules sélectionnés se trouvent sur la même page, ils interagieront avec
                le tableau</div>

              <SimpleTable :Headers="[
                { text: 'ID', value: 'id' },
                { text: 'champs', value: 'champs' },
              ]" :datas="dataTableSearch" :selected="displays.params.searchmodule" keyRow="id"
                @getselected="getselected" />

              <div class="text-h6">champs selectionnés pour la recherche globale</div>
              <Autocomplete :item="{
                name: 'Champs ciblés pour recherche globale',
                value: 'globalsearch',
                defaultprops: { show: true, multiple: true },
                liste: listFields,
              }" :valField="displays.params.globalsearch" :change="changeGlobalSearch" />

            </v-tab-item>

            <v-tab-item>

              <listActions :page="page" :module="module" :key="reload" />

            </v-tab-item>

          </v-tabs-items>
        </v-tabs>
      </v-col>
    </v-row>
  </v-card>
</template>



<script>

export default {
  name: "TableModule",
  props: ["module", "page"],

  components: {
    Autocomplete: () => import("../../fields/autocomplete.vue"),
    TextField: () => import("../../fields/textfield.vue"),
    SimpleTable: () => import("../outils/simpleTable.vue"),
    Filtre: () => import("../outils/constructFiltre/Filtre.vue"),
    Fields: () => import("../outils/constructField/Field.vue"),
    SwitchC: () => import("../../fields/switch.vue"),
    Select: () => import("../../fields/select.vue"),
    paramDialog: () => import("../outils/paramDialog.vue"),
    listActions: () => import("../outils/listActions.vue"),
    ArrayTable   : () => import('../../fields/arrayTable.vue'),
  },

  data: function () {
    return {

      tab: null,
      tab2 : '',
      itemSomme: { multiple: true, name: "Champs pour les sommes", value: "somme", liste: [] },
      itemSortBy: {
        multiple: true, name: "Trier par", value: "sortBy", liste: [],
        defaultprops: {
          show: true,
          chips: true,
          deletablechips: true,
          multiple: true,
          description: 'choisissez le tri de colonne',
          persistenthint: true,
        }
      },
      displays: this.module.displays,
      dataTableSearch: [],
      reload: 0,
      listFields: [],
      FieldsOfModuleTable: []

    };
  },

  watch: {
    showMod: {
      // permet de rafraichir quand la modal de la page se ferme
      handler(val) {
        if (val.show === false) {
          this.reload++;
        }

      },
      deep: true,
    },

  },

  methods: {

    getsearchFromAPI() {
      this.API({ type: 'post', url: "/engine/module/list", params: { type: 'search' } }).then((resp) => {

        resp.data.forEach((v) => {
          this.dataTableSearch.push({ id: v.id, champs: v.displays.params.champs.join(', ') });
        });
        // pour intégrer la recherche globale :
        this.dataTableSearch.push({ id: 'global', champs: 'sensible au champ de la recherche globale' });
      });
    },


    //permet de récupérer les champs issues de la requete pur les insérer dans la recherche globale
    getFields() {

      this.API({ type: 'post', url: 'engine/module/getfieldswithjunctions', params: { table: this.module.table, filter: this.displays.filter } }).then((resp) => {
        this.Fields = resp.data.fields
        resp.data.fields.forEach((field) => {
          this.listFields.push({ 'text': field.table + '.' + field.field, value: field.cle })
          if(field.table == this.module.table) this.FieldsOfModuleTable.push({ 'text': field.table + '.' + field.field, value: field.cle, default:'value' })
        })
      })

    },


    changeEC(array, type) {
      this.displays.params[type] = array;
      this.$emit('recepVal', this.displays)
      if (type == 'filter') this.constructListSort();
    },

    changeGlobalSearch(item, value, selectedKey) {
      this.displays.params.globalsearch = [];
      for (var selected in value) {
        if (typeof value[selected] == 'object') { this.displays.params.globalsearch.push(value[selected][selectedKey]) } else { this.displays.params.globalsearch.push(value[selected]); }
      }
      this.$emit('recepVal', this.displays)
    },

    getselected(val) {
      this.displays.params.searchmodule = val;
      this.$emit('recepVal', this.displays)
    },

    ChangeParam(item, value, selectedKey) {

      if (item.multiple) {
        this.displays.params.paramtab[item.value] = []
        for (var selected in value) {
          if (typeof value[selected] == 'object') { this.displays.params.paramtab[item.value].push(value[selected][selectedKey]) } else { this.displays.params.paramtab[item.value].push(value[selected]); }
        }
      } else {
        var realValue = (typeof value === 'object') ? value[selectedKey] : value;
        this.displays.params.paramtab[item.value] = realValue;
      }

      this.$emit('recepVal', this.displays)
    },

    ChangeAddButton(item, value, selectedKey) {
      var realValue = (typeof value == 'object') ? value[selectedKey] : value;
      this.displays.params.ajout[item.value] = realValue;
      this.$emit('recepVal', this.displays)
    },

    ChangeParamDialog(data) {
      this.displays.params.ajout.paramdialog = data;
      this.$emit('recepVal', this.displays)
    },

    constructListSort() {
      this.itemSortBy.liste = []
      if (this.displays.params.champs.length == 0) return

      this.displays.params.champs.forEach((v) => {
        this.itemSortBy.liste.push({ text: v.as, value: v.as });
      })
    }

  },

  mounted() {
    this.getsearchFromAPI();
    this.constructListSort();
    this.getFields()
  },
};
</script>

