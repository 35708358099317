<template>
  <v-dialog v-model="dialog" persistent max-width="600px" min-width="360px">
    <div>
      <v-tabs
        v-model="tab"
        show-arrows
        background-color="deep-purple accent-4"
        icons-and-text
        dark
        grow
      >
        <v-tabs-slider color="purple darken-4"></v-tabs-slider>
        <v-tab>
          <v-icon large>mdi-email</v-icon>
          <div class="caption py-1">réinitialisation du mot de passe</div>
        </v-tab>
        <v-tab-item>
          <v-card class="px-4">
            <v-card-text>
              <v-form ref="loginForm" v-model="valid" lazy-validation>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="loginEmail"
                      :rules="[(v) => !!v || 'e-mail requis',(v) => /.+@.+\..+/.test(v) || 'requiert un e-mail valide']"
                      label="E-mail"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
              <v-card-actions>
                <v-row>
                <v-col class="col-12 text-center rounded red lighten-3" v-if="verified && error">
                    {{message}}
                  </v-col>
                  <v-col class="col-12 text-center rounded green lighten-3" v-if="verified && !error">
                    {{message}} 
                  </v-col>
                  <v-col class="col-12">
                    <v-btn :disabled="!valid" color="success" @click="validate" v-if="(!verified || error)" class="mx-2">
                      Envoyer le mail de réinitialisation de mot de passe
                    </v-btn>
                    <v-btn color="primary" @click="retour" class="mx-2">
                      retour connexion</v-btn>   
                  </v-col>
                </v-row>             
              </v-card-actions>



            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </div>
  </v-dialog>
</template>

<script>

export default {
  name: "LognComponent",

  components: {
  },

  data: function () {
    return {
      dialog: true,
      tab: 0,
      valid: true,
      message: "",
      loginEmail: "",
      error : false,
      verified : false
    };
  },

  methods: {
    validate() {
      if (this.$refs.loginForm.validate()) {

        this.API({
          type: "post",
          url: "/auth/sendForgot",
          params: { email: this.loginEmail },
        }).then((resp) => {
          this.verified = true
          this.error = !resp.data.success;
          this.message = resp.data.message;
        });

        // submit form to server/API here...
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    retour() {
      this.$router.push({ name: "login" });
    },
  },

  created() {
    this.$store.dispatch("setUser").then(() => {
      window.location.href = '/'
    })
  },
};
</script>
