<template>
  <div class="text-center">
 
    <v-dialog
      v-model="dialog"
      overlay-opacity="0.1"
      width="300"
    >
      <v-card
        color="primary"
        dark
        class="top"
      >
        <v-card-text>
          chargement en cours
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>



<script>
  export default {
      name : 'LoaDing',
    data () {
      return {
        dialog: this.$store.getters['loading'],
      }
    },

computed : {
    load : function() {
      return this.$store.getters['loading']
    }
},
    watch: {
      load (val) {
        this.dialog = val

      },
    }, 
  }
</script>