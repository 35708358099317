<template>
    
    <div>
        <v-container v-if="checkDroits">
            
                <v-row>
                    <v-col class="text-h5 text-center">
                        Gestion des API 
                    </v-col>
                </v-row>

                <v-row>

                    <v-col class="col-md-6 col-12">
                        <div class="text-h5">Envoi d'E-mails :</div>
                        <Select                          
                        :change="(item,model,sel) => { if(!mail.isauth) mail.set = model[sel] }"
                        :item="{name:'Choix du paramétrage',value:'set',liste:listMails,
                        defaultprops:{show:true}}" 
                        :valField="mail.set" /> 
                        <v-form v-model="validMail" ref="mail" v-if="!mail.isauth">
                        <v-row v-if="mail.set=='manual'">
                            <v-col>
                            <TextField 
                            :item="{value:'host',name:'Hôte',
                            defaultprops:{show:true}}"
                            :keyup="(item,model) => { mail.host = model }"
                            :valField="mail.host" /> 
                            <TextField 
                            :item="{value:'port',name:'Port de sortie',
                            defaultprops:{show:true}}"
                            :keyup="(item,model) => { mail.port = model }"
                            :valField="mail.port" />
                            <TextField 
                            :item="{value:'user',name:'nom utilisateur',
                            defaultprops:{show:true}}"
                            :keyup="(item,model) => { mail.user = model }"
                            :valField="mail.user" />  
                            <TextField 
                            :item="{value:'password',name:'Mot de passe',
                            defaultprops:{show:true}}"
                            :keyup="(item,model) => { mail.password = model }"
                            :valField="mail.password" /> 
                            <TextField 
                            :item="{value:'mailfrom',name:'Mail d\'envoi',
                            defaultprops:{show:true}}"
                            :keyup="(item,model) => { mail.mailfrom = model }"
                            :valField="mail.mailfrom" /> 
                            </v-col>
                        </v-row>
                        <v-row v-if="mail.set=='mailjet'">
                            <v-col>
                            <TextField 
                            :item="{value:'public',name:'Clé publique',
                            defaultprops:{show:true}}"
                            :keyup="(item,model) => { mail.public = model }"
                            :valField="mail.public" /> 
                            <TextField 
                            :item="{value:'private',name:'Clé privée',
                            defaultprops:{show:true}}"
                            :keyup="(item,model) => { mail.private = model }"
                            :valField="mail.private" />
                            <TextField 
                            :item="{value:'mailfrom',name:'Email d\'envoi',
                            defaultprops:{show:true}}"
                            :keyup="(item,model) => { mail.mailfrom = model }"
                            :valField="mail.mailfrom" />
                            <v-btn @click="setMailsKeys">Enrgistrer les clés</v-btn>
                            </v-col>
                        </v-row>
                        <v-row v-if="mail.set=='brevo'">
                            <v-col>
                            <TextField 
                            :item="{value:'key',name:'Clé API',
                            defaultprops:{show:true}}"
                            :keyup="(item,model) => { mail.key = model }"
                            :valField="mail.key" /> 
                            <TextField 
                            :item="{value:'mailfrom',name:'Email d\'envoi',
                            defaultprops:{show:true}}"
                            :keyup="(item,model) => { mail.mailfrom = model }"
                            :valField="mail.mailfrom" />
                            <v-btn @click="setMailsKeys">Enrgistrer les clés</v-btn>
                            </v-col>
                        </v-row>
                        </v-form>
                        <v-row v-if="mail.isauth">
                                    <v-col class="text-center">
                                        <v-icon class="text-h3 green--text text-center">mdi-check</v-icon>
                                        <div class="text-h6">Vous êtes connecté à {{listMails.find(el => el.value == mail.set).text}}</div>
                                   
                                        <v-btn primary
                                            @click="Deconnect('mail')"
                                            >Se déconnecter</v-btn>
                                    </v-col>    
                                </v-row> 
                    </v-col>


                    <v-col class="col-md-6 col-12">
                        <div class="text-h5">Stockage de documents</div>
                        <Select                          
                        :change="(item,value) => { if(!storage.isauth) storage.set = value['value']}"
                        :item="{name:'Choix du paramétrage',value:'storageSet',liste:listStorage,
                        defaultprops:{show:true}}" 
                        :valField="storage.set" /> 

                        <div v-if="!storage.isauth">
                        <v-row v-if="storage.set=='dropbox' || storage.set=='gdrive'" >
                            <v-col>
                                <v-row>
                                    <v-col>
                                        <div>Comment créer ma connection avec mon google Drive</div>

                                        <ul>
                                            <li>1 - Connectez-vous avec votre compte google et dirigez-vous sur la console google : <a href="https://console.cloud.google.com/">console.cloud.google</a></li>
                                            <li>2 - Créez un nouveau projet en renseignant en tant que nom "monapplication"</li>
                                            <li>3 - Sélectionnez "monapplication" et cliquez sur ACTIVER LES API ET SERVICES pour activer Google Drive API</li>
                                            <li>4 - Dans le menu gauche, cliquez sur "Ecran de consentement". 
                                                <ul>
                                                    <li>1. Ecran de consentement
                                                        <ul>
                                                            <li>a. selectionnez User Type "Externe"</li>
                                                            <li>b. nommez votre application "monapplication"</li>
                                                            <li>c. entrez votre adresse e-mail google</li>
                                                            <li>Laisser "Logo de l'application" vide (facultatif)</li>
                                                            <li>d. renseignez la page d'accueil de l'application : {{interfaceApp}}. Confidentialité et conditions à ne pas remplir (facultatif)</li>
                                                            <li>e. Dans "Domaines autorisés", Ajouter un domaine et mentionnez : "{{interfaceApp.split("//")[1]}}"</li>
                                                            <li>Mentionnez votre e-mail dans "Coordonnées du développeur"</li>
                                                        </ul>
                                                    </li>
                                                        <li>2. Niveau d'accès
                                                            <ul>
                                                                <li>a. Cliquez sur "ajouter ou supprimer des champs d'applications"</li>
                                                                <li>b. Cherchez la ligne "Google drive API" -> champ d'application ".../auth/drive", cochez la case et cliquez sur "mise à jour", puis : "Enregistrer et continuer" en bas à gauche</li>
                                                                <li>c. Utilisateurs tests : Vous pouvez mentionner les e-mails de toutes les personnes qui peuvent utiliser Google drive dans cette liste.</li>
                                                                <li>Cliquez sur Enregistrer et Continuer</li>
                                                            </ul>
                                                    </li>
                                                    <li>Résumé : Cliquez sur "revenir au tableau de bord" en bas de page</li>
                                                </ul>

                                            </li>
                                            <li>5 - Dans le menu de gauche , cliquez sur "Identifiants", et créez votre "ID client Oauth"
                                                <ul>
                                                    <li>a. Dans type d'application, selectionnez Application Web. Laissez le nom tel quel</li>
                                                    <li>b. URL de redirection authorisés, mentionnez : "{{interfaceApp}}/activationAPI/{{ storage.set }}"</li>
                                                    <li>Cliquez sur "Créer" et copiez votre ID client et votre Code secret client dans les champs ci-dessous.</li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </v-col>
                                </v-row>
                                <v-row align="center" class="ma-4" justify="center">
                                    <v-col class="col-8">
                                        <v-form v-model="validStorage" ref="storage" >

                                        <TextField 
                                        :item=" {value:'key',name:'Clé API',                       
                                        defaultprops:{show:true,required:true,disabled:(storage.state=='mustAuth')}} "
                                        :keyup="(item,model) => { storage.key = model } "
                                        :valField="storage.key" /> 

                                        <TextField 
                                        :item="{value:'secret',name:'Clé API secret',
                                        defaultprops:{show:true,required:true,disabled:(storage.state=='mustAuth')}}"
                                        :keyup="(item,model) => { storage.secret = model }"
                                        :valField="storage.secret" />


                                        <TextField 
                                        :item="{value:'rootapp',name:'chemin racine pour l\'application',
                                        defaultprops:{show:true,required:false,disabled:(storage.state=='mustAuth')}}"
                                        :keyup="(item,model) => { storage.rootapp = model }"
                                        :valField="storage.rootapp" />
                                        <v-alert dense border="left" type="warning">
                                        Vous pouvez définir le chemin racine pour l'application (ex : 'myapp/' ). laissez vide pour utiliser la racine du stockage
                                        <br> Une fois mentionné, vous ne pourrez plus modifier ce paramètre.
                                        </v-alert>
                                        <div>
                                            <v-btn primary
                                            :disabled="!validStorage || storage.state=='mustAuth'"
                                            @click="setStorageKeys"
                                            >Enregistrer la clé</v-btn>
                                        </div>
                                        </v-form>                                        
                                    </v-col>
                                    <v-col class="col-4 text-center" align="center" justify="center">
                                        <v-btn class="success"
                                        height="90"
                                        v-if="!storage.isauth"
                                        :disabled="storage.state!='mustAuth'"
                                        @click="authStorage"
                                        >Cliquez ici pour lier<br>votre compte <br>{{listStorage.find(el => el.value == storage.set).text}} à l'application</v-btn>  
                                        <v-btn primary
                                            @click="Deconnect('ged')"
                                            >Se déconnecter</v-btn>

                                        </v-col>
                                </v-row>
                            </v-col>
                            </v-row>
                            </div>
                            <div v-else>
                                 <v-row>
                                    <v-col class="text-center">
                                        <v-icon class="text-h3 green--text text-center">mdi-check</v-icon>
                                        <div class="text-h6">Vous êtes connecté à {{listStorage.find(el => el.value == storage.set).text}}</div>
                                   
                                        <v-btn primary
                                            @click="Deconnect('ged')"
                                            >Se déconnecter</v-btn>
                                    </v-col>    
                                </v-row>                               
                            </div>



                            </v-col>
                        </v-row>

        </v-container>
        <v-container v-else>
            <v-row>
                <v-col>
                    {{isAllow}}
                </v-col>
            </v-row>
        </v-container>

    </div>

</template>
    
    <script>
 
    export default {
    
    name : "externalAPI",
    props: [
        "page",
        "modal",
      ],
    components : {
        TextField   : () => import('../../fields/textfield.vue'),
        Select      : () => import('../../fields/select.vue'),
        },
    
        data: function() {
            return {
                valid : false,
                isAllow : 'En attente du module',
                checkDroits : false,
                config : {set:'',key:'',secret:''},
                listStorage : [{text:'Dropbox',value:'dropbox'},{text:'Google Cloud',value:'gdrive'}],
                listMails : [{text:'Configuration manuelle',value:'manual'},{text:'Mailjet',value:'mailjet'},{text:'Brevo (ex sendinblue)',value:'brevo'}],
                storage : {},
                mail : {},
                interfaceApp : '',
                validStorage : true,
                validMail : true,

                mailSet : '',
                mailKey : '',
                mailSecret : '',
                
                getInfos : {},
            }
        }, 
    
    
    
        methods: {

            setStorageKeys() {
                if(this.$refs.storage.validate()) {
                    this.API({type:'post', url:'/engine/defineStorageAPI',params:{set:this.storage.set,key:this.storage.key,secret:this.storage.secret,rootapp:this.storage.rootapp}}).then((resp) => {
                        this.storage = resp.data.ged;
                        this.mail = resp.data.mail;
                    })
                }


            },

            setMailsKeys() {
                if(this.$refs.mail.validate()) {

                    this.API({type:'post', url:'/engine/defineMailAPI',params:this.mail}).then((resp) => {
                        if(!resp.data.success) {
                            this.$toast.error(resp.data.message)
                            return false;
                        }

                        this.storage = resp.data.ged;
                        this.mail = resp.data.mail;
                    })
                }
            },

            authStorage() {
                switch(this.storage.set) {
                    case 'dropbox' :
                    location.href="https://www.dropbox.com/oauth2/authorize?client_id="+this.storage.key+"&token_access_type=offline&redirect_uri="+this.interfaceApp+"/activationAPI/"+this.storage.set+"&response_type=code";
                    break;
                    case 'gdrive' :
                    location.href="https://accounts.google.com/o/oauth2/v2/auth?client_id="+this.storage.key+"&scope=https://www.googleapis.com/auth/drive&access_type=offline&redirect_uri="+this.interfaceApp+"/activationAPI/"+this.storage.set+"&response_type=code";
                    // https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/drive.metadata
                    break;
                    default :
                    this.$toast.error('Vous devez selectionner un type de stockage');
                }
                
            },

            Deconnect(type) {
                if(!confirm('Vous allez vous déconnecter de cet API. Toutes les données ne seront plus visible dans le système. Continuer ?')) {
                    return;
                }
                let set = (type=='ged') ? this.storage.set : this.mail.set;
                this.API({type:'post', url:'/engine/deconnectAPI',params:{type:type,set:set}}).then((resp) => {
                    if(resp.data.success) {
                        this.storage = resp.data.ged;
                        this.mail = resp.data.mail;
                    } else {
                        this.$toast.error(resp.data.message);
                    }

                })                
            }
    
        },
    
        created() {
    
            // on check si l'utilisateur peut gérérer les API : //

            this.API({type:'post', url:'/engine/droits/getdroit',params:{root:'general',core:'manageapi'}}).then((resp) => {
                this.checkDroits = resp.data;

                if(resp.data) {
                    this.API({type:'get', url:'/engine/defineAPI',params:{}}).then((resp) => {
                        this.storage = resp.data.ged;
                        this.mail = resp.data.mail;
                        this.interfaceApp = resp.data.ged.interface
                    })
                }
            })
    
        },
    
    
    }
    </script>