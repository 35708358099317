const modal = {
    state: {

        // array avec id de page et show en argument
        show: [],

        // loading permet de mettre simplement en attente l'utilisateur avant l'affichage d'une page ou d'une modal quelconque
        loading: false,

        pageLoaded: []

    },

    getters: {

        show: (state) => {
            return state.show;
        },

        modalShowParams: (state) => (param) => {
            return state.show.find(el => el[param.type] === param.val);
        },

        loading: (state) => {
            return state.loading;
        },

        loadPage: (state) => {
            return state.pageLoaded;
        }
    },

    mutations: {

        showModal: function(state, datas) {
            // ces infos proviennent du click sur une action dans un tableau
            var pageShow = state.show.find(el => el.from === datas.from)

            // on va checker si une modal détient déjà la param "to", et on remove à zero.. 
            // sinon ca corromp les recherche dans le store avec le this.$store.getters.modalShowParams({ type: 'to', val: this.page })

            var checkTo = state.show.find(el => el.to === datas.propsPage.props.page);
            if (checkTo) {
                checkTo.to = 0;
            }
            // on attribut les infos dans le show...
            pageShow.to = datas.propsPage.props.page
            pageShow.propsPage = { props: datas.propsPage.props, name: datas.propsPage.name, displayname: datas.propsPage.displayname, path: datas.propsPage.path }
            pageShow.params = datas.params
            pageShow.query = datas.query
            pageShow.url = datas.newurl
            if (!datas.paramDialog) datas.paramDialog = {}
            pageShow.paramDialog = datas.paramDialog
            pageShow.show = true



        },

        changeOptionsModal: function(state, datas) {

            var modal = state.show.find(el => el[datas.modal.type] === datas.modal.val);
            if (datas.key) {
                modal[datas.type][datas.key] = datas.value
            } else {
                // si c'est une array []
                if (typeof datas.value === 'object' && Array.isArray(datas.value)) modal[datas.type] = [...modal[datas.type], ...datas.value]
                if (typeof datas.value === 'object' && !Array.isArray(datas.value)) modal[datas.type] = {...modal[datas.type], ...datas.value }
                if (typeof datas.value !== 'object') modal[datas.type] = datas.value
            }

        },
        closeModal: function(state, page) {
            //1 - on close la modal de la page qui est affichée

            var currPage = state.show.find(el => el.from === page.page)

            //console.log("dans vuex : " + page.pageTemplate + " = false");
            currPage.show = false

            //2 - on supprime du state show l'array qui a été instancié dans la page affiché dans la modal
            state.show = state.show.filter((el) => el.page !== currPage.to)
        },

        // placé dans modalTemplate
        initModal: function(state, datas) {

            state.show = state.show.filter(el => { return el.from != datas.page });
            var newmodal = { from: datas.page, to: 0, show: false, propsPage: {}, params: {}, query: {}, type: '', url: '', paramDialog: { fullscreen: false } }

            state.show.push(newmodal);
        },


        loadtrue: function(state) {
            state.loading = true
        },
        loadfalse: function(state) {
            state.loading = false
        },

        loadPage: function(state, args) {
            state.pageLoaded = {}
            state.pageLoaded[args.page] = args.resp
        },

        purgeModal: function(state) {
            state.show = [];
        },
    },

    actions: {

        showModal: function({ commit }, datas) {
            commit('showModal', datas);
        },

        closeModal: function({ commit }, page) {
            commit('closeModal', page);
        },

        initModal: function({ commit }, datas) {
            commit('initModal', datas);
        },

        loadtrue: function({ commit }) {
            commit('loadtrue');
        },

        loadfalse: function({ commit }) {
            commit('loadfalse');
        },

        loadPage: function({ commit }, args) {
            commit('loadPage', args);
        },

        changeOptionsModal: function({ commit }, args) {
            commit('changeOptionsModal', args);
        },

        purgeModal: function({ commit }) {
            commit('purgeModal');
        },
    }

}
export default modal;