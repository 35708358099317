<template>

        <component 
        v-if="loaded"
        :is="getFieldComponent(fieldInDB.format)" 
        :item="fieldInDB" 
        :page="page"
        :keyup="keyup" 
        :change="change"
        :valField="model"
        /> 
</template>
    
    
    
    <script>

import mixinFields from '../../mixins/Fields'
import mixinConditionsFields from '../../mixins/conditionsFields'

        export default {
            name : 'arrayTable',

            mixins : [mixinFields,mixinConditionsFields],
            // item : array du champ avec tous ces parametres.
            // keyup : function provenant de search ou FormComponent
            components : {
            },            
    

            data: function() {
                return { 
                    model : '',     
                    fieldInDB : [],
                    loaded : false,      
                }
            },
    
            watch : { 
    
               valField(val) { 
                   this.model = val
                   this.changeStoreOnField({item:this.item,model:this.model,page:this.page})
               }
    
            },

            methods : {

                getSpecificField(id) {
                    return this.fieldsInDB.find((field) => field.id === id)
                },
            },

            created() {
                this.model = this.valField
                this.getListField({filter:{id:this.item.fromBDD},typeformat:'form'}).then((resp) => {    
                        this.fieldInDB = resp[0];   
                        this.loaded = true                        
                        });
            }
    
        }
    
    </script>