var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[(_vm.lodaded)?_c('div',[_c('v-row',[_c('v-col',{staticClass:"col-md-4 col-12"},[_c('TextField',{attrs:{"item":{ value: 'name', name: 'nom de la tabulation',defaultprops:{ show: true }},"keyup":(item,value) => { _vm.thisTab.name = value },"valField":_vm.thisTab.name}}),_c('TextField',{attrs:{"item":{ value: 'class', name: 'class',defaultprops:{ show: true }},"keyup":(item,value) => { _vm.thisTab.params.class = value },"valField":_vm.thisTab.params.class}}),_c('TextField',{attrs:{"item":{ value: 'icon', name: 'Icône',defaultprops:{ show: true }},"keyup":(item,value) => { _vm.thisTab.params.icon = value },"valField":_vm.thisTab.params.icon}}),_c('SwitchC',{attrs:{"item":{ name: 'dark mode', value: 'dark',defaultprops:{ show: true }},"valField":_vm.thisTab.params.dark,"change":(item,value) => { _vm.thisTab.params.dark = value }}}),_vm._v(" (permet de créer des onglets dynamique en fonction d'un résultat de requete) "),_c('SwitchC',{attrs:{"item":{ name: 'Tabultation customisé ', value: 'setcustom',defaultprops:{ show: true }},"valField":_vm.thisTab.params.setcustom,"change":(item,value) => { _vm.thisTab.params.setcustom = value }}}),(_vm.thisTab.params.setcustom)?_c('Select',{attrs:{"item":{
            name: 'table ciblé pour la requete', value: 'table', liste: _vm.listTables,
            defaultprops: { show: true, column: false }},"change":(item,value) => { _vm.thisTab.params.custom.table = value['value'] },"valField":_vm.thisTab.params.custom.table}}):_vm._e()],1),(_vm.thisTab.params.setcustom && _vm.thisTab.params.custom.table)?_c('v-col',{staticClass:"col-md-8 col-12"},[_c('Autocomplete',{attrs:{"item":{
                name: 'Affiche une page complète ?',
                value: 'idpage',
                defaultprops: { show: true, clearable:true},
                liste: _vm.routes,
              },"valField":_vm.thisTab.params.idpage,"change":(item,value) => { _vm.thisTab.params.idpage = (value) ? value['value'] : 0; }}}),_c('p',[_vm._v("Si vous choisissez une page complète, celle-ci supplentera le contenu manuel de l'onglet")]),_c('v-tabs',{attrs:{"align":"start"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v(" Filtre ")]),_c('v-tab',[_vm._v(" champs ")]),_c('v-tabs-items',{staticClass:"pa-1",model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('Filtre',{attrs:{"filtre":_vm.thisTab.params.custom.filter,"table":_vm.thisTab.params.custom.table},on:{"recepVal":(array) => { _vm.thisTab.params.custom.filter = array }}})],1),_c('v-tab-item',[_c('p',[_vm._v("Pour chaque onglet, il faut un nom et des paramètres à intégrer.")]),_c('ul',[_c('li',[_vm._v("AS pour le nom de l'onglet : ongletname")]),_c('li',[_vm._v("AS pour les paramètres params : params-[intitulé du param]")]),_c('li',[_vm._v("AS pour les paramètres querys : querys-[intitulé de la query]")])]),_c('Fields',{attrs:{"table":_vm.thisTab.params.custom.table,"champs":_vm.thisTab.params.custom.fields,"filter":_vm.thisTab.params.custom.filter,"page":_vm.page},on:{"recepVal":(array) => { _vm.thisTab.params.custom.fields = array }}})],1)],1)],1)],1):_vm._e()],1)],1):_vm._e(),_c('div',{staticClass:"text-center"},[_c('v-btn',{staticClass:"primary",attrs:{"disabled":!_vm.valid},on:{"click":_vm.validate}},[_vm._v("Enregistrer")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }