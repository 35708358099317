import store from "../store/store";

function getTitle(vm) {
    //titleBrowser est la fonction qui peut se trouver dnas chaque page créé.
    const { titleBrowser } = vm.$options
        //si titleBrowser est dans la page
    if (titleBrowser) {
        // et que titleBrowser est une fonction
        return typeof titleBrowser === 'function' ?
            titleBrowser.call(vm) :
            titleBrowser
    }
}
export default {

    methods: {


        getListField(filter) {
            return new Promise((resolve) => {

                this.API({ type: 'post', url: 'engine/module/getfields', params: filter }).then((resp) => {
                    resolve(resp.data)
                })

            })


        }

    },
    computed: {

        showMod() {
            return store.getters.modalShowParams({
                type: "from",
                val: this.page,
            });
        },

    },
    watch: {

    },

    created() {

        const titleBrowser = getTitle(this)
        if (titleBrowser) {
            document.title = titleBrowser + " - " + store.getters.currentUser.companie
        }

        const favicon = document.getElementById("favicon");
        favicon.href = "https://minutiae.fr/favicon.ico";

    },


}